import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "component/header";
import useGet from "api/useGet";
import { Popup, RightPopup } from "component/popup";
import { BtnBox } from "component/btns";
import termsData from "../../data/terms.json";
import { ChkBox, FileItem, FileItemBtn, InputItemBox, TestAreaItem } from "component/formItems";
import { PhoneShPopup, ProductItem, TextItem } from "component/orderItem";
import { bodyScroll_clear, bodyScroll_limit, bottomChkEl, comFormat, PhoneSetting } from "js/function";
import ListBox from "component/listBox";
import * as fatchSet from "../../api/api";
import { loadTossPayments } from "@tosspayments/payment-sdk";
import { TypeTap } from "component/tap";

const Order = () => {
    const navigate = useNavigate();

    const param = useParams();
    const id = param.id;
    const countParam = param.count;
    const type = param.type;
    const storeId = param.storeId;

    const state = useLocation();
    const requireArr = state?.state ? state?.state?.requireArr : [];
    const selectArr = state?.state ? state?.state?.selectArr : [];

    const chkData = termsData?.data?.filter((el) => el.id == 1 || el.id == 3);
    const chkDataAll = termsData?.data;

    const deatilData = useGet({
        url:`/store/menu/detail?store_id=${storeId}&menu_id=${id}`,
        loginType:"login"
    });
    const menuData = deatilData.data?.menu;

    const storeData = useGet({
        url:`/store/detail?id=${storeId}${localStorage.getItem("token") ? "&user_id=" + localStorage.getItem("user_id") : ""}`,
        loginType:"login"
    });
    const store = storeData?.data?.store;

    const myApi = useGet({
        url:`/mypage/info`,
        loginType:"login"
    });
    const myData = myApi?.data;

    const [optionText,setOptionText] = useState("");
    const [selOptionText,setSelOptionText] = useState("");
    
    const [optionPrice,setOptionPrice] = useState(0);
    const [selOptionPrice,setSelOptionPrice] = useState(0);

    const [count,setCount] = useState(Number(countParam));

    const [totalPrice,setTotalPrice] = useState(0);
    const [totalPriceSet,setTotalPriceSet] = useState(0);

    const [checkItems, setCheckItems] = useState([]);
    const [checkItemTitles, setCheckItemTitles] = useState([]);
    const [terms,setTerms] =  useState("");
    const [btnChk,setBtnChk] =  useState(true);

    const [payType, setPayType] = useState("card");

    const [imageUpload,setImageUpload] =  useState("");
    const [imageSel,setImageSel] =  useState([`${process.env.REACT_APP_IMG_URL}/order_img_0.png`,`${process.env.REACT_APP_IMG_URL}/order_img_1.png`,`${process.env.REACT_APP_IMG_URL}/order_img_2.png`,`${process.env.REACT_APP_IMG_URL}/order_img_3.png`]);
    const [image,setImage] =  useState(`${process.env.REACT_APP_IMG_URL}/order_img_0.png`);
    const [basicMsg,setBasicMsg] =  useState(["기분 좋은 하루되세요.","생일 축하합니다.","언제나 응원합니다.","감사합니다."]);
    const [msg,setMsg] =  useState("기분 좋은 하루되세요.");
    const [msgDataSet,setMsgDataSet] =  useState(true);
    
    const [popupData,setPopupData] =  useState(null);

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    const [phoneType, setPhoneType] = useState("sh");
    const [phone, setPhone] = useState("");
    const [phoneText, setPhoneText] = useState("");
    const [phoneApi, setPhoneApi] = useState("");
    const phoneApiDemo = {
        // phones : {
        //     current_page : 1,
        //     last_page : 1,
        //     data : [
        //         {
        //             name : "이름 1",
        //             phone : "01012341234"
        //         },
        //         {
        //             name : "이름 2",
        //             phone : "01012341235"
        //         },
        //         {
        //             name : "이름 3",
        //             phone : "01012341237"
        //         },
        //         {
        //             name : "이름 1",
        //             phone : "01012341234"
        //         },
        //         {
        //             name : "이름 1",
        //             phone : "01012341234"
        //         },
        //         {
        //             name : "이름 1",
        //             phone : "01012341234"
        //         },
        //         {
        //             name : "이름 1",
        //             phone : "01012341234"
        //         },
        //         {
        //             name : "이름 1",
        //             phone : "01012341234"
        //         },
        //         {
        //             name : "이름 1",
        //             phone : "01012341234"
        //         },
        //         {
        //             name : "이름 1",
        //             phone : "01012341234"
        //         }
        //     ]
        // }
    }
    const [phoneData, setPhoneData] = useState([]);
    let settingChk = 0;
    const [phoneShOpen, setPhoneShOpen] = useState("");
    const [phoneDataSet, setPhoneDataSet] = useState(true);

    // 체크박스 선택
    const handleSingleCheck = (checked, id, text) => {
         if (checked) {
            setCheckItemTitles(prev => [...prev, text]);
            setCheckItems(prev => [...prev, id]);
        } else {
            setCheckItemTitles(checkItemTitles.filter((el) => el !== text));
            setCheckItems(checkItems.filter((el) => el !== id));
        }
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if(checked) {
        const idArr = [],
            textArr = [];
        chkData.forEach((el) => {idArr.push(el.id);textArr.push(el.text)});
            setCheckItemTitles(textArr);
            setCheckItems(idArr);
        }
        else {
            setCheckItemTitles([]);
            setCheckItems([]);
        }
    }

    const rightOpen = (id) => {
        setTerms({
            title:chkDataAll[Number(id)].text,
            text:chkDataAll[Number(id)].termsText
        })
    }

    const btnActive = () => {
        if(type==0){
            if(checkItemTitles.includes("개인정보 수집 및 이용 동의")&&
            checkItemTitles.includes("결제대행 서비스 이용약관")&&
            msg !== "" && image !== ""){
                setBtnChk(false);
            }else{
                setBtnChk(true);
            }
        }else{
            if(checkItemTitles.includes("개인정보 수집 및 이용 동의")&&checkItemTitles.includes("결제대행 서비스 이용약관")){
                setBtnChk(false);
            }else{
                setBtnChk(true);
            }
        }
    }

    const orderStart = () => {
      
        let allOption = [...requireArr, ...selectArr];

        let formData = new FormData();
        formData.append("is_option", allOption.length == 0 ? "0" : "1");

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/order/number",
            loginType:"login",
            success: (data) => {
                let key = data?.data;
                let paramData = {
                    amount: totalPriceSet, // 결제 금액
                    orderId: key, // 주문 ID
                    orderName: menuData?.menu_name, // 주문명
                    customerName: myData?.nickname ? myData?.nickname : myData?.phone, // 구매자 이름
                    successUrl: encodeURI(`${process.env.REACT_APP_URL}/order/pay/${type == 0 ? "gift" : "order"}/${id}/${storeId}/${allOption.length == 0 ? null : [...allOption]}/${count}/${payType}/${totalPriceSet}/${image !== "" ? image.replace("https://","").replace(/\//gi,"!SLICE!").replace(/\./gi,"!DOT!") : null}/${msg !== "" ? msg : null}/${type == 0 && phone !== "" && phone ? phone : null}`), // 결제 성공
                    failUrl: `${process.env.REACT_APP_URL}/order/err/err/${storeId}/${id}` // 결제 실패
                }
                if(payType == "kakao" || payType == "naver" || payType == "toss"){
                    paramData.flowMode = "DIRECT";
                    paramData.easyPay = payType == "kakao" ? "KAKAOPAY" : payType == "toss" ? "TOSSPAY" : "NAVERPAY";
                }

                loadTossPayments(process.env.REACT_APP_TOSSPAY_KEY).then(tossPayments => {
                    //결제창
                    tossPayments.requestPayment('카드', paramData).catch(function (error) {
                        if (error.code === 'USER_CANCEL') {
                            navigate(`/order/err/close/${storeId}/${id}`)
                        } else if (error.code === 'INVALID_CARD_COMPANY') {
                            navigate(`/order/err/card/${storeId}/${id}`)
                        }
                    });
                });
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.alert,
                        text:data.data ? data.data : "",
                        btn0:"확인",
                        btn1:"",
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btnFunc1:(e)=>{}
                    });
                }
            }
        });
    }

    function ImageItem(){
        return (
            imageSel.map((item,i)=>(
                <button type="button" key={`btn_imageSel_${i}`} className={`btn_imageSel btn_imageItemSel ${i == 0 ? "btn_imageItemSelFirst" : ""} ${item == image ? "active" : ""}`} onClick={()=>{setImage(item);setMsg(basicMsg[i]);setMsgDataSet(!msgDataSet)}}>{item ? <img src={item} className="btn_imageItemSel_img"/> : ""}</button>
            ))
        );
    }

    window.appBack = () => {
        bodyScroll_clear();
        setPhoneShOpen("");
        setPopupData({
            addClass:"appBack",
            title:"결제를 취소하시겠습니까?",
            text:"",
            btn0:"닫기",
            btn1:"결제취소",
            closeType:true,
            closeFunc:(e) => {},
            btnFunc0:(e)=>{},
            btnFunc1:(e)=>{navigate(-1)}
        });
    }

    const handleEvent = () => {
        bodyScroll_clear();
        setPhoneShOpen("");
        setPopupData({
            addClass:"appBack",
            title:"결제를 취소하시겠습니까?",
            text:"",
            btn0:"닫기",
            btn1:"결제취소",
            closeType:true,
            closeFunc:(e) => {},
            btnFunc0:(e)=>{
                window.history.pushState(null, '', window.location.href)
            },
            btnFunc1:(e)=>{
                navigate(-1)
            }
        });
    }

    function phoneShStartSet(){
        setPhoneApi({});
        setPhoneText("");
        setPhone("");
        setPage(1);
        setLastPage(null);
        setPhoneData([]);
    }

    const phoneShRightOpen = (nowPage) => {
        let varUA = navigator.userAgent;
        if(varUA.match('golmogtikon/Android') != null){
            window.golmogtikon.postMessage(JSON.stringify({ type: 'phoneSh', page: nowPage }),);
        }else if(varUA.match('golmogtikon/iOS') != null){
            window.webkit.messageHandlers.phoneShHandler.postMessage(nowPage);
        }else{
            setPhoneApi({});
            setPhoneDataSet(!phoneDataSet);
            setTimeout(() => {
                setPhoneApi(phoneApiDemo);
                setPhoneShOpen("open");
                bodyScroll_limit();
            }, 100);
        }
    }

    window.phoneShSetting = (data) => {
        setPhoneApi({});
        setPhoneDataSet(!phoneDataSet);
        setTimeout(() => {
            setPhoneApi(data);
            setPhoneShOpen("open");
            bodyScroll_limit();
            setLastPage(data?.phones?.last_page);
        }, 100);
    }

    function handleScroll(){
        const scrollItem = document.querySelectorAll(".phoneShPopup .rightPopup_contents")[0];
        if(bottomChkEl(scrollItem) && page < lastPage && settingChk == 0){
            setPage(page + 1);
            settingChk = 1;
            setTimeout(() => {
                phoneShRightOpen(page + 1);
                settingChk = 0;
            }, 500);
        }
    };

    useEffect(() => {

    }, []);

    useEffect(() => {
        if(phoneType == "sh" && phoneApi.phones){
            if(phoneApi.phones?.data && phoneApi.phones?.data.length > 0){
                if(page == 1){
                    setPhoneData([...phoneApi.phones?.data]);
                }else{    
                    setPhoneData(prev => [...prev, ...phoneApi.phones?.data]);
                }
            }else{
                setPhoneData([]);
            }
        }
    }, [phoneApi]);

    useEffect(() => {
        let varUA = navigator.userAgent;
        if(varUA.match('golmogtikon/Android') == null && varUA.match('golmogtikon/iOS') == null){
            window.history.pushState(null, '', window.location.href)
            window.addEventListener('popstate', handleEvent);
            return () => {
                window.removeEventListener('popstate', handleEvent);
            }
        }
    }, []);
    
    useEffect(() => {
        if(requireArr){
            setOptionText("");
            let optionTextSet = "",
                optionPrictSet = 0;
            menuData?.require_options.forEach((item,i) => {
                if(requireArr.indexOf(item.id) !== -1){
                    optionTextSet = optionTextSet + `${optionTextSet == "" ? "" : ","}${item.group_name} : ${item.option_name}${item.option_price > 0 ? item.option_sale_price && item.option_sale_price > 0 ? "(" + comFormat(Math.floor(Number(item.option_price) - Number(item.option_sale_price))) + "원)" : "(+" + comFormat(Math.floor(Number(item.option_price))) + "원)" : ""}`
                    optionPrictSet = optionPrictSet + (item.option_price > 0 ? item.option_sale_price ? Number(item.option_price) - Number(item.option_sale_price) : Number(item.option_price) : 0);
                }
                if(menuData?.require_options.length - 1 == i){
                    setOptionText(optionTextSet);
                    setOptionPrice(optionPrictSet);
                }
            });
        }

        if(selectArr){
            setSelOptionText("");
            let optionSelTextSet = "",
                optionPrictSet = 0;
            menuData?.select_options.forEach((item,i) => {
                if(selectArr.indexOf(item.id) !== -1){
                    optionSelTextSet = optionSelTextSet + `${optionSelTextSet == "" ? "" : ","}${item.group_name} : ${item.option_name}${item.option_price > 0 ? item.option_sale_price && item.option_sale_price > 0 ? "(" + comFormat(Math.floor(Number(item.option_price) - Number(item.option_sale_price))) + "원)" : "(+" + comFormat(Math.floor(Number(item.option_price))) + "원)" : ""}`
                    optionPrictSet = optionPrictSet + (item.option_price > 0 ? item.option_sale_price ? Number(item.option_price) - Number(item.option_sale_price) : Number(item.option_price) : 0);
                }
                if(menuData?.select_options.length - 1 == i){
                    setSelOptionText(optionSelTextSet);
                    setSelOptionPrice(optionPrictSet);
                }
            });
        }
    }, [menuData]);

    useEffect(() => {
        setTotalPrice(comFormat(Math.floor((Number(menuData?.price) - (Number(menuData?.sale_price) + Number(menuData?.platform_sale_price)) + optionPrice + selOptionPrice) * count)));
        setTotalPriceSet(Math.floor((Number(menuData?.price) - (Number(menuData?.sale_price) + Number(menuData?.platform_sale_price)) + optionPrice + selOptionPrice) * count));
    }, [menuData,optionPrice,selOptionPrice,count]);

    useEffect(() => {
        btnActive();
    }, [checkItems,msg,image]);

    return (
        <div className="orderPage bottomBtnPage">
            <Header
                pageBack = {true}
                func={() => {
                    setPopupData({
                        addClass:null,
                        title:"결제를 취소하시겠습니까?",
                        text:"",
                        btn0:"닫기",
                        btn1:"결제취소",
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btnFunc1:(e)=>{navigate(-2)}
                    });
                }}
                headTitle={type == 0 ? "선물하기" : "구매하기"}
                centerTitle={true}
            />
            <div className="orderSection">
                {type == 0 && 
                    <>
                    <h1 className="orderSection_title">선물카드 작성</h1>
                    <div className="orderForm_area">
                        <div style={{backgroundImage:`url('${image}')`}} className="fileImage_item"/>
                        <div className="orderImgSelArea">
                            <ListBox type="horizontalScroll">
                                <FileItemBtn
                                    id="cardImage"
                                    value={imageUpload}
                                    func={(e)=>{if(e){setImage(e);setImageUpload(e)}}}
                                />
                                <ImageItem/>
                            </ListBox>
                        </div>
                        <TestAreaItem
                            inputName="선물카드 메세지"
                            addClass="type2"
                            max={30}
                            maxChk={true}
                            value={msg}
                            setChk={msgDataSet}
                            func={(e)=>setMsg(encodeURI(e))}
                            placeholder="텍스트를 입력하세요"
                        />
                    </div>
                    <div className="order_phoneTypeArea">
                        <h1 className="inputName">선물받는 분 연락처</h1>
                        <div className="order_phoneType_itemBox">
                            <TypeTap
                                id={"order_phoneType_0"}
                                addClass="minVar"
                                name={"order_phoneType"}
                                val={"sh"}
                                checked={phoneType == "sh" ? true : false}
                                text={"연락처 가져오기"}
                                func={(id) => {setPhoneType(id);setPhone("");phoneShStartSet();setPhoneDataSet(!phoneDataSet)}}
                            />
                            <TypeTap
                                id={"order_phoneType_1"}
                                addClass="minVar"
                                name={"order_phoneType"}
                                val={"input"}
                                checked={phoneType == "input" ? true : false}
                                text={"연락처 직접입력"}
                                func={(id) => {setPhoneType(id);setPhone("");phoneShStartSet();setPhoneDataSet(!phoneDataSet)}}
                            />
                        </div>
                        <div className={`order_phone_inputBox ${phoneText !== "" ? "active" : ""}`}>
                            {phoneType == "sh" ? 
                                <><InputItemBox
                                    type="text"
                                    inputName=""
                                    addClass="type2 phoneShInput"
                                    placeholder="연락처를 검색해주세요"
                                    readOnly={true}
                                    value={phoneText}
                                    setChk={phoneDataSet}
                                    clickEv={(e)=>{phoneShStartSet();setTimeout(() => {phoneShRightOpen(1)}, 100)}}
                                    // func={(e)=>{setPhone(e);setPhoneText(e)}}
                                />
                                <button type="button" className="btn_phoneShReSet" onClick={()=>{phoneShStartSet();setPhoneDataSet(!phoneDataSet)}}><img src="/assets/images/basic/del.svg"/></button>
                                </>
                            : 
                                <InputItemBox
                                    type="phone"
                                    inputName=""
                                    addClass="type2"
                                    placeholder="010-"
                                    value={phoneText}
                                    setChk={phoneDataSet}
                                    func={(e)=>{setPhone(e);setPhoneText(PhoneSetting(e))}}
                                />
                            }
                        </div>
                    </div>
                    </>
                }
                <ProductItem
                    storeId={store?.id}
                    storeName={store?.store_name}
                    options={
                        optionText !== "" ? 
                            `${optionText}${selOptionText !== "" ? `,${selOptionText}` : ""}`
                            : selOptionText !== "" ? selOptionText : null
                    }
                    price={true}
                    data={menuData}
                    countVal={count}
                    countFunc={(e) => {setCount(e)}}
                />
                <div className="orderInfoText_area">
                    <TextItem
                        infoName="수량"
                        infoData={count}
                        infoUnit="개"
                    />
                    <TextItem
                        infoName="총 결제금액"
                        infoData={!totalPrice || totalPrice == "NaN" ? 0 : totalPrice}
                        infoUnit="원"
                    />
                </div>
            </div>
            <div className="orderSection">
                <div className="order_typeArea">
                    <h1 className="orderSection_title">결제방식</h1>
                    <div className="order_type_itemBox">
                        <TypeTap
                            id={"order_payType_0"}
                            name={"order_payType"}
                            val={"card"}
                            checked={payType == "card" ? true : false}
                            text={"신용/체크카드"}
                            func={(id) => {setPayType(id)}}
                        />
                        <TypeTap
                            id={"order_payType_1"}
                            name={"order_payType"}
                            val={"toss"}
                            checked={payType == "toss" ? true : false}
                            text={"토스페이"}
                            func={(id) => {setPayType(id)}}
                        />
                        {/* <TypeTap
                            id={"order_payType_2"}
                            name={"order_payType"}
                            val={"kakao"}
                            checked={payType == "kakao" ? true : false}
                            text={"카카오페이"}
                            func={(id) => {setPayType(id)}}
                        />
                        <TypeTap
                            id={"order_payType_3"}
                            name={"order_payType"}
                            val={"naver"}
                            checked={payType == "naver" ? true : false}
                            text={"네이버페이"}
                            func={(id) => {setPayType(id)}}
                        /> */}
                    </div>
                </div>
            </div>
            <div className="orderSection">
                <div className="order_chkArea">
                    <ChkBox
                        addClass="allChkItem"
                        func={(e) => handleAllCheck(e.target.checked)}
                        checkedType={checkItems.length === chkData.length ? true : false}
                        chkSubType=""
                        label="모든 약관에 동의합니다."

                    />
                    {chkData?.map((data, key) => (
                        <ChkBox
                            addClass=""
                            func={(e) => handleSingleCheck(e.target.checked,data.id,data.text)}
                            checkedType={checkItems.includes(data.id) ? true : false}
                            chkSubType={data.type}
                            label={data.text}
                            key={data.id}
                            chkFunc={(e) => rightOpen(data.id)}
                        />
                    ))}
                </div>
            </div>
            <BtnBox
                textType={true}
                btnAdd={true}
                textAddClass={"basicText"}
                textTypeText={"위 내용을 확인하였으며 결제에 동의합니다."}
                disabled={btnChk}
                text={`${!totalPrice || totalPrice == "NaN" ? 0 : totalPrice} 원 결제하기`}
                func={()=>{orderStart()}}
            />
            <RightPopup
                title={terms?.title}
                addClass={terms == "" ? "" : "active"}
                text={terms?.text}
                closePopup={() => setTerms("")}
            />
            <RightPopup
                headerTitle={"연락처 가져오기"}
                addClass={phoneShOpen == "" ? "phoneShPopup" : "phoneShPopup active"}
                closePopup={() => {setPhoneShOpen("");bodyScroll_clear()}}
                scrollEv={()=>{handleScroll()}}
            >
                <PhoneShPopup
                    data={phoneData}
                    val={phone}
                    openChk={phoneShOpen}                    
                    selFunc={(name,number)=>{setPhone(number);setPhoneText(`${name} (${PhoneSetting(number)})`);setPhoneShOpen("");bodyScroll_clear();setTimeout(() => {setPhoneDataSet(!phoneDataSet)}, 200)}}
                />
            </RightPopup>
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default Order;