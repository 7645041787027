import { setDateTime } from "js/function";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import GiftImage from "./GiftImage";

//상품권 상세
export default function GiftDetailItem(data) {
  const navigate = useNavigate();

  function dDay(data) {
    const now = new Date();
    const use_date = new Date(data.replace(/-/g, "/"));

    const setDate = use_date - now;

    const day = Math.floor(setDate / (1000 * 60 * 60 * 24));

    return day;
  }

  const date = useMemo(() => {
    if (data?.data?.order_status === "사용가능")
      return `D-${dDay(data?.data?.limit_date)} 남음`;

    if (data?.data?.order_status === "사용완료")
      return `${setDateTime(data?.data?.use_date, "yymmddhhtt", "")} 사용완료`;

    if (data?.data?.order_status === "관리자 발행취소")
      return `${setDateTime(
        data?.data?.cancel_request_date,
        "yymmddhhtt",
        ""
      )} 발행취소`;

    if (data?.data?.order_status === "기간만료/환불") {
      if (
        data?.data?.refund_confrim_date &&
        data?.data?.refund_confrim_date !== "-"
      )
        return (
          setDateTime(data?.data?.refund_confrim_date, "yymmddhhtt", "") +
          " 환불완료"
        );

      if (
        data?.data?.refund_request_date &&
        data?.data?.refund_request_date !== "-"
      )
        return setDateTime(data?.data?.refund_request_date) + " 환불요청";

      if (
        data?.data?.cancel_confrim_date &&
        data?.data?.cancel_confrim_date !== "-"
      )
        return setDateTime(data?.data?.cancel_confrim_date) + " 취소완료";

      if (
        data?.data?.cancel_request_date &&
        data?.data?.cancel_request_date !== "-"
      )
        return setDateTime(data?.data?.cancel_request_date) + " 취소요청";

      return setDateTime(data?.data?.limit_date, "yymmddhhtt", "") + " 만료";
    }

    return "";
  }, [data]);

  const isLimit = useMemo(
    () =>
      data?.data?.order_status === "기간만료/환불" &&
      data?.data?.limit_date &&
      data?.data?.limit_date !== "-",
    [data]
  );

  const isComplete = useMemo(
    () => data?.data?.order_status === "사용완료",
    [data?.data?.order_status]
  );

  return (
    <div className={`giftDetailItem ${data.wType && "wItem"}`}>
      <GiftImage
        date={date}
        image={data?.data?.menu_image}
        status={data?.data?.order_status}
        isLimit={isLimit}
        isComplete={isComplete}
      />
      <button
        type="button"
        onClick={() => {
          navigate(`/main/detail/${data?.data?.store_id}/0`);
        }}
        className="giftDetailItem_store"
      >
        <div
          className="giftDetailItem_storeImg"
          style={{ backgroundImage: `url('${data?.data?.store_image}')` }}
        ></div>
        <p className="giftDetailItem_storeName">{data?.data?.store_name}</p>
      </button>
      <h1 className="giftDetailItem_name">{data?.data?.menu_name}</h1>
      {data?.data?.options ? (
        <p className="giftDetailItem_option">{data?.data?.options}</p>
      ) : (
        ""
      )}
    </div>
  );
}
