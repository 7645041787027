import { useState } from "react";

//관심매장 추가
function LikeBtn(data){
    const [like,setLike] = useState(data.likeType);

    return (
        <button type="button" onClick={() => {data.like(data.id,like == "Y" ? 0 : 1);setLike(localStorage.getItem("token") ? like == "Y" ? "N" : "Y" : "N")}}><img src={`/assets/images/basic/${like == "Y" ? "like_on.svg" : "like_off.svg"}`}/></button>
    );
}

//관심매장 추가(작은버전/캘린더형 리스트)
function LikeBtnMin(data){
    const [like,setLike] = useState(data.likeType);

    return (
        <button type="button" className="btn_likeMin" onClick={() => {data.like(data.id,like == "Y" ? 0 : 1);setLike(localStorage.getItem("token") ? like == "Y" ? "N" : "Y" : "N")}}><img src={`/assets/images/basic/${like == "Y" ? "like_on_2.png" : "like_off_2.png"}`} style={{maxWidth:"24px"}}/></button>
    );
}

export {LikeBtn, LikeBtnMin}