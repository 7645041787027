import { comFormat } from "js/function";

function OptionItem(data){
    return (
        <div className="option_selList_item">
            <input type={data?.essential ? "radio" : "checkbox"} onChange={(e) => data.func(e,data?.data?.id,data?.id)} name={data?.essential ? `essential_option_${data?.id}` : `option_${data?.data?.id}`} id={`option_selList_input_${data?.data?.id}`} className="option_selList_input"/>
            <label htmlFor={`option_selList_input_${data?.data?.id}`}>
                {data?.data?.option_name}
                <div className="option_priceBox">
                    {data?.data?.option_sale_price ? <p className="option_sale">{Math.floor(data?.data?.option_sale_price / data?.data?.option_price * 100)} %</p> : ""}
                    <p className="option_price">+ {data?.data?.option_sale_price ? comFormat(Math.floor(Number(data?.data?.option_price) - Number(data?.data?.option_sale_price))) : comFormat(Math.floor(data?.data?.option_price))} 원</p>
                </div>
            </label>    
        </div>
    );
}

export function OptionBox(data){
    return (
        <div className={`option_area ${data?.essential ? "essential_option_area" : ""}`}>
            <div className="option_titleBox">
                <h1 className="option_title">{data?.title}</h1>
                {data?.essential ? <span className="option_caption">필수선택</span> : ""}
            </div>
            <div className="option_selListBox">
                    {data?.data.map((item, i) => (
                        <OptionItem
                            id={data?.id}
                            essential={data?.essential ? true : false}
                            data={item}
                            key={item.id}
                            index={i}
                            func={data?.func}
                        />        
                    ))}
            </div>    
        </div>
    );
}