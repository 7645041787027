import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "component/header";
import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { ChkBox } from "component/formItems";
import { BtnBox } from "component/btns";
import { Popup } from "component/popup";
import MypageListItem from "component/mypage_menuItem";

const WithdrawalPage = (props) => {
    const navigate = useNavigate();
    const [chkData,setChkData] = useState(false);
    const [popupData,setPopupData] =  useState(null);

    const myApi = useGet({
        url:`/mypage/info`,
        loginType:"login"
    });
    const myData = myApi?.data;
    
    function withdrawalFunc(){
        let formData = new FormData();
        fatchSet.FatchApi({
            type:"DELETE",
            formDataItem: formData,
            url: "/mypage/withdraw",
            loginType:"login",
            success: (data) => {
                setPopupData({
                    addClass:null,
                    title:"회원 탈퇴가 완료 되었습니다.",
                    text:"",
                    btn0:"확인",
                    btn1:"",
                    closeType:false,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{
                        localStorage.removeItem("sub_location_text");
                        localStorage.removeItem("sub_location_id");
                        localStorage.removeItem("main_location_id");
                        localStorage.removeItem("token");
                        localStorage.removeItem("refresh_token");
                        localStorage.removeItem("user_id");

                        props.logOut();

                        navigate("/main")
                    },
                    btnFunc1:(e)=>{}
                });
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.alert,
                        text:data.data ? data.data : "",
                        btn0:"확인",
                        btn1:"",
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btnFunc1:(e)=>{}
                    });
                }
            }
        })
    }
    
    return (
        <div className="myPage bottomBtnPage" style={{paddingBottom:"128px"}}>
            <Header
                pageBack = {true}
                func={() => navigate(-1)}
                headTitle="회원탈퇴 안내"
                centerTitle={true}
            />
            <div className="mypageSubSection">
                <div className="mypageWithdrawal_textBox">
                    <h1 className="mypageWithdrawal_title">회원탈퇴하시겠습니까?</h1>
                    <p className="mypageWithdrawal_text">앨리펀 회원탈퇴 시<br/>회원정보 및 서비스 이용기록은 모두 삭제되며,<br/>삭제된 데이터는 복구가 불가능합니다.</p>
                </div>
                <div className="mypage_links">
                    <MypageListItem
                        name="사용가능한 상품권"
                        rColor={true}
                        text={`${myData?.gift_count}개`}
                        func={()=>navigate("/gift")}
                    />
                    <MypageListItem
                        name="취소/환불 대상 상품권"
                        rColor={true}
                        text={`${myData?.can_cancel_count}개`}
                        func={()=>navigate("/gift")}
                    />
                </div>
            </div>
            <BtnBox
                disabled={!chkData}
                text="회원 탈퇴"
                func={()=>{
                    setPopupData({
                        addClass:null,
                        title:"앨리펀을 탈퇴하시겠습니까?",
                        text:"",
                        btn0:"취소",
                        btn1:"회원탈퇴",
                        closeType:false,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btnFunc1:(e)=>{withdrawalFunc()}
                    });
                }}
            >
                <div className="btnChk_area">
                    <ChkBox
                        addClass=""
                        func={(e) => setChkData(e.target.checked)}
                        label={`위 내용에 동의하고 탈퇴하겠습니다.`}
                    />
                </div>
            </BtnBox>
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default WithdrawalPage;