import { Popup } from "component/popup";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PublicOnly = () => {
    const navigate = useNavigate();

    const [popupData,setPopupData] =  useState(null);

    useEffect(() => {
        if(!localStorage.getItem("token")){
            navigate(0);
        }else{
            setPopupData({
                addClass:null,
                title:"잘못된 접근입니다.",
                text:"",
                btn0:"확인",
                btn1:"",
                closeType:false,
                closeFunc:(e) => {},
                btnFunc0:(e)=>{
                    navigate("/main");
                },
                btnFunc1:(e)=>{}
            });
        }
    }, []);

    return (
        <Popup
            data={popupData}
        />
    );
};

export default PublicOnly;