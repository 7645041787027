import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "component/header";
import DetailBox from "component/detail";
import useGet from "api/useGet";
import { BottomErrMsg, Popup } from "component/popup";
import { Tap } from "component/tap";
import ListBox from "component/listBox";
import { MenuList } from "component/list";
import Category from "component/category";
import { moveScroll, PhoneSetting, sharing, storeLike } from "js/function";
import { Container as MapDiv, NaverMap, Marker, NavermapsProvider, useNavermaps } from 'react-naver-maps'

const MainDetail = () => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;
    const tap = param.tap;

    const [map, setMap] = useState(null);
    const [myPosition, setMyPosition] = useState({lat: 0,lng: 0});
    const [{lat, lng}, setGeometricData] = useState({lat: 0,lng: 0});
    const [myAddr, setMyAddr] = useState("");

    const [fixedCategory, setFixedCategory] = useState("");
    const [fixedMenuCategory, setFixedMenuCategory] = useState("");
    const fixedTargetRef = useRef(null);  
    const fixedTargetMenuRef = useRef(null);  

    const fixedTitleRef = useRef(null);  
    const [fixedTitle, setFixedTitle] = useState(false);

    const [tapNum,setTapNum] =  useState(tap);

    const [menuCategory,setMenuCategory] =  useState("");

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const [popupData,setPopupData] =  useState(null);

    const copyRef = useRef(null);
    const navermaps = useNavermaps();

    const deatilData = useGet({
        url:`/store/detail?id=${id}${localStorage.getItem("token") ? "&user_id=" + localStorage.getItem("user_id") : ""}`,
        loginType:"login"
    });
    const storeData = deatilData?.data?.store;

    const menuCategoryData = useGet({
        url:`/store/menu/category?store_id=${id}`,
        loginType:"login"
    });

    function copyText(){
        copyRef.current.select();
        document.execCommand('copy');
        copyRef.current.blur();
        setBottomMsgData({
            text : "주소가 클립보드에 복사되었습니다.",
            chk : bottomMsgData.chk + 1
        });
    }
    
    function handleScroll(){
        if(window.scrollY + 54 > fixedTargetRef?.current?.offsetTop){
            setFixedCategory("fixedItem");
        }else{
            setFixedCategory("");
        }

        if(window.scrollY + 54 > fixedTargetMenuRef?.current?.offsetTop){
            setFixedMenuCategory("fixedItem");
        }else{
            setFixedMenuCategory("");
        }

        if(window.scrollY + 54 > fixedTitleRef?.current?.offsetTop){
            setFixedTitle(true);
        }else{
            setFixedTitle(false);
        }
    };

    window.iosGeo = (lat,lng) => {
        setMyPosition({
            lat:lat, 
            lng:lng
        });
    }

    function onSuccessGeolocation(position) {
        const location = new navermaps.LatLng(position.coords.latitude,position.coords.longitude);

        setMyPosition({
            lat:location.y, 
            lng:location.x
        });
    }

    function onErrorGeolocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(onSuccessGeolocation,onErrorGeolocation)
        }
    }

    function searchAddressToCoordinate(address){
        window.naver.maps.Service.geocode(
            {query: address},
            function (status, response) {
                if (status === window.naver.maps.Service.Status.ERROR) {
                    if (!address) {
                        console.log('Geocode Error, Please check address');
                    }
                    console.log('Geocode Error, address:' + address);
                }
    
                let item = response.v2.addresses;

                if(item.length > 0){
                    if(item[0].roadAddress) {
                        // setMyAddr(item[0].roadAddress);
                    }else if(item[0].jibunAddress){
                        // setMyAddr(item[0].jibunAddress);
                    }
                    setGeometricData({
                        lat:item[0].y, 
                        lng:item[0].x
                    });
                }else{
                    setMyAddr("");

                    setGeometricData({
                        lat:37.5666103,
                        lng:126.9783882
                    });
                }
            }
        );
    }

    useEffect(() => {
        setMenuCategory(menuCategoryData?.data?.categories[0]?.id);
    }, [menuCategoryData]);

    useEffect(() => {
        let varUA = navigator.userAgent;

        storeData && searchAddressToCoordinate(storeData?.business_address);

        if(varUA.match('golmogtikon/iOS') != null){
            window.webkit.messageHandlers.getlatlngHandler.postMessage({});
        }else if (navigator.geolocation ) {
            navigator.geolocation.getCurrentPosition(onSuccessGeolocation,onErrorGeolocation);
        }
    }, [storeData]);

    useEffect(() => {
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="detailPage">
            <Header
                pageBack = {true}
                func={() => {navigate(-1)}}
                headTitle={
                    fixedTitle ? 
                    deatilData.data?.store?.store_name ? deatilData.data?.store?.store_name : "" 
                    : ""
                }
                centerTitle={true}
                sharing = {true}
                sharingFunc={() => {sharing(window.location.href,copyText)}}
            />
            <DetailBox 
                imgType="slide"
                detailInfoMenu={true}
                data={deatilData.data?.store}
                fixedTitle={fixedTitleRef}
                like={(selId,type) => storeLike({
                    selId : selId,
                    type : type,
                    func : () => {
                        setBottomMsgData({
                            text : "관심매장이 추가되었습니다.",
                            chk : bottomMsgData.chk + 1
                        });
                    },
                    loginErr : () => {
                        setPopupData({
                            addClass:null,
                            title:"로그인이 필요합니다.",
                            text:"",
                            btn0:"취소",
                            btn1:"로그인",
                            closeType:false,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btnFunc1:(e)=>{
                                let nowUrl = new URL(window.location.href);
                                sessionStorage.setItem("setUrl",nowUrl.pathname);
                                navigate("/member/login");
                            }
                        });
                    }
                })}
                location={`nmap://route/car?slat=${myPosition.lat}&slng=${myPosition.lng}&sname=${myAddr}&dlat=${lat}&dlng=${lng}&dname=${storeData?.business_address} ${storeData?.business_address_detail}`}
                locationHtml={`https://map.naver.com/index.nhn?slng=${myPosition.lng}&slat=${myPosition.lat}&stext=${myAddr}&elng=${lng}&elat=${lat}&pathType=0&showMap=true&etext=${storeData?.business_address} ${storeData?.business_address_detail}&menu=route`}
                sharing={() => {sharing(window.location.href,copyText)}}
            />
            {menuCategoryData?.data?.categories.length > 0 ? 
                <div className="tapSection" ref={fixedTargetRef} style={{minHeight:"40px"}}>
                        <Tap
                            addClass={fixedCategory}
                            data={[
                                {
                                    id:0,
                                    name:"메뉴 리스트"
                                },
                                {
                                    id:1,
                                    name:"매장 상세정보"
                                }
                            ]}
                            val={tapNum}
                            func={(tapId) => setTapNum(tapId)}
                        />
                </div>
            : <div className="tapSection" style={{borderBottom:"0"}}></div>}
            <input type="text" autoComplete="off" ref={copyRef} value={`${storeData?.business_address} ${storeData?.business_address_detail}`} className="copyInput" onChange={()=>{}}/>
            {
                tapNum == 0 && menuCategoryData?.data?.categories.length > 0 ?
                <div className="menuList_section">
                    {<>
                        <div className="menuCategory" ref={fixedTargetMenuRef}>
                            <ListBox type="horizontalScroll" addClass={fixedMenuCategory} style={{top:"94px"}}>
                                <Category allChk={0} active={menuCategory} data={menuCategoryData?.data?.categories} textVer={1} func={(selId) => {moveScroll(`#menuList_area_${selId}`,159);setMenuCategory(selId)}}/>
                            </ListBox>
                        </div>
                        
                        {menuCategoryData?.data?.categories.map((item,i)=>(
                        <div className="menuList_area" key={item.id} id={`menuList_area_${item.id}`}>
                            <h1 className="menuList_categoryTitle">{item.category}</h1>
                            <MenuList
                                menuLink={`/main/menu/${id}/`}
                                data={item?.menus}
                            />
                        </div>))}
                    </>}
                </div> : 
                <div className="storeDetail_infoSection">
                    <div className="storeDetail_infoNameArea">
                        <h1 className="storeDetail_infoName">{storeData?.store_name}</h1>
                        <p className="storeDetail_infoAddr">{storeData?.business_address} {storeData?.business_address_detail}</p>
                        <button type="button" onClick={()=>copyText()} className="btn_addrCopy"><img src="/assets/images/basic/copy.svg"/>주소복사</button>
                    </div>
                    {storeData?.store_editer ? <div className="storeDetail_infoEditArea ql-editor" dangerouslySetInnerHTML={{__html:storeData?.store_editer}}></div> : ""}
                    <div className="storeDetail_infoTextArea">
                        <div className="storeDetail_infoTextItem">
                            <p className="storeDetail_infoTextName">전화번호</p>
                            <p className="storeDetail_infoText">{storeData?.business_phone ? PhoneSetting(storeData?.business_phone) : ""}</p>
                        </div>
                        <div className="storeDetail_infoTextItem">
                            <p className="storeDetail_infoTextName">대표자명</p>
                            <p className="storeDetail_infoText">{storeData?.ceo_name ? storeData?.ceo_name : "-"}</p>
                        </div>
                        <div className="storeDetail_infoTextItem">
                            <p className="storeDetail_infoTextName">사업자등록번호</p>
                            <p className="storeDetail_infoText">{storeData?.business_number ? storeData?.business_number : "-"}</p>
                        </div>
                        <div className="storeDetail_infoTextItem">
                            <p className="storeDetail_infoTextName">운영시간</p>
                            <p className="storeDetail_infoText">{storeData?.store_time ? storeData?.store_time : "-"}</p>
                        </div>
                        <div className="storeDetail_infoTextItem">
                            <p className="storeDetail_infoTextName">매장 휴무일</p>
                            <p className="storeDetail_infoText">{storeData?.holiday ? `${storeData?.holiday} 휴무` : "연중무휴"}</p>
                        </div>
                    </div>  
                    <div className="storeDetail_infoMap">
                        <NavermapsProvider>
                            <MapDiv style={{width: '100%',height: '100%'}}>
                                <NaverMap center={new navermaps.LatLng(lat, lng)} defaultZoom={15} ref={setMap}>
                                    {lat !== 0 ? <Marker defaultPosition={new navermaps.LatLng(lat, lng)}/> : ""}
                                </NaverMap>
                            </MapDiv>
                        </NavermapsProvider>
                    </div>  
                </div>    
            }
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default MainDetail;