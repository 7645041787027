//카테고리 목록(textVer 0 : 아이콘형, textVer 1 : 텍스트형)
export default function Category(data){
    return (
        <>
            {data.allChk ? <div className={`categoryBox ${data.active == "all" || data.active == "" ? "active" : ""} ${data.textVer == 1 ? "textCategory" : ""} ${data.addClass ? data.addClass : ""}`}>
                <button type="button" className="btn_category btnTopCenter btnCenter" onClick={() => data.func("all")}>
                    {data.textVer == 1 ? "" : <img src="/assets/images/icon/icon_all.svg"/>}
                    <span>전체</span>
                </button>
            </div> : ""}
            {data.data?.map((item, i) => (
                <div className={`categoryBox ${data.active == item.id ? "active" : ""} ${data.textVer == 1 ? "textCategory" : ""} ${data.addClass ? data.addClass : ""}`} key={item.id}>
                    <button type="button" className="btn_category btnTopCenter btnCenter" onClick={() => data.func(item.id)}>
                        {data.textVer == 1 ? "" : <img src={item.category_image}/>}
                        <span>{item.category ? item.category : item.location_name}</span>
                    </button>
                </div>
            ))}
        </>
    );
}