import { useState } from "react";
import { useNavigate } from "react-router-dom";
import terms from "../data/terms.json";
import { RightPopup } from "./popup";

//footer 메뉴
function FooterManu(data){
    const navigate = useNavigate();
    return (
        <div className="footerMenu">
            {/* <button onClick={() => navigate("/main")} className={`footerMenuItem ${data.menuChk == 0 ? "active" : ""}`}>
                <img src={`/assets/images/icon/${data.menuChk == 0 ? "menu_on_0.svg" : "menu_off_0.svg"}`}/>
                선물
            </button>
            <button onClick={() => navigate("/cafe")} className={`footerMenuItem ${data.menuChk == 1 ? "active" : ""}`}>
                <img src={`/assets/images/icon/${data.menuChk == 1 ? "menu_on_1.svg" : "menu_off_1.svg"}`}/>
                카페
            </button> */}

            <button onClick={() => navigate("/main")} className={`footerMenuItem ${data.menuChk == 1 ? "active" : ""}`}>
                <img src={`/assets/images/icon/${data.menuChk == 1 ? "menu_on_1.svg" : "menu_off_1.svg"}`}/>
                카페
            </button>

            <button onClick={() => navigate("/pick")} className={`footerMenuItem ${data.menuChk == 2 ? "active" : ""}`}>
                <img src={`/assets/images/icon/${data.menuChk == 2 ? "menu_on_2.svg" : "menu_off_2.svg"}`}/>
                PICK
            </button>
            <button onClick={() => navigate("/gift")} className={`footerMenuItem ${data.menuChk == 3 ? "active" : ""}`}>
                <img src={`/assets/images/icon/${data.menuChk == 3 ? "menu_on_3.svg" : "menu_off_3.svg"}`}/>
                선물함
            </button>
            <button onClick={() => navigate("/mypage")} className={`footerMenuItem ${data.menuChk == 4 ? "active" : ""}`}>
                <img src={`/assets/images/icon/${data.menuChk == 4 ? "menu_on_4.svg" : "menu_off_4.svg"}`}/>
                마이페이지
            </button>
        </div>
    );
}

//footer
function Footer(data){
    const [footerOpen,setFooterOpen] = useState(false);
    const [termsItem,setTermsItem] =  useState("");

    const rightOpen = (id) => {    
        setTermsItem({
            title:terms.data[Number(id)].text,
            text:terms.data[Number(id)].termsText
        })
    }

    return (
        <div className="footer" ref={data.footerRef}>
            <div className={`footerSection ${footerOpen ? "active" : ""}`}>
                <button type="button" onClick={()=>{setFooterOpen(!footerOpen)}} className="footer_title">주식회사 위드미 <img src="/assets/images/basic/footer_open.svg"/></button>
                <p className="footer_text">주식회사 위드미는 통신판매중개자로서 통신판매 당사자가 아니며 판매자가 등록한 상품정보 및 거래에 일체 책임을 지지 않습니다.</p>
                {footerOpen ?
                    <div className="footer_infoArea">
                        <p className="footer_infoText"><span>대표자</span>염승민</p>
                        <p className="footer_infoText"><span>사업자등록번호</span>607-22-86591</p>
                        <p className="footer_infoText"><span>주소</span>서울특별시 중구 남대문로9길 40, 20층 | 부산광역시 북구 덕천로 194, 7동 3층 301호(만덕동, 럭키아파트)</p>
                        <p className="footer_infoText"><span>대표번호</span>010-7285-2770</p>
                        <p className="footer_infoText"><span>이메일</span>golmokticon@gmail.com</p>
                        <p className="footer_infoText"><span>통신판매업신고</span>2023-부산북구-0350호</p>
                        <p className="footer_infoText"><span>개인정보 보호책임자</span>염승민</p>
                    </div>
                :""}
                <div className="footer_menuBox">
                    <button type="button" onClick={()=>rightOpen(0)} className="footer_title">이용약관</button>
                    <button type="button" onClick={()=>rightOpen(1)} className="footer_title">개인정보처리방침</button>
                </div>
            </div>
            <RightPopup
                title={termsItem?.title}
                addClass={termsItem == "" ? "" : "active"}
                text={termsItem?.text}
                closePopup={() => setTermsItem("")}
            />
        </div>
    );
}

export {FooterManu, Footer}