import { useState } from "react";

//상품 수량
export default function CountInput(data){
    const [count,setCount] = useState(data.val ? data.val : 1);

    function setCountUp(){
        if(data.qty && Number(data.qty) < 99){
            if(count < data.qty){
                setCount(count + 1);
                data.func(count + 1);
            }
        }else{
            if(count < 99){
                setCount(count + 1);
                data.func(count + 1);
            }
        }
    }

    function setCountDown(){
        if(count > 1){
            setCount(count - 1);
            data.func(count - 1);
        }
    }

    return (
        <div className="countBox">
            <button type="button" onClick={() => setCountDown()} className="btn_m"><img src={`/assets/images/basic/${count > 1 ? "count_down" : "count_down_off"}.svg`}/></button>
            <input type="text" value={count} readOnly className="countInput"/>
            <button type="button" onClick={() => setCountUp()} className="btn_p"><img src={`/assets/images/basic/${count < 99 && count < data.qty ? "count_up" : "count_up_off"}.svg`}/></button>
        </div>
    );
}