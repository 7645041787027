import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "component/header";
import { BottomErrMsg, Popup } from "component/popup";
import ListBox from "component/listBox";
import { CafeListItem } from "component/list";
import Category from "component/category";
import useGet from "api/useGet";
import { storeLike } from "js/function";

const CafeList = () => {
    const navigate = useNavigate();
    const param = useParams();
    const categoryId = param.category;

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const [category,setCategory] = useState(categoryId);

    const [popupData,setPopupData] =  useState(null);
    
    const categoryList = useGet({
        url:"/category",
        loginType:"login"
    });

    const storeList = useGet({
        url:`/store?sub_location_id=${localStorage.getItem("sub_location_id") ? localStorage.getItem("sub_location_id") : ""}&category_id=${category}&user_id=${localStorage.getItem("user_id")}`,
        loginType:"login"
    });

    useEffect(() => {
        
    }, []);

    return (
        <div className="listPage">
            <Header
                pageBack = {true}
                func={() => {navigate(-1)}}
                headTitle="매장 리스트"
                centerTitle={true}
                shLink={1}
            />
            <ListBox type="horizontalScroll" addClass="fixedItem">
                <Category allChk={1} active={category} data={categoryList.data?.categories} textVer={1} func={(selId) => setCategory(selId == "all" ? "" : selId)}/>
            </ListBox>
            <div className="list_section">
                <CafeListItem
                    data={storeList.data?.stores}
                    like={(selId,type) => storeLike({
                        selId : selId,
                        type : type,
                        func : () => {
                            setBottomMsgData({
                                text : "관심매장이 추가되었습니다.",
                                chk : bottomMsgData.chk + 1
                            });
                        },
                        loginErr : () => {
                            setPopupData({
                                addClass:null,
                                title:"로그인이 필요합니다.",
                                text:"",
                                btn0:"취소",
                                btn1:"로그인",
                                closeType:false,
                                closeFunc:(e) => {},
                                btnFunc0:(e)=>{},
                                btnFunc1:(e)=>{
                                    let nowUrl = new URL(window.location.href);
                                    sessionStorage.setItem("setUrl",nowUrl.pathname);
                                    navigate("/member/login");
                                }
                            });
                        }
                    })}
                />
            </div>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default CafeList;