import React, { useEffect, useMemo, useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { giftModalState } from "recoil/atoms/modal";
import styled, { css, keyframes } from "styled-components";

function GiftModal() {
  const [closed, setClosed] = useState(true);
  const modal = useRecoilValue(giftModalState);
  const reset = useResetRecoilState(giftModalState);

  const visible = useMemo(() => !!modal, [modal]);

  useEffect(() => {
    let timeoutId = 0;
    if (visible) {
      setClosed(false);
    } else {
      timeoutId = setTimeout(() => setClosed(true), 200);
    }
    return () => clearTimeout(timeoutId);
  }, [visible]);

  useEffect(() => {
    if (!modal) return;

    document.documentElement.style.overflow = `hidden`;
    return () => {
      document.documentElement.style.overflow = `auto`;
    };
  }, [modal]);

  if (!modal && closed) return null;

  return (
    <Wrapper visible={visible}>
      <div className="gift_modal_header">
        <img
          src="/assets/images/basic/close_w.svg"
          alt="close_icon"
          onClick={() => {
            reset();
          }}
        />
      </div>
      <div className="gift_modal_container giftCard">
        <div
          className="giftCard_img"
          style={{
            backgroundImage: `url('${modal?.giftImage}')`,
          }}
        ></div>
        <h2 className="giftCard_name">#{modal?.name} 님이 보낸 선물 메시지</h2>
        <p className="giftCard_text">{modal?.giftMsg}</p>
      </div>

      <div className="gift_modal_footer">
        <button className="pageBtn greenBtn" onClick={reset}>
          닫기
        </button>
      </div>
    </Wrapper>
  );
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background: #333333;
  overflow-y: scroll;

  padding-bottom: 78px;

  & > .gift_modal_header {
    height: 54px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    padding: 15px 20px;
  }
  & > .gift_modal_container {
    & .giftCard_name {
      color: #fff;
    }
  }
  & > .gift_modal_footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 10px 20px 20px 20px;
  }

  ${(props) =>
    props.visible
      ? css`
          animation: ${fadeIn} 0.4s forwards ease-in-out;
        `
      : css`
          animation: ${fadeOut} 0.2s forwards ease-in-out;
        `}
`;

export default GiftModal;
