import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import Router from "./router/Router";

import "swiper/css";
import "./css/quill.snow.css";
import "./css/basic.css";
import "./css/style.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <RecoilRoot>
    <Router />
  </RecoilRoot>
);
