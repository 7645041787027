import React, { useState } from "react";
import { useEffect } from "react";
import * as fatchSet from "../../api/api";
import { useNavigate } from "react-router-dom";
import {BtnBox} from "../../component/btns";
import { InputItemBox } from "../../component/formItems";
import MemberTitle from "../../component/memberTitle";
import { PhoneSetting } from "js/function";
import Header from "component/header";

const Login = () => {
    const navigate = useNavigate();

    const [phone,setPhone] =  useState("");
    const [btnChk,setBtnChk] =  useState(true);

    const LoginNext = () => {    
        let formData = new FormData();
        formData.append("phone", phone.replace(/[^0-9]/g, ''));

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/phone/check",
            success: (data) => {
                navigate(`/member/terms/${phone.replace(/[^0-9]/g, '')}`);
            },
            err: (data) => {
                navigate(`/member/certified/${phone.replace(/[^0-9]/g, '')}`);
            }
        })
    }

    useEffect(() => {
        let phone_chk = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
        if (phone.length >= 13 && phone_chk.test(phone.replace(/[^0-9]/g, ''))) {
            setBtnChk(false)
        } else {
            setBtnChk(true)
        }
    }, [phone]);

    return (
        <div className="memberPage">
            <Header
                pageBack = {true}
                func={() => navigate(-1)}
            />
            <MemberTitle 
                title="앨리펀 이용을 위해<br/>회원님의 연락처를 입력해주세요"
                subTitle="로그인은 사용하고 계신 휴대폰 번호를 통해 가능합니다."
            />
            <div className="memberInput_section">
                <InputItemBox
                    type="phone"
                    inputName="휴대폰번호"
                    placeholder="010-"
                    value={phone}
                    func={(e)=>setPhone(PhoneSetting(e))}
                />
            </div>
            <BtnBox
                text="로그인"
                disabled={btnChk}
                func={LoginNext}
            />
        </div>
    );
};

export default Login;