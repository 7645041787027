import React, { useMemo } from "react";
import styled from "styled-components";

function GiftImage({ status, date, image }) {
  const isComplete = useMemo(() => status === "사용완료", [status]);
  const isLimit = useMemo(() => status === "기간만료/환불", [status]);

  return (
    <Wrapper>
      <div
        className={`giftDetailItem_imgBox ${
          isComplete || isLimit ? "light" : ""
        }`}
        style={{ backgroundImage: `url('${image}')` }}
      ></div>
      <p
        className={`giftDetailItem_dDay endDDay ${
          isComplete || isLimit ? "thick" : ""
        }`}
      >
        {date}
      </p>
      {isComplete && (
        <img
          src="/assets/images/icon/complete_text_icon.svg"
          alt="text_icon"
          className="text_icon"
        />
      )}
      {isLimit && (
        <img
          src="/assets/images/icon/limit_text_icon.svg"
          alt="text_icon"
          className="text_icon"
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 210px;
  height: 210px;
  margin: 0 auto;
  & .light {
    opacity: 0.5;
  }
  & .thick {
    color: #fff;
    background-color: #000000;
  }

  & .text_icon {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export default GiftImage;
