import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "component/header";
import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { InputItemBox } from "component/formItems";
import { BtnBox } from "component/btns";
import { Popup } from "component/popup";

const EditPage = () => {
    const navigate = useNavigate();
    const [btnChk,setBtnChk] = useState(true);
    const [popupData,setPopupData] =  useState(null);

    const myApi = useGet({
        url:`/mypage/info`,
        loginType:"login"
    });
    const myData = myApi?.data;

    const [nickName,setNickName] = useState(myData?.nickname ? myData.nickname : "");
    const [dataSet,setDataSet] = useState(0);
    
    function editProfile(){
        let formData = new FormData();
        formData.append("nickname", nickName);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/mypage/info/modify",
            loginType:"login",
            success: (data) => {
                setPopupData({
                    addClass:null,
                    title:"회원정보 수정이 완료 되었습니다.",
                    text:"",
                    btn0:"확인",
                    btn1:"",
                    closeType:false,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{navigate(-1)},
                    btnFunc1:(e)=>{}
                });
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.alert,
                        text:data.data ? data.data : "",
                        btn0:"확인",
                        btn1:"",
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btnFunc1:(e)=>{}
                    });
                }
            }
        })
    }

    useEffect(()=>{
        setNickName(myData?.nickname ? myData.nickname : "");
        myData?.nickname && setDataSet(1);
    },[myData]);

    useEffect(()=>{
        if(nickName?.length < 7 && nickName !== ""){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    },[nickName]);
    
    return (
        <div className="myPage bottomBtnPage">
            <Header
                pageBack = {true}
                func={() => navigate(-1)}
                headTitle="회원정보 수정"
                centerTitle={true}
                rightLinkText="회원탈퇴"
                rightLink={()=>navigate("/mypage/withdrawal")}
            />
            <div className="mypageSubSection">
                <InputItemBox
                    type="text"
                    inputName="닉네임"
                    placeholder="닉네임을 입력해주세요"
                    value={nickName}
                    setChk={dataSet}
                    max={6}
                    func={(e) => {setNickName(e)}}
                />
            </div>
            <BtnBox
                disabled={btnChk}
                text="회원정보 수정"
                func={()=>{editProfile()}}
            />
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default EditPage;