import styled from 'styled-components'

//기본 버튼
function BtnBox(data){
    return (
        <div className={`pageBtn_box ${data.addClass ? data.addClass : ""}`}>
            {data.textType ? 
                <>{
                    <>
                    <p className={`pageBtn textType ${data.textAddClass ? data.textAddClass : ""}`} dangerouslySetInnerHTML={{__html:data.textTypeText}}></p>
                    {data.btnAdd ? <button type="button" className={`pageBtn ${data.btnAddClass ? data.btnAddClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.text}}></button> : ""}
                    </>
                }</>
                :
                <>
                {data.children}
                <button type="button" className={`pageBtn ${data.btnAddClass ? data.btnAddClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.text}}></button>
                </>
            }
        </div>
    );
}
//2중 버튼
function BtnBoxV2(data){
    return (
        <div className={`pageBtn_box ${data.addClass ? data.addClass : ""}`}>
            <button type="button" className={`pageBtn ${data.leftAddClass ? data.leftAddClass : ""}`} onClick={() => data.leftFunc()} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.leftText}}></button>
            <button type="button" className={`pageBtn ${data.rightAddClass ? data.rightAddClass : ""}`} onClick={() => data.rightFunc()} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.rightText}}></button>
        </div>
    );
}

export {BtnBox, BtnBoxV2};