import { useState } from "react";
//pick item
export default function PickText(data){
    const [more,setMore] = useState(true);

    return (
        <div className={`pickTextBox ${more ? "close" : "open"}`}>
            <h1 className="pick_title">{data.title}</h1>
            <p className="pick_text">{data.text}</p>
            <button type="button" className="btnPickMore" onClick={()=>{setMore(!more)}}>
                {more ? 
                    <>내용 더보기<img src="/assets/images/basic/move_g.svg"/></>
                    :
                    <>닫기<img src="/assets/images/basic/move_g.svg"/></>
                    }
            </button>
        </div>
    );
}