import * as fatchSet from "../api/api";

//핸드폰번호 포멧
function PhoneSetting(str) {
    str = str.replace(/[^0-9]/g, '');
    var tmp = '';
    if (str.length < 4) {
        return str;
    } else if (str.length < 7) {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3);
        return tmp;
    } else if (str.length < 11) {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 3);
        tmp += '-';
        tmp += str.substr(6);
        return tmp;
    } else {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 4);
        tmp += '-';
        tmp += str.substr(7);
        return tmp;
    }

    return str;
}

//날짜 표기형식 세팅
function setDateTime(item,type,unit){
	let setDate = "";
	if(item){
		let basicDate = item.indexOf("-") > 0 ? item.replace(/-/g,"/") : item;
		let date = new Date(basicDate),
			yy,
			mm,
			dd,
			th,
			tm,
			ts;

		yy = date.getFullYear();
		mm = date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
		dd = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate();
		th = date.getHours() <= 9 ? "0" + date.getHours() : date.getHours();
		tm = date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes();
		ts = date.getSeconds() <= 9 ? "0" + date.getSeconds() : date.getSeconds();

		if(type == "yymmdd"){
			if(unit == "ymd"){
				setDate = yy + "년 " + mm + "월 " + dd + "일";
			}else{
				setDate = yy + "." + mm + "." + dd;
			}
		}else if(type == "yymmddhhtt"){
			if(unit == "ymd"){
				setDate = yy + "년 " + mm + "월 " + dd + "일 " + th + ":" + tm;
			}else{
				setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm;
			}
		}else if(type == "yymmddhhttss"){
			if(unit == "ymd"){
				setDate = yy + "년 " + mm + "월 " + dd + "일 " + th + ":" + tm + ":" + ts;
			}else{
				setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm + ":" + ts;
			}
		}else if(type == "hhttss"){
			setDate = th + ":" + tm + ":" + ts;
		}else{
			setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm + ":" + ts;
		}
	}
	return setDate;
}

//스크롤 하단 체크
function bottomChk(){
    let scrollTop = document.documentElement.scrollTop,
		innerHeight = window.innerHeight,
		scrollHeight = document.querySelectorAll("body")[0].scrollHeight;

    if (Math.ceil(scrollTop + innerHeight) + 1 >= scrollHeight) {
        return true;
    } else {
        return false;
    }
}

//요소 스크롤 하단 체크
function bottomChkEl(el){
    let scrollTop = el.scrollTop,
		innerHeight = el.clientHeight,
		scrollHeight = el.scrollHeight;

    if (Math.ceil(scrollTop + innerHeight) + 1 >= scrollHeight) {
        return true;
    } else {
        return false;
    }
}

//토글 슬라이드
function slideUp(target, duration){
	target.style.transitionProperty = "height, margin, padding";
	target.style.transitionDuration = duration + "ms";
	target.style.boxSizing = "border-box";
	target.style.height = target.offsetHeight + "px";
	target.style.overflow = "hidden";
	window.setTimeout(() => {
		target.style.height = 0;
		target.style.paddingTop = 0;
		target.style.paddingBottom = 0;
		target.style.marginTop = 0;
		target.style.marginBottom = 0;
	}, 10);
	window.setTimeout(() => {
		target.style.display = "none";
		target.style.removeProperty("height");
		target.style.removeProperty("padding-top");
		target.style.removeProperty("padding-bottom");
		target.style.removeProperty("margin-top");
		target.style.removeProperty("margin-bottom");
		target.style.removeProperty("overflow");
		target.style.removeProperty("transition-duration");
		target.style.removeProperty("transition-property");
	}, duration);
}
function slideDown(target, duration, type){
	target.style.removeProperty("display");
	let display = window.getComputedStyle(target).display;

	if (display === "none") display = type == "flex" ? "flex" : "block";

	target.style.display = display;
	let height = target.offsetHeight;
	target.style.overflow = "hidden";
	target.style.height = 0;
	target.style.paddingTop = 0;
	target.style.paddingBottom = 0;
	target.style.marginTop = 0;
	target.style.marginBottom = 0;
	target.style.height = target.offsetHeight + "px";
	target.style.boxSizing = "border-box";
	target.style.transitionProperty = "height, margin, padding";
	target.style.transitionDuration = duration + "ms";
	target.style.height = height + "px";
	target.style.removeProperty("padding-top");
	target.style.removeProperty("padding-bottom");
	target.style.removeProperty("margin-top");
	target.style.removeProperty("margin-bottom");
	window.setTimeout(() => {
		target.style.removeProperty("height");
		target.style.removeProperty("overflow");
		target.style.removeProperty("transition-duration");
		target.style.removeProperty("transition-property");
	}, duration);
}
function slideToggle(target, type){
  if (window.getComputedStyle(target).display === "none") {
    return slideDown(target, 300, type);
  } else {
    return slideUp(target, 300);
  }
}

//스크롤 이동
function moveScroll(item,addTop){
	let scrollItem = document.querySelectorAll(item)[0].offsetTop;
	window.scrollTo({left: 0, top: addTop ? scrollItem - addTop : scrollItem, behavior: "smooth"});
}

//천단위 콤마 포멧
function comFormat(number){
	let data = Number(number);

	if (data == 0) return 0;
	var reg = /(^[+-]?\d+)(\d{3})/;
	var n = (data + '');
	while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
	return n;
}

//dday 체크
function dDay(data){
	const now = new Date();
	const use_date = new Date(data.replace(/-/g,"/"));

	const setDate = use_date - now;

	const day = Math.floor(setDate / (1000 * 60 * 60 * 24));

	return day;
}

//관심매장 추가
function storeLike(likeData){
	if(localStorage.getItem("token")){
		let formData = new FormData();
		formData.append("id", likeData.selId);
		formData.append("val", likeData.type);

		fatchSet.FatchApi({
			type:"POST",
			formDataItem: formData,
			url: "/store/like",
			loginType:"login",
			success: (data) => {
				if(likeData.type == 1){
					likeData.func();
				}
			},
			err: (data) => {
				
			}
		})
	}else{
		likeData.loginErr();
	}
}

//앱 브릿지
function sharing(url,func){
	let varUA = navigator.userAgent;
	if(varUA.match('golmogtikon/Android') != null){
		window.golmogtikon.postMessage(JSON.stringify({ type: 'share', text: url }),);
	}else if(varUA.match('golmogtikon/iOS') != null){
		window.webkit.messageHandlers.shareHandler.postMessage(url);
	}else{
		func();
	}
}

function tokenSave(token){
	let varUA = navigator.userAgent;
	if(varUA.match('golmogtikon/Android') != null){
		window.golmogtikon.postMessage(JSON.stringify({ type: 'autoLogin', token: token }),);
	}else if(varUA.match('golmogtikon/iOS') != null){
		window.webkit.messageHandlers.autoLoginHandler.postMessage(token);
	}
}

//url 파라미터
function getParam(sname) {
	var params = window.location.search.substr(window.location.search.indexOf("?") + 1);
	var sval = "";
	params = params.split("&");
	for (var i = 0; i < params.length; i++) {
		let temp = params[i].split("=");
		if ([temp[0]] == sname) {
			sval = temp[1];
		}
	}
	return sval;
}

//스크롤 제한(팝업용)
function bodyScroll_limit(){
	document.querySelectorAll("body")[0].classList.add("active");
}
function bodyScroll_clear(){
	document.querySelectorAll("body")[0].classList.remove("active");
}

export {PhoneSetting, bottomChk, bottomChkEl, slideUp, slideDown, slideToggle, comFormat, dDay, storeLike, sharing, tokenSave, setDateTime, moveScroll, getParam, bodyScroll_limit, bodyScroll_clear};