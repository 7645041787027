import * as fatchSet from "../../api/api";
import { InputItemBox } from "component/formItems";
import { Popup } from "component/popup";
import { PhoneSetting, tokenSave } from "js/function";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {BtnBox} from "../../component/btns";
import Header from "../../component/header";
import MemberTitle from "../../component/memberTitle";

const Certified = (props) => {
    const param = useParams();
    const phone = param.phone;

    const navigate = useNavigate();

    const [page, setPage] = useState(0);
    const [btnChk,setBtnChk] =  useState(true);

    const [certify,setCertify] =  useState("");

    const [certifyErr,setCertifyErr] =  useState("");

    const [timerCount,setTimerCount] =  useState("3:00");

    const [popupData,setPopupData] =  useState(null);

    const sval = useRef(null);
    function timerStart(){
        //타이머
        setTimerCount("3:00");
        setCertifyErr("");

        if (sval.current !== null) {
            clearInterval(sval.current);
            sval.current = null;
        }
        
        timerChk(2.99);

        function timerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            sval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(sval.current);
                    sval.current = null;
                    setCertifyErr("인증시간이 초과되었습니다.");
                }
            }, 1000);
        }
    }

    function cetifiedStert(){
        let formData = new FormData();

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/sms/certify?phone=${phone}&id_check=0`,
            success: (data) => {
                setPage(1);
                timerStart();
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.alert,
                        text:data.data ? data.data : "",
                        btn0:"확인",
                        btn1:"",
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btnFunc1:(e)=>{}
                    });
                }
            }
        })
    }

    function cetifiedChk(){
        if (certify.length > 6) {
            let text = certify;
            setCertify(text.substr(0, 6));
        }
    }

    function loginStert(){
        let formData = new FormData();
        formData.append("phone", phone);
        formData.append("sms_verify_code", certify);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/login`,
            success: (data) => {
                clearInterval(sval.current);
                sval.current = null;

                localStorage.setItem("token",data.data.token.access_token);
                localStorage.setItem("refresh_token",data.data.token.refresh_token);
                localStorage.setItem("user_id",data.data.user_id);

                tokenSave(data.data.token.access_token);

                props.logIn();

                if(sessionStorage.getItem("setUrl")){
                    let reUrl = sessionStorage.getItem("setUrl");
                    sessionStorage.removeItem("setUrl");
                    navigate(reUrl);
                }else{
                    navigate(`/main`);
                }
            },
            err: (data) => {
                if(data.alert == "인증 번호가 맞지 않습니다."){
                    setCertifyErr("인증 번호가 올바르지 않습니다.");
                }else if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.alert,
                        text:data.data ? data.data : "",
                        btn0:"확인",
                        btn1:"",
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btnFunc1:(e)=>{}
                    });
                }
            }
        })
    }

    useEffect(() => {
        cetifiedStert();
    }, []);

    useEffect(() => {
        if(certify.length >= 6){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [certify, page]);

    return (
        <div className="memberPage">
            <Header
                pageBack = {true}
                func={() => {navigate(-1)}}
            />
            <MemberTitle 
                title="문자메세지로 발송된<br/>인증번호를 입력해주세요."
                subTitle={`${PhoneSetting(phone)} 번호로 문자메세지를 발송드렸어요.`}
            />
            <div className="memberInput_section">
                <InputItemBox
                    type="smsChk"
                    inputName="인증번호"
                    placeholder="인증번호 6자리를 입력해주세요"
                    readOnly={sval.current == null ? true : false}
                    timer={timerCount}
                    value={certify}
                    max={6}
                    clickEv={(e) => {cetifiedStert()}}
                    func={(e) => {setCertify(e);cetifiedChk()}}
                    errMsg={certifyErr == "" ? "" : certifyErr}
                />
            </div>
            <BtnBox
                text="로그인"
                disabled={btnChk}
                func={loginStert}
            />
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default Certified;