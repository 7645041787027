import { Swiper, SwiperSlide } from "swiper/react";
import CountInput from "./count";
import DetailLike from "./detailLike";
import { Pagination } from "swiper";
import ListBox from "./listBox";
import { comFormat } from "js/function";

//스토어,메뉴상세
export default function DetailBox(data){
    let tagArr = data.data?.tag?.indexOf(",") ? data.data?.tag?.split(",") : [data.data?.tag];

    function locationLink(url,urlHtml){
        let varUA = navigator.userAgent;                                           
        if(varUA.match('golmogtikon/Android') != null){
            window.golmogtikon.postMessage(JSON.stringify({ type: 'naverMap', url: url }),);  
        }else if(varUA.match('golmogtikon/iOS') != null){
            window.webkit.messageHandlers.naverMapHandler.postMessage(url);
        }else{
            window.open(urlHtml,"_blank");
        }
    }

    return (
        <div className={`detail_section ${data.addClass ? data.addClass : ""}`}>
            {data.imgType == "slide" ?
                <div className="detail_bannerSlide">
                    {data.data?.images ? <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        modules={[Pagination]}
                        pagination={{
                            type: "fraction",
                        }}
                        loop={true}
                    >
                        {data.data?.images?.map((imgItem, index) => (
                            <SwiperSlide key={imgItem.id}>
                                <div className="detail_bannerItem" style={{backgroundImage:`url('${imgItem.image_url}')`}}></div>
                            </SwiperSlide>
                        ))}
                    </Swiper> : ""}
                </div>
                :
                <div className="detail_bannerItemBox">
                    <div className="detail_bannerItem" style={{backgroundImage:`url('${data.data?.menu_image}')`}}></div>
                </div>
            }
            <div className="detail_titleBox">
                <h1 className="detail_title" ref={data.fixedTitle}>{data.data?.store_name ? data.data?.store_name : data.data?.menu_name ? data.data?.menu_name : ""}</h1>
                <p className="detail_text">{data.data?.store_introduce ? data.data?.store_introduce : data.data?.menu_description ? data.data?.menu_description : ""}</p>
            </div>
            {data.data?.is_event !== 0 && data.data?.event_contents &&
                <div className="detailEventBox"><img src="/assets/images/icon/notice.svg"/><p className="detailEventText">{data.data?.event_contents}</p></div>
            }
            {data.data?.tag && data.data?.tag !== "null" ? <div className="detail_tagBox">
                <ListBox type="horizontalScroll">
                    {tagArr.map((item,i) => (
                        <p className="tagItem" key={`tagItem_${i}`}>#{item}</p>
                    ))}
                </ListBox>
            </div> : ""}
            {data.detailInfoMenu ? 
                <div className="detailInfoMenu">
                    <a href={`tel:${data.data?.business_phone}`} className="detailInfoMenuItem">
                        <img src="/assets/images/icon/detail_call.svg"/>
                        전화하기
                    </a>
                    <DetailLike
                        id={data.data?.id}
                        likeType={data.data?.is_my_like}
                        like={data.like}
                    />
                    <button type="button" onClick={() => locationLink(data.location,data.locationHtml)} className="detailInfoMenuItem">
                        <img src="/assets/images/icon/detail_location.svg"/>
                        길 찾기
                    </button>
                    <button type="button" onClick={() => data.sharing()} className="detailInfoMenuItem">
                        <img src="/assets/images/icon/detail_sharing.svg"/>
                        공유하기
                    </button>
                </div>
            : ""}
            {data.data?.price ? 
                <div className="menuDetail_topInfo">
                    <div className="detailPriceSection">
                        {data.data?.sale_price > 0 || data.data?.platform_sale_price > 0 ? <p className="detailBasicPrice">{comFormat(Math.floor(data.data?.price))} 원</p> : ""}
                        <div className="detailPriceArea">
                            {data.data?.sale_price > 0 || data.data?.platform_sale_price > 0 ? <p className="detailSale_val">{Math.floor((data.data?.sale_price ? Number(data.data?.sale_price) : 0 + data.data?.platform_sale_price ? Number(data.data?.platform_sale_price) : 0) / Number(data.data?.price) * 100)}%</p> : ""}
                            <h2 className="detailPrict">{comFormat(Math.floor(Number(data.data?.price) - (Number(data.data?.sale_price) + Number(data.data?.platform_sale_price))))} 원</h2>
                        </div>
                    </div>
                    <CountInput
                        qty={data.data?.qty}
                        func={data.countFunc}
                    />
                </div>
                : ""
            }
        </div>
    );
}