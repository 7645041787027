export default function OnboardingItem(data){
    return (
        <div className="onboardingItem">
            <div className="onboarding_titleBox">
                <h1 className="onboarding_title" dangerouslySetInnerHTML={{__html:data.title}}></h1>
                <p className="onboarding_text" dangerouslySetInnerHTML={{__html:data.text}}></p>
            </div>
            <img src={data.image}/>
        </div>
    );
}