import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "component/header";
import { AccordionItem } from "component/list";
import useGet from "api/useGet";
import { BtnBox } from "component/btns";

const Faq = () => {
    const navigate = useNavigate();

    const faqApi = useGet({
        url:`/mypage/faq`,
        loginType:"login"
    });
    const faqData = faqApi?.data?.faqs;

    useEffect(() => {

    }, []);

    return (
        <div className="faqPage">
            <Header
                pageBack = {true}
                func={() => navigate(-1)}
                headTitle="고객센터"
                centerTitle={true}
            />
            <div className="faq_section">
                {faqData && faqData?.length > 0 ?
                    <>
                        {faqData && faqData?.map((item, i) => (
                            <AccordionItem key={i} faq={true} data={item}/>
                        ))}
                    </>
                    :
                    <div className="notList">
                        <img src="/assets/images/icon/not_list.svg"/>
                        FAQ 내역이 없습니다
                    </div>
                }
            </div>
            <BtnBox
                text="문의하기"
                func={()=>{navigate("/cs/cs")}}
            >
                <p className="btnSubText">1:1 문의가 필요하신가요?</p>
            </BtnBox>
        </div>
    );
};

export default Faq;