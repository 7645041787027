import Certified from "pages/member/Certified";
import Join from "pages/member/Join";
import SettingPage from "pages/mypage/Setting";
import Private from "pages/private/Private";
import PublicOnly from "pages/private/publicOnly";
import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { publicRoutes, privateRoutes, publicRoutesOnly } from "./routes";
import WithdrawalPage from "pages/mypage/Withdrawal";
import ScrollToTop from "component/scrollTopSet";
import GiftModal from "component/modal/GiftModal";

const Router = () => {
  const [token, setToken] = useState(
    localStorage.getItem("token") ? true : false
  );

  const logIn = () => {
    setToken(true);
  };
  const logOut = () => {
    setToken(false);
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* 기본접근 */}
        {publicRoutes.map(({ exact, path, component }) => (
          <Route
            key={`public-route-${path}`}
            exact={exact}
            path={path}
            element={component}
          />
        ))}

        {/* 비로그인만 접근 */}
        {publicRoutesOnly.map(({ exact, path, component }) => (
          <Route
            key={`public-route-${path}`}
            exact={exact}
            path={path}
            element={!token ? component : <PublicOnly />}
          />
        ))}

        {/* 로그인만 접근 */}
        {privateRoutes.map(({ exact, path, component }) => (
          <Route
            key={`public-route-${path}`}
            exact={exact}
            path={path}
            element={token ? component : <Private />}
          />
        ))}

        {/* 로그인 체크 */}
        <Route
          key={`public-route-/member/certified/:phone`}
          exact={true}
          path="/member/certified/:phone"
          element={!token ? <Certified logIn={logIn} /> : <PublicOnly />}
        />
        <Route
          key={`public-route-/member/join/:phone/:terms`}
          exact={true}
          path="/member/join/:phone/:terms"
          element={!token ? <Join logIn={logIn} /> : <PublicOnly />}
        />

        {/* 로그아웃 체크 */}
        <Route
          key={`public-route-/mypage/setting`}
          exact={true}
          path="/mypage/setting"
          element={token ? <SettingPage logOut={logOut} /> : <Private />}
        />
        <Route
          key={`public-route-/mypage/withdrawal`}
          exact={true}
          path="/mypage/withdrawal"
          element={token ? <WithdrawalPage logOut={logOut} /> : <Private />}
        />
      </Routes>
      <GiftModal />
    </BrowserRouter>
  );
};

export default Router;
