import { Fragment, useState } from "react";
import { AccordionItem } from "./list";

//기타정보
function Etc(data){
    return (
        <div className="etc_area">
            {data.data?.map((item, i) => (
                <Fragment key={item.id}>
                    {data.notProduct && item.term_name == "상품 고시정보" ?
                        "" : <AccordionItem data={item}/>
                    }
                </Fragment>
            ))}
        </div>
    );
}

export {Etc};