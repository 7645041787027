import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "component/header";
import useGet from "api/useGet";
import { BtnBox } from "component/btns";
import GiftDetailItem from "component/giftDetail";
import { RightPopup } from "component/popup";
import { TextItem } from "component/orderItem";
import moment from "moment";
import GiftFixedButtons from "component/GiftFixedButtons";

const GiftDetailPage = () => {
  const navigate = useNavigate();
  const param = useParams();
  const id = param.id;
  const popup = param.popup;

  const [popupType, setPopupType] = useState(Number(popup));

  const giftDetail = useGet({
    url: `/gift/detail?id=${id}`,
    loginType: "login",
  });
  const giftDetailData = giftDetail?.data?.order;

  useEffect(() => {
    if (popup == 0) {
      setPopupType(0);
    } else {
      setPopupType(1);
    }
  }, [popup]);

  useEffect(() => {
    var uaSet = window.navigator.userAgent;
    if (
      uaSet.toLocaleLowerCase().indexOf("kakaotalk") > -1 &&
      !/iPad|iPhone|iPod/.test(uaSet)
    ) {
      window.location.href = "kakaotalk://inappbrowser/close";
      window.location.href =
        "intent://" +
        window.location.href.replace(/https?:\/\//i, "") +
        "#Intent;scheme=http;end";
    }
  }, []);

  return (
    <div
      className={`giftDetailPage bottomBtnPage ${
        giftDetailData?.gift_type == "mySend" &&
        giftDetailData?.order_status == "사용가능"
          ? "dBottomBtnPage"
          : ""
      }`}
    >
      <Header
        pageBack={true}
        func={() => {
          navigate(-1);
        }}
        headTitle="상품권 상세정보"
        centerTitle={true}
        rightBtn={
          (giftDetailData?.gift_type == "mySend" ||
            giftDetailData?.gift_type == "send") &&
          giftDetailData?.order_status == "사용가능" &&
          giftDetailData?.is_admin == 0 ? (
            <button
              type="button"
              className="headerTextBtn btnHeaderRight"
              onClick={() => {
                navigate(`/cs/cancel/${giftDetailData.id}/결제 취소`);
              }}
            >
              취소접수
            </button>
          ) : (giftDetailData?.gift_type == "mySend" ||
              giftDetailData?.gift_type == "receive") &&
            giftDetailData?.order_status == "기간만료/환불" &&
            giftDetailData?.is_admin == 0 ? (
            <button
              type="button"
              className="headerTextBtn btnHeaderRight"
              onClick={() => {
                navigate(`/cs/cancel/${giftDetailData.id}/상품권 환불`);
              }}
            >
              환불접수
            </button>
          ) : null
        }
      />
      <div className="giftDetail">
        <GiftDetailItem data={giftDetailData} />

        <div className="giftCard_order_info">
          <h3 className="giftCard_order_info_title">선물 사용 정보</h3>
          <TextItem
            infoName="유효기간"
            infoData={moment(giftDetailData?.limit_date).format(
              "YYYY년 MM월 DD일"
            )}
          />
          <TextItem
            infoName="주문번호"
            infoData={giftDetailData?.order_number}
          />
          <TextItem infoName="교환처" infoData={giftDetailData?.store_name} />
          <TextItem
            infoName="사용가능금액"
            infoData={giftDetailData?.original_price}
          />
          <TextItem
            infoName="선물주문일"
            infoData={giftDetailData?.created_at}
          />
          <TextItem
            infoName="쿠폰상태"
            infoData={giftDetailData?.order_status}
          />
        </div>
      </div>

      <GiftFixedButtons
        status={giftDetailData?.order_status}
        type={giftDetailData?.gift_type}
        cardNumber={giftDetailData?.giftCard_number}
        id={giftDetailData?.id}
        giftImage={giftDetailData?.gift_image}
        giftMsg={giftDetailData?.gift_msg}
        giftName={giftDetailData?.name}
      />

      {giftDetailData?.gift_type !== "mySend" && (
        <RightPopup
          addClass={popupType == 1 ? "active" : ""}
          bType={true}
          closePopup={() => navigate(-1)}
        >
          <GiftDetailItem wType={true} data={giftDetailData} />
          <BtnBox
            textType={true}
            btnAdd={
              giftDetailData?.gift_type !== "send" &&
              giftDetailData?.order_status == "사용가능"
                ? true
                : false
            }
            textTypeText={`상품권 번호<span>${
              giftDetailData?.giftCard_number
                ? giftDetailData?.giftCard_number
                : ""
            }</span>`}
            text={
              giftDetailData?.gift_type !== "send" &&
              giftDetailData?.order_status == "사용가능"
                ? `<img src="/assets/images/basic/qr_icon.svg" class="qrIcon"/>상품권 사용`
                : ""
            }
            btnAddClass="greenBtn"
            func={() => {
              giftDetailData?.gift_type !== "send" &&
                giftDetailData?.order_status == "사용가능" &&
                navigate(`/gift/qr/${giftDetailData?.id}`);
            }}
          />
        </RightPopup>
      )}
    </div>
  );
};

export default GiftDetailPage;
