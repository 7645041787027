import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {Footer, FooterManu} from "component/footer";
import Header from "component/header";
import { Popup, SelPopup } from "component/popup";
import ListBox from "component/listBox";
import { BasicList } from "component/list";
import Category from "component/category";
import useGet from "api/useGet";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import * as fatchSet from "../../api/api";

const Cafe = () => {
    const navigate = useNavigate();
    
    const [selCloseType,setSelCloseType] = useState(true);

    const [mainAddr,setMainAddr] = useState("all");
    const [addr,setAddr] = useState("지역 전체");
    const [addrVal,setAddrVal] = useState("all");
    const [selPopup,setSelPopup] =  useState(null);
    const [selPopupOpenType,setSelPopupOpenType] =  useState(false);

    const [popupData,setPopupData] =  useState(null);

    const bannerList = useGet({
        url:`/cafe/banner`,
        loginType:"login"
    });

    const categoryList = useGet({
        url:"/category",
        loginType:"login"
    });

    const recentList = useGet({
        url:localStorage.getItem("token") ? "/cafe/recent" : null,
        loginType:"login"
    });

    const popularList = useGet({
        url:`/cafe/popular?sub_location_id=${localStorage.getItem("sub_location_id") ? localStorage.getItem("sub_location_id") : ""}`,
        loginType:"login"
    });

    const themas = useGet({
        url:`/cafe/thema`,
        loginType:"login"
    });

    const mainLocationList = useGet({
        url:`/location`,
        loginType:"login"
    });

    const subLocationApi = useGet({
        url:`/location?parent_id=${mainAddr}`,
        loginType:"login",
        chk: mainAddr ? true : false
    });
    const subLocationList = subLocationApi?.data?.locations;

    function selOpen(){
        setSelPopupOpenType(true);
        setSelPopup({
            title:"지역 선택",
            addClass:null,
            closeType:selCloseType ? true : false,
            dataKey:"location_name",
            data:mainAddr !== "all" ? subLocationList : [{"id": "all","location_name": "지역 전체","created_at": "2023-05-03 00:00:00"}],
            sel:addr,
            closeFunc:(e) => {
                if(selCloseType){
                    setSelPopupOpenType(true)
                }
            },
            func:(e) => {
                selItem(e);
                setSelPopupOpenType(false);
            }
        })
    }
    function selItem(e){
        setAddrVal(e.val);
        setAddr(e.text);
        localStorage.setItem("main_location_id",mainAddr);
        localStorage.setItem("sub_location_id",e.val);
        localStorage.setItem("sub_location_text",e.text);
    }

    //링크 회원 체크
    function linkOpen(url){
        if(localStorage.getItem("token")){
            window.open(url,"_blank");
        }else{
            setPopupData({
                addClass:null,
                title:"로그인이 필요합니다.",
                text:"",
                btn0:"취소",
                btn1:"로그인",
                closeType:false,
                closeFunc:(e) => {},
                btnFunc0:(e)=>{},
                btnFunc1:(e)=>{
                    let nowUrl = new URL(window.location.href);
                    sessionStorage.setItem("setUrl",nowUrl.pathname);
                    navigate("/member/login");
                }
            });
        }
    }

    useEffect(() => {
        //방문자 수 집계
        let formData = new FormData();
        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/visit",
            success: (data) => {},
            err: (data) => {}
        })

        if(localStorage.getItem("sub_location_id")){
            setAddrVal(localStorage.getItem("sub_location_id"));
        }else{
            setAddrVal("all");
        }
        if(localStorage.getItem("sub_location_text")){
            setAddr(localStorage.getItem("sub_location_text"));
        }else{
            setAddr("지역 전체");
        }
    }, []);

    useEffect(() => {
        if(localStorage.getItem("main_location_id")){
            setMainAddr(localStorage.getItem("main_location_id"));
        }else{
            setMainAddr("all");
        }
        
        if(!localStorage.getItem("sub_location_id")){
            localStorage.setItem("main_location_id","all");
            localStorage.setItem("sub_location_id","all");
            localStorage.setItem("sub_location_text","지역 전체");
        }
    }, [mainLocationList]);

    useEffect(() => {
        if(selPopupOpenType){
            setSelPopup({
                title:"지역 선택",
                addClass:null,
                closeType:selCloseType ? true : false,
                dataKey:"location_name",
                data:mainAddr !== "all" ? subLocationList : [{"id": "all","location_name": "지역 전체","created_at": "2023-05-03 00:00:00"}],
                sel:addr,
                closeFunc:(e) => {
                    if(selCloseType){
                        setSelPopupOpenType(true);
                    }
                },
                func:(e) => {
                    selItem(e);
                    setSelPopupOpenType(false);
                }
            })
        }
    }, [subLocationList]);

    return (
        <div className="mainPage">
            <Header
                customHeaderItem={<div className="addrSelBox" onClick={(e) => {setSelCloseType(true);selOpen()}}>
                    <img src="/assets/images/basic/location.svg"/>
                    {addr}
                    <img src="/assets/images/basic/sel.svg"/>
                </div>}
                shLink={1}
            />
            <div className="banner_section">
                {bannerList?.data?.banners ? <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    pagination={{
                        type: "fraction",
                    }}
                    loop={true}
                    modules={[Pagination]}
                >
                    {bannerList?.data?.banners && bannerList?.data?.banners.map((imgItem, index) => (
                        <SwiperSlide key={imgItem.id}>
                            <div className="bannerImg" onClick={()=>{linkOpen(imgItem.link)}} style={{backgroundImage:`url('${imgItem.image_url}')`}}></div>
                        </SwiperSlide>
                    ))}
                </Swiper> : ""}
            </div>
            <div className="cafeCategory_section">
                <Category allChk={0} data={categoryList.data?.categories} textVer={0} func={(selId) => navigate(`/cafe/list/${selId}`)}/>
            </div>
            {recentList?.data?.recent_stores && recentList?.data?.recent_stores.length > 0 ?
                <div className="recent_section">
                    <h1 className="cafe_section_title">최근 이용한 매장</h1>
                    <div className="recent_area">
                        <ListBox type="horizontalScroll">
                            {recentList?.data?.recent_stores && recentList?.data?.recent_stores.map((item, i) => (
                                <button type="button" key={item.id} onClick={() => navigate(`/main/detail/${item.id}/0`)} className="recent_item btnTopCenter btnCenter">
                                    <div className="recentItemImg" style={{backgroundImage:`url('${item.image_url}')`}}></div>
                                    <div className="recentItem_info">
                                        <h1 className="recentItem_name">{item.store_name}</h1>
                                        <p className="recentItem_text">{item.store_introduce}</p>
                                    </div>
                                </button>
                            ))}
                        </ListBox>
                    </div>
                </div>
                :""
            }
            <div className="popular_section">
                <h1 className="cafe_section_title">인기 매장</h1>
                <div className="popular_area">
                    <ListBox type="horizontalScroll">
                        {popularList?.data?.stores && popularList?.data?.stores.map((item, i) => (
                            <button type="button" key={item.id} onClick={() => navigate(`/main/detail/${item.id}/0`)} className="popular_item btnTopCenter">
                                <div className="popularItemImg" style={{backgroundImage:`url('${item.image_url}')`}}></div>
                                <div className="popularItem_info">
                                    <h1 className="popularItem_name">{item.store_name}</h1>
                                    <p className="popularItem_text">{item.store_introduce}</p>
                                </div>
                                {item?.tags && item?.tags.length > 0 ? <div className="popularItem_tagBox">
                                    {item?.tags.map((item,i) => (
                                        i <= 1 && item.tag !== "null" ? <p className="tagItem" key={`tagItem_${i}`}>#{item.tag}</p> : ""
                                    ))}
                                </div> : ""}
                            </button>
                        ))}
                    </ListBox>
                </div>
            </div>
            {themas?.data?.themas && themas?.data?.themas.length > 0 && themas?.data?.themas.map((item, i) => (
                item?.stores && item?.stores.length > 0 ? <div className="thema_section" key={item.id}>
                    <h1 className="cafe_section_title">{item.thema_name}</h1>
                    <div className="thema_area">
                        <ListBox type="horizontalScroll">
                            {item?.stores && item?.stores.map((themaItem, index) => (
                                <BasicList
                                    data={themaItem}
                                    key={themaItem?.id}
                                />
                            ))}
                        </ListBox>
                    </div>
                </div> : ""
            ))}
            <FooterManu menuChk="1"/>
            <Footer/>
            <SelPopup
                data={selPopup}
            >
                <div className="horizontalScrollBox">
                    <ListBox type="horizontalScroll">
                        <Category allChk={0} active={mainAddr} data={mainLocationList?.data?.locations ? [{
                            "id": "all",
                            "location_name": "전체",
                            "created_at": "2023-05-03 00:00:00"
                        },...mainLocationList?.data?.locations] : []} textVer={1} func={(selId) => {setMainAddr(selId)}}/>
                    </ListBox>
                </div>
            </SelPopup>
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default Cafe;