import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { LikeBtn } from "./likeBtn";
import { MenuListItem } from "./list";
import PickText from "./pickText";
//pick목록
export default function PickList(data){
    return (
        <>
            {data.data?.map((item, i) => (
                <div key={item.id} className="pickItem">
                    <div className="pickItemImgBox">
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            pagination={{
                                type: "fraction",
                            }}
                            loop={true}
                            modules={[Pagination]}
                        >
                            {item.images?.map((imgItem, index) => (
                                <SwiperSlide key={imgItem.id}>
                                    <div className="pickItemImg" style={{backgroundImage:`url('${imgItem.image_url}')`}}></div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    <PickText
                        title={item.title}
                        text={item.contents}
                    />
                    {item.stores.length > 0 ?
                        <Swiper
                            spaceBetween={10}
                            slidesPerView={1}
                            loop={true}
                        >
                            {item.stores?.map((storesItem, index) => (
                                <SwiperSlide key={storesItem.id}>
                                    <div className="pickStoreItem">
                                        <MenuListItem
                                            menuLink="/main/detail/"
                                            id={storesItem.id}
                                            menu_image={storesItem.image_url}
                                            menu_name={storesItem.store_name}
                                            menu_text={storesItem.store_introduce}
                                        />
                                        <LikeBtn
                                            id={storesItem.id}
                                            likeType={storesItem.is_my_like}
                                            like={data.like}
                                        />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        :
                        ""
                    }
                </div>
            ))}
        </>
    );
}