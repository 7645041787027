import { RightPopup } from "component/popup";
import React, { useState } from "react";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {BtnBox} from "../../component/btns";
import { ChkBox } from "../../component/formItems";
import Header from "../../component/header";
import MemberTitle from "../../component/memberTitle";
import termsData from "../../data/terms.json";

const Terms = () => {
    const param = useParams();
    const phone = param.phone;

    const navigate = useNavigate();

    const chkData = termsData?.data?.filter((el) => el.id !== 3);
    const chkDataAll = termsData?.data;

    const [checkItems, setCheckItems] = useState([]);
    const [checkItemTitles, setCheckItemTitles] = useState([]);
    const [btnChk,setBtnChk] =  useState(true);
    const [terms,setTerms] =  useState("");

    // 체크박스 선택
    const handleSingleCheck = (checked, id, text) => {
         if (checked) {
            setCheckItemTitles(prev => [...prev, text]);
            setCheckItems(prev => [...prev, id]);
        } else {
            setCheckItemTitles(checkItemTitles.filter((el) => el !== text));
            setCheckItems(checkItems.filter((el) => el !== id));
        }
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if(checked) {
        const idArr = [],
            textArr = [];
        chkData.forEach((el) => {idArr.push(el.id);textArr.push(el.text)});
            setCheckItemTitles(textArr);
            setCheckItems(idArr);
        }
        else {
            setCheckItemTitles([]);
            setCheckItems([]);
        }
    }

    const joinStert = () => {    
        navigate(`/member/join/${phone.replace(/[^0-9]/g, '')}/${checkItemTitles.includes("이벤트 및 혜택 정보 수신 동의") ? "1" : "0"}`);
    }

    const rightOpen = (id) => {    
        setTerms({
            title:chkDataAll[Number(id)].text,
            text:chkDataAll[Number(id)].termsText
        })
    }

    useEffect(() => {
        if(checkItemTitles.includes("서비스 이용 약관")&&checkItemTitles.includes("개인정보 수집 및 이용 동의")){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [checkItems]);

    return (
        <div className="memberPage">
            <Header
                pageBack = {true}
                func={() => navigate(-1)}
            />
            <MemberTitle 
                title="앨리펀을 새롭게 이용하시나요?<br/>아래 약관에 동의해주세요."
                subTitle="필수 약관에 모두 동의해야 서비스 이용이 가능해요."
            />
            <div className="memberInput_section">
                <ChkBox
                    addClass="allChkItem"
                    func={(e) => handleAllCheck(e.target.checked)}
                    checkedType={checkItems.length === chkData.length ? true : false}
                    chkSubType=""
                    label="모든 약관에 동의합니다."

                />
                {chkData?.map((data, key) => (
                    <ChkBox
                        addClass=""
                        func={(e) => handleSingleCheck(e.target.checked,data.id,data.text)}
                        checkedType={checkItems.includes(data.id) ? true : false}
                        chkSubType={data.type}
                        label={data.text}
                        key={data.id}
                        chkFunc={data.id == 2 ? null : (e) => rightOpen(data.id)}
                    />
                ))}
            </div>
            <BtnBox
                text="다음"
                disabled={btnChk}
                func={joinStert}
            />
            <RightPopup
                title={terms?.title}
                addClass={terms == "" ? "" : "active"}
                text={terms?.text}
                closePopup={() => setTerms("")}
            />
        </div>
    );
};

export default Terms;