import { PhoneSetting } from "js/function";
import { Fragment, useEffect, useRef, useState } from "react";
import * as fatchSet from "../api/api";

//기본 input
function InputItemBox(data){
    const [val,setVal] = useState(data.value ? data.value : "");
    const [val2,setVal2] = useState(data.value2 ? data.value2 : "");

    function valSetting(inputVal){
        setVal(inputVal);
        data.func(inputVal);
    }

    useEffect(()=>{
        setVal(data.value ? data.value : "")
    },[data.setChk]);

    return (
        <div className="inputItemBox">
            {data.inputName && data.inputName !== "" ? <h2 className="inputName">{data.inputName}</h2> : ""}
            {data.type == "text" ? 
                <div className={`inputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
                    <input type="text" value={val} max={data.max && data.max !== "" ? data.max : "null"} className="inputItem" onClick={(e) => {if(data.clickEv){data.clickEv(e)}}} onInput={(e) => {valSetting(data.max ? e.target.value.slice(0, data.max) : e.target.value)}} onPaste={(e) => {valSetting(data.max ? e.target.value.slice(0, data.max) : e.target.value)}} onChange={(e) => {valSetting(data.max ? e.target.value.slice(0, data.max) : e.target.value)}} onKeyUp={(e) => {valSetting(data.max ? e.target.value.slice(0, data.max) : e.target.value)}} placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={data.readOnly ? data.readOnly : false} disabled={data.disabled ? data.disabled : false}/>
                    {data.errMsg && data.errMsg !== "" ? <p className="errMsg">{data.errMsg}</p> : ""}
                </div>
                : data.type == "phone" ? 
                <div className={`inputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
                    <input type="text" value={val} max={data.max && data.max !== "" ? data.max : "null"} onClick={(e) => {if(data.clickEv){data.clickEv(e)}}} onInput={(e) => {valSetting(PhoneSetting(e.target.value).slice(0, 13))}} onPaste={(e) => {valSetting(PhoneSetting(e.target.value).slice(0, 13))}} onChange={(e) => {valSetting(PhoneSetting(e.target.value).slice(0, 13))}} onKeyUp={(e) => {valSetting(PhoneSetting(e.target.value).slice(0, 13))}} inputMode="numeric" pattern="[0-9]*" className="inputItem" placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={data.readOnly ? data.readOnly : false} disabled={data.disabled ? data.disabled : false}/>
                    {data.errMsg && data.errMsg !== "" ? <p className="errMsg">{data.errMsg}</p> : ""}
                </div>
                : data.type == "smsChk" ? 
                <div className={`inputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""} ${data.errMsg && data.errMsg !== "" ? "err" : ""}`}>
                    <div className="btnCom">
                        <div className="certificationInput">
                            <input type="number" value={val} max={data.max && data.max !== "" ? data.max : "null"} onClick={(e) => {if(data.clickEv){data.clickEv(e)}}} onInput={(e) => {valSetting(data.max ? e.target.value.slice(0, data.max) : e.target.value)}} onPaste={(e) => {valSetting(data.max ? e.target.value.slice(0, data.max) : e.target.value)}} onChange={(e) => {valSetting(data.max ? e.target.value.slice(0, data.max) : e.target.value)}} onKeyUp={(e) => {valSetting(data.max ? e.target.value.slice(0, data.max) : e.target.value)}} inputMode="numeric" pattern="[0-9]*" className="inputItem certificationInputItem" placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={data.readOnly ? data.readOnly : false} disabled={data.disabled ? data.disabled : false}/>
                            <p className="certificationCount">{data.timer}</p>
                        </div>
                        <button type="button" className="btn_inputSubBtn btn_line btn_re" onClick={(e) => data.clickEv(e)}>재발송</button>
                    </div>
                    {data.errMsg && data.errMsg !== "" ? <p className="errMsg">{data.errMsg}</p> : ""}
                </div>
                : data.type == "registration" ? 
                <div className={`inputBox registrationNumberInput ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
                    <div className="registrationNumberBox">
                        <input type="number" value={val} max={data.max && data.max !== "" ? data.max : "null"} onClick={(e) => {if(data.clickEv){data.clickEv(e)}}} onInput={(e) => {valSetting(e.target.value.slice(0, 6))}} onPaste={(e) => {valSetting(e.target.value.slice(0, 6))}} onChange={(e) => {valSetting(e.target.value.slice(0, 6))}} onKeyUp={(e) => {valSetting(e.target.value.slice(0, 6))}} inputMode="numeric" pattern="[0-9]*" className="inputItem" placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={data.readOnly ? data.readOnly : false} disabled={data.disabled ? data.disabled : false}/>
                        <span>-</span>
                        <div className="registrationNumberBackBox">
                            <input type="number" value={val2} max={data.max && data.max !== "" ? data.max : "null"} onClick={(e) => {if(data.clickEv){data.clickEv(e)}}} onInput={(e) => {setVal2(e.target.value.slice(0, 1));data.func2(e.target.value.slice(0, 1))}} onPaste={(e) => {setVal2(e.target.value.slice(0, 1));data.func2(e.target.value.slice(0, 1))}} onChange={(e) => {setVal2(e.target.value.slice(0, 1));data.func2(e.target.value.slice(0, 1))}} onKeyUp={(e) => {setVal2(e.target.value.slice(0, 1));data.func2(e.target.value.slice(0, 1))}} inputMode="numeric" pattern="[0-9]*" className="inputItem" placeholder={data.placeholder2 && data.placeholder2 !== "" ? data.placeholder2 : ""} readOnly={data.readOnly ? data.readOnly : false} disabled={data.disabled ? data.disabled : false}/>
                            <p className="registrationNumberBack_text">******</p>
                        </div>
                    </div>
                    {data.errMsg && data.errMsg !== "" ? <p className="errMsg">{data.errMsg}</p> : ""}
                </div>
                : 
                data.type == "selItem" ? 
                <div className={`inputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
                    <input type="text" value={data.value} className="inputItem customSel" onClick={(e) => data.clickEv(e)} onInput={(e) => {data.func(e.target.value)}} onPaste={(e) => {data.func(e.target.value)}} onChange={(e) => {data.func(e.target.value)}} onKeyUp={(e) => {data.func(e.target.value)}} placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={data.readOnly ? data.readOnly : false} disabled={data.disabled ? data.disabled : false}/>
                    {data.errMsg && data.errMsg !== "" ? <p className="errMsg">{data.errMsg}</p> : ""}
                </div>
                : data.type == "number" ? 
                <div className={`inputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
                    <input type="text" inputMode="numeric" pattern="[0-9]*" value={val} max={data.max && data.max !== "" ? data.max : "null"} className="inputItem" onClick={(e) => {if(data.clickEv){data.clickEv(e)}}} onInput={(e) => {valSetting(data.max ? e.target.value.replace(/[^0-9]/g, '').slice(0, data.max) : e.target.value.replace(/[^0-9]/g, ''))}} onPaste={(e) => {valSetting(data.max ? e.target.value.replace(/[^0-9]/g, '').slice(0, data.max) : e.target.value.replace(/[^0-9]/g, ''))}} onChange={(e) => {valSetting(data.max ? e.target.value.replace(/[^0-9]/g, '').slice(0, data.max) : e.target.value.replace(/[^0-9]/g, ''))}} onKeyUp={(e) => {valSetting(data.max ? e.target.value.replace(/[^0-9]/g, '').slice(0, data.max) : e.target.value.replace(/[^0-9]/g, ''))}} placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={data.readOnly ? data.readOnly : false} disabled={data.disabled ? data.disabled : false}/>
                    {data.errMsg && data.errMsg !== "" ? <p className="errMsg">{data.errMsg}</p> : ""}
                </div> : ""
            }
        </div>
    );
}

//textarea
function TestAreaItem(data){
    const [val,setVal] = useState(data.value ? data.value : "");
    const [count,setCount] = useState(0);

    function valSetting(inputVal){
        setVal(inputVal);
        setCount(inputVal.length)
        data.func(inputVal);
    }

    useEffect(()=>{
        setVal(data.value ? data.value : "")
    },[data.setChk]);

    return (
        <div className="inputItemBox">
            {data.inputName && data.inputName !== "" ? <h2 className="inputName">{data.inputName}</h2> : ""}
            <div className={`inputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
                <div className={`reviewWriting_textAreaBox ${data.maxChk ? "maxChk" : ""}`}>
                    <textarea value={val} className="textareaItem" max={data.max && data.max !== "" ? data.max : "null"} onInput={(e) => {valSetting(data.max ? e.target.value.slice(0, data.max) : e.target.value)}} onPaste={(e) => {valSetting(data.max ? e.target.value.slice(0, data.max) : e.target.value)}} onChange={(e) => {valSetting(data.max ? e.target.value.slice(0, data.max) : e.target.value)}} onKeyUp={(e) => {valSetting(data.max ? e.target.value.slice(0, data.max) : e.target.value)}} placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={data.readOnly ? data.readOnly : false} disabled={data.disabled ? data.disabled : false}></textarea>
                    {data.maxChk ? <span className="textareaCount">{count} / {data.max}</span> : ""}
                </div>
                {data.errMsg && data.errMsg !== "" ? <p className="errMsg">{data.errMsg}</p> : ""}
            </div>
        </div>
    );
}

//파일첨부(api호출형)
function FileItem(data){
    const [val,setVal] = useState(data.value ? data.value : "");
    const [err,setErr] = useState(data.errMsg ? data.errMsg : "");
    const fileInput = useRef(null);

    function changeFile(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                let formData = new FormData();
                formData.append("card_image", input.files[0]);

                fatchSet.FatchApi({
                    type:"POST",
                    formDataItem: formData,
                    url: "/store/menu/gift/image",
                    loginType:"login",
                    success: (data) => {
                        setErr("");
                        setVal(data.data.card_image_url);
                        e.target.value = "";
                    },
                    err: (data) => {
                        setErr(data.alert);
                    }
                });
            }
            reader.readAsDataURL(input.files[0]);
            
            return input.files[0];
        }
    }

    function delImage(){
        setErr("");
        setVal("");
        fileInput.current.value = "";
    }

    useEffect(() => {
        data.func(val);
        setErr("");
    }, [val]);

    return (
        <div className="inputItemBox">
            {data.inputName && data.inputName !== "" ? <h2 className="inputName">{data.inputName}</h2> : ""}
            <div className={`inputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
                <div className="inputFileBox">
                    <input type="file" onChange={(e) => changeFile(e.target)} accept="image/*" ref={fileInput} id={data.id} style={{display:"none"}}/>
                    <label htmlFor={data.id} style={{backgroundImage:`url('${val}')`}}>
                        <button type="button" className="delImage" onClick={()=>delImage()} style={{display:`${val == "" ? "none" : "block"}`}}>
                            <img src="/assets/images/basic/del.svg"/>
                        </button>
                        <div className="inputFile_label_basic" style={{display:`${val == "" ? "flex" : "none"}`}}>
                            <img src="/assets/images/basic/upload.svg"/>
                            <p className="inputFile_label_text">이미지를 업로드 해주세요</p>
                        </div>
                    </label>
                </div>
                {err && err !== "" ? <p className="errMsg">{err}</p> : ""}
            </div>
        </div>
    );
}

//파일첨부(버튼 한정)
function FileItemBtn(data){
    const [val,setVal] = useState(data.value ? data.value : "");
    const [err,setErr] = useState(data.errMsg ? data.errMsg : "");
    const fileInput = useRef(null);

    function changeFile(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                let formData = new FormData();
                formData.append("card_image", input.files[0]);

                fatchSet.FatchApi({
                    type:"POST",
                    formDataItem: formData,
                    url: "/store/menu/gift/image",
                    loginType:"login",
                    success: (data) => {
                        setErr("");
                        setVal(data.data.card_image_url);
                        e.target.value = "";
                    },
                    err: (data) => {
                        setErr(data.alert);
                    }
                });
            }
            reader.readAsDataURL(input.files[0]);
            
            return input.files[0];
        }
    }

    useEffect(() => {
        data.func(val);
        fileInput.current.value = "";
        setErr("");
    }, [val]);

    return (
        <div className="inputItemBox">
            {data.inputName && data.inputName !== "" ? <h2 className="inputName">{data.inputName}</h2> : ""}
            <div className={`inputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
                <div className="inputFileBox">
                    <input type="file" onChange={(e) => changeFile(e.target)} accept="image/*" ref={fileInput} id={data.id} style={{display:"none"}}/>
                    <label htmlFor={data.id} className="btn_imageSel">
                        <img src="/assets/images/basic/camera_b.svg"/>
                        사진첩
                    </label>
                </div>
                {err && err !== "" ? <p className="errMsg">{err}</p> : ""}
            </div>
        </div>
    );
}

//파일첨부(다중)
function FileItemMultipleItem(data){
    const [img,setImg] = useState("");

    useEffect(()=>{
        let reader = new FileReader();
        reader.onload = function(e){
            setImg(e.target.result && e.target.result);
        }
        reader.readAsDataURL(data.data);
    },[]);

    return (
        <div className="fileItemMultipleItem" style={{backgroundImage:`url('${img}')`}}>
            <button type="button" className="fileItemMultipleItemDel" onClick={()=>data.func()}>
                <img src="/assets/images/basic/del.svg"/>
            </button>
        </div>
    );
}

function FileItemMultiple(data){
    const [err,setErr] = useState(data.errMsg ? data.errMsg : "");

    const [inputFiles,setInputFiles] = useState([]);

    const fileInput = useRef(null);

    let addfiles = [];
    let setAddFiles = [];

    function changeFile(input) {
        setAddFiles=[];
        if(data.max){
            let setInputArr = [...inputFiles, ...input.files];
            let setInputArrSplice = setInputArr.splice(0,data.max);
            setInputFiles([...setInputArrSplice]);
        }else{
            setInputFiles([...inputFiles, ...input.files]);
        }

        setErr("");
    }

    function inputReset(){
        fileInput.current.value = "";
    }

    function addFileFunc(item){
        setAddFiles.push(item);
    }

    function addFileSetting(){
        addfiles = [...addfiles, setAddFiles];
    }

    useEffect(()=>{
        data.func(...addfiles);
    },[addfiles]);

    return (
        <div className="inputItemBox">
            {data.inputName && data.inputName !== "" ? <h2 className="inputName">{data.inputName}</h2> : ""}
            <div className={`inputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
                <div className="inputFileBox">
                    <input type="file" multiple onChange={(e) => changeFile(e.target)} accept="image/*" ref={fileInput} id={data.id} style={{display:"none"}}/>
                    <div className="inputFileList">
                        {data.max && inputFiles.length >= data.max ? "" : <label htmlFor={data.id}>
                            <img src="/assets/images/basic/camera.svg"/>
                        </label>}
                        {
                            inputFiles && inputFiles.map((item,i)=>(
                                <Fragment key={i}>
                                    {item.type.split("/")[0] == "image" ? 
                                        (data.maxSise ? item.size <= (Number(data.maxSise) * 1024 * 1024) : true) ? 
                                            (data.max ? (addfiles.length + setAddFiles.length) < data.max : true) ? 
                                            <>
                                                {addFileFunc(item)}
                                                <FileItemMultipleItem
                                                    data={item}
                                                    func={()=>{
                                                        setErr("");
                                                        addfiles.splice(i,1);
                                                        let inputFileArr = [...inputFiles];
                                                        inputFileArr.splice(i,1);
                                                        setInputFiles(inputFileArr);
                                                    }}
                                                />
                                            </>
                                            : ""
                                        : setErr("지원하는 파일 확장자가 아닙니다.")
                                    : setErr(`${data.maxSise} 이하의 이미지를 첨부해주세요.`)}
                                    {inputFiles.length - 1 == i && inputReset()}
                                    {inputFiles.length - 1 == i && addFileSetting()}
                                </Fragment>
                            ))
                        }
                    </div>
                </div>
                {err && err !== "" ? <p className="errMsg">{err}</p> : ""}
            </div>
        </div>
    );
}

//체크박스
function ChkBox(data){
    let id = `chk_item_${Math.floor(Math.random() * 1000)}`;
    return (
        <div className={`chk_item ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
            <input type="checkbox" onChange={(e) => data.func(e)} className="chk_itemChk" id={id} checked={data.checkedType}/>
            <label htmlFor={id}>
                <p className="chk_item_text" dangerouslySetInnerHTML={{__html:`${data.chkSubType ? `<span>[${data.chkSubType}]</span>` : ""}${data.label}`}}></p>
            </label>
            {data.chkFunc ? <img src="/assets/images/basic/move.svg" onClick={(e) => data.chkFunc(e)}/> : ""}
        </div>
    );
}

export {InputItemBox, ChkBox, TestAreaItem, FileItem, FileItemMultiple, FileItemBtn}