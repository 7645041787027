import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "component/header";
import useGet from "api/useGet";
import { FooterManu } from "component/footer";
import { GiftListItem } from "component/list";
import { Tap } from "component/tap";

const GiftListPage = () => {
    const navigate = useNavigate();

    const [giftType,setGiftType] =  useState(1);
    const [tapNum,setTapNum] =  useState("사용가능");
    
    const giftList = useGet({
        url:`/gift?is_receive=${giftType}${giftType == 1 ? `&status=${tapNum}` : ""}`,
        loginType:"login"
    });

    useEffect(() => {
        var uaSet = window.navigator.userAgent;
        if (uaSet.toLocaleLowerCase().indexOf("kakaotalk") > -1 && !(/iPad|iPhone|iPod/.test(uaSet))) {
            window.location.href = "kakaotalk://inappbrowser/close";
            window.location.href='intent://'+window.location.href.replace(/https?:\/\//i,'')+'#Intent;scheme=http;end';
        }
    }, []);

    return (
        <div className="giftListPage bottomBtnPage">
            <Header
                customHeaderItem={<div className="giftType_selBox">
                    <button type="button" className={`giftType_sel ${giftType == 1 ? "active" : ""}`} onClick={(e) => {setGiftType(1)}}>받은선물</button>
                    <button type="button" className={`giftType_sel ${giftType == 0 ? "active" : ""}`} onClick={(e) => {setGiftType(0)}}>보낸선물</button>
                </div>}
            />
            {giftType == 1 ? <div className="topTap" style={{minHeight:"40px"}}>
                <Tap
                    data={[
                        {
                            id:"사용가능",
                            name:"사용가능"
                        },
                        {
                            id:"사용완료",
                            name:"사용완료"
                        },
                        {
                            id:"기간만료",
                            name:"기간만료"
                        }
                    ]}
                    val={tapNum}
                    func={(tapId) => setTapNum(tapId)}
                />
            </div> : ""}
            <div className="giftList" style={{paddingTop:giftType == 1 ? "104px" : "54px"}}>
                <GiftListItem
                    type={giftType}
                    tapType={tapNum}
                    data={giftList?.data?.orders}
                />
            </div>
            <FooterManu menuChk="3"/>
        </div>
    );
};

export default GiftListPage;