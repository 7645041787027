import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Header(headerData){
    let navigate = useNavigate();

    const [shKey,setShKey] = useState("");
    const [info,setInfo] = useState(false);

    function shSet(e){
        setShKey(e.target.value);
        if(e.keyCode == 13 || e.key == "Enter"){
            headerData.shFunc(shKey);
        }
    }

    return (
        <div className="header">
            <div className="headerSection">
                {!headerData.pageBack || headerData.pageBack === "" ? "" : <button type="button" className="btnBack" onClick={(e) => headerData.func(e)}><img src="/assets/images/basic/back.svg"/></button>}
                {headerData.headTitle ? <p className={`headTitle ${headerData.centerTitle ? "centerTitle" : ""}`}>{headerData.headTitle}</p> : ""}
                {headerData.customHeaderItem ? headerData.customHeaderItem : ""}
                {headerData.shLink ? <button type="button" className="btnSh btnHeaderRight" onClick={(e) => navigate("/sh")}><img src="/assets/images/basic/sh.svg"/></button> : ""}
                {headerData.sharing ? <button type="button" className="btnSharing btnHeaderRight" onClick={(e) => headerData.sharingFunc(e)}><img src="/assets/images/basic/sharing_min.svg"/></button> : ""}
                {
                    headerData.shBox ? 
                    <div className="header_shBox">
                        <input type="text" autoComplete="off" className="header_sh" onInput={(e) => setShKey(e.target.value)} onPaste={(e) => setShKey(e.target.value)} onChange={(e) => setShKey(e.target.value)} onKeyUp={(e) => shSet(e)} placeholder="검색어를 입력해보세요"/>
                        <button type="button" onClick={(e) => headerData.shFunc(shKey)} className="btn_header_sh"><img src="/assets/images/basic/sh.svg"/></button>
                    </div>
                    :""
                }
                {headerData.pageClose ? <button type="button" className="btnClose btnHeaderRight" onClick={(e) => headerData.func(e)}><img src="/assets/images/basic/close.svg"/></button> : ""}
                {headerData.setting ? <button type="button" className="btnSetting btnHeaderRight" onClick={(e) => navigate("/mypage/setting")}><img src="/assets/images/basic/setting.svg"/></button> : ""}
                {headerData.rightLink ? <button type="button" className="btnSetting btnHeaderRight" onClick={(e) => headerData.rightLink()}>{headerData.rightLinkText}</button> : ""}
                {headerData.info ? <div className="headerInfoBox btnHeaderRight">
                        <button type="button" className="btn_headerInfo" onClick={(e) => setInfo(!info)}>
                        <img src="/assets/images/basic/info.svg"/>
                        </button>
                        <p className="btn_headerInfoText" style={{display:`${info ? "block" : "none"}`}}><span dangerouslySetInnerHTML={{__html:headerData.info}}></span></p>
                    </div> : ""}
                {headerData.rightBtn ? headerData.rightBtn : ""}
            </div>
        </div>
    );
}