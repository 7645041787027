import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Popup } from "component/popup";
import * as fatchSet from "../../api/api";
import { getParam } from "js/function";

const OrderPay = () => {
    const navigate = useNavigate();

    const param = useParams();

    const type = param.type;
    const menuId = param.menuId;
    const storeId = param.storeId;
    const giftPhoneNumber = param.giftPhoneNumber;
    const option_id = param.option_id !== "null" && param.option_id ? param.option_id.indexOf(",") > 0 ? param.option_id.split(',') : [param.option_id] : [];
    const qty = param.qty;
    const payment_method = param.payment_method;
    const paymentKey = getParam("paymentKey");
    const order_number = getParam("orderId");
    const amount = param.amount;
    const card_image = param.card_image;
    const message = param.message;

    const [popupData,setPopupData] =  useState(null);

    useEffect(() => {
        let apiUrl = type == "gift" ? "/store/menu/purchase" : "/store/menu/purchase";
        let formData = new FormData();

        formData.append("menu_id", menuId);
        formData.append("qty", qty);
        formData.append("payment_method", payment_method);
        formData.append("paymentKey", paymentKey);
        formData.append("order_number", order_number);
        formData.append("amount", amount);

        option_id.length > 0 && option_id.forEach(function(item,i){
            formData.append("option_id[]", item);
        });

        if(type == "gift"){
            formData.append("is_gift", 1);
            formData.append("card_image", `https://${card_image.replace(/!DOT!/g,".").replace(/!SLICE!/g,"/")}`);
            formData.append("message", message);
            if(giftPhoneNumber){
                formData.append("phone", giftPhoneNumber);
            }
        }

        setTimeout(() => {
            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: apiUrl,
                loginType:"login",
                success: (data) => {
                    navigate(`/order/orderCom/${data?.data?.menu_id}/${data?.data?.store_id}/${data?.data?.id}/${type == "gift" ? 1 : 0}`)
                },
                err: (data) => {
                    if(data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert,
                            text:data.data ? data.data : "",
                            btn0:"확인",
                            btn1:"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{navigate(`/main/menu/${storeId}/${menuId}`)},
                            btnFunc1:(e)=>{}
                        });
                    }
                }
            });
        }, 200);
    }, []);

    return (
        <div className="orderPage">
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default OrderPay;