import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "component/header";
import useGet from "api/useGet";
import { comFormat, dDay } from "js/function";

const CsDetail = () => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;

    const [orderId,setOrderId] = useState(null);

    const csApi = useGet({
        url:`/mypage/contact/detail?id=${id}`,
        loginType:"login"
    });
    const csData = csApi?.data?.contact;

    const productApi = useGet({
        url:`/gift/detail?id=${orderId}`,
        loginType:"login"
    });
    const productData = orderId ? productApi?.data?.order : null;

    function BankList(data){
        return (
            <div className="bankItem">
                <p className="bankName">{data?.name}</p>
                <p className="bankVal">{data?.val}</p>
            </div>
        );
    }

    useEffect(() => {
        setOrderId(csData ? csData?.order_id : null);
    }, [csData]);

    return (
        <div className="csPage">
            <Header
                pageBack = {true}
                func={() => navigate(-1)}
                headTitle="1:1 문의 상세"
                centerTitle={true}
                customHeaderItem={<p className={`csList_status btnHeaderRight ${csData?.status == 1 ? "gColor" : ""}`}>{csData?.status == 1 ? "답변완료" : "답변대기"}</p>}
            />
            <div className="csDetail_section">
                <p className="csDetail_type">{csData?.contact_type}</p>
                <h1 className="csDetail_title">{csData?.title}</h1>
                {productData && productData?.id ?
                <div className="csDetailProduct_infoBox" onClick={()=>{navigate(`/mypage/orderDetail/${csData?.order_id}`)}}>
                    <div className="csDetailProduct_infoImgBox" style={{backgroundImage:`url('${productData?.menu_image}')`}}>
                        {productData?.order_status == "사용가능" ? <p className="csDetailProduct_infoCount">D-{dDay(productData?.limit_date)}</p> : ""}
                    </div>
                    <div className="csDetailProduct_infoTextBox">
                        <p className="csDetailProduct_store">{productData?.store_name}</p>
                        <h1 className="csDetailProduct_name">{productData?.menu_name}</h1>
                        <p className="csDetailProduct_price">{`${comFormat(Math.floor(productData?.total_price))}원`}</p>
                    </div>
                </div> : ""}
                <p className="csDetail_text">{csData?.contents}</p>
                {csData && csData?.images.length > 0 &&
                    <div className="csDetail_imgArea">
                        {csData?.images.map((item, i) => (
                            <div className="csDetail_img" key={i} style={{backgroundImage:`url('${item?.image_url}')`}}></div>
                        ))}
                    </div>
                }
                {csData && csData?.bank_name ? <>
                    <BankList
                        name="은행"
                        val={csData?.bank_name}
                    />
                    <BankList
                        name="예금주"
                        val={csData?.bank_user}
                    />
                    <BankList
                        name="환불 받을 계좌번호"
                        val={csData?.bank_account}
                    />
                </> : ""}
            </div>
            {csData && csData?.status == 1 &&
                <div className="csDetail_answer">
                    <h2 className="csDetail_answer_title">앨리펀 답변</h2>
                    <p className="csDetail_answer_text">{csData?.answer}</p>
                </div>
            }
        </div>
    );
};

export default CsDetail;