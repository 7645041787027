import { Html5Qrcode } from 'html5-qrcode';
import { useEffect, useRef } from 'react';
import { Popup } from './popup';
import { useState } from 'react';

const Html5QrcodePlugin = (props) => {
    const qrBox = useRef(null);

    useEffect(() => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const aspectRatioChk = width / (height - 78)
        const reverseAspectRatio = (height - 78) / width

        const mobileAspectRatio = reverseAspectRatio > 1.5 ? reverseAspectRatio + (reverseAspectRatio * 12 / 100) : reverseAspectRatio;

        const html5QrCodeStart = new Html5Qrcode("qrItem");

        html5QrCodeStart.start({ facingMode: "environment" }, {fps: 10,aspectRatio: width < height ? mobileAspectRatio : aspectRatioChk}, props.qrCodeSuccessCallback);

        const playSet = setInterval(() => {
            if(document.querySelectorAll("#qrItem")[0].querySelectorAll("video")[0]){
                setTimeout(() => {
                    document.querySelectorAll("#qrItem")[0].querySelectorAll("video")[0].setAttribute("muted",true);
                    document.querySelectorAll("#qrItem")[0].querySelectorAll("video")[0].play();
                    clearInterval(playSet);
                }, 500);
            }
        }, 100);
    }, []);

    return (
        <div id="qrItem" ref={qrBox}/>
    );
};

export default Html5QrcodePlugin;