import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useGet from "api/useGet";
import { GiftListItem } from "component/list";

const PublicGiftListPage = () => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;
    
    const giftList = useGet({
        url:`/gift/group?id=${id}`,
        loginType:"login"
    });

    useEffect(() => {
        var uaSet = window.navigator.userAgent;
        if (uaSet.toLocaleLowerCase().indexOf("kakaotalk") > -1 && !(/iPad|iPhone|iPod/.test(uaSet))) {
            window.location.href = "kakaotalk://inappbrowser/close";
            window.location.href='intent://'+window.location.href.replace(/https?:\/\//i,'')+'#Intent;scheme=http;end';
        }
    }, []);

    return (
        <div className="giftListPage">
            <div className="giftList" style={{paddingTop:"30px"}}>
                <GiftListItem
                    type={1}
                    tapType="publicList"
                    data={giftList?.data?.orders}
                />
            </div>
        </div>
    );
};

export default PublicGiftListPage;