import { comFormat, dDay } from "js/function";
import { Fragment, useEffect, useState } from "react";
//취소/환불 상품권 선택
function ProductSelItem(data){
    return (
        <div className="productSelItem_infoBox">
            <div className="productSelItem_infoImgBox" style={{backgroundImage:`url('${data?.data?.menu_image}')`}}>
                {data?.data?.order_status == "사용가능" ? <p className="productSelItem_infoCount">D-{dDay(data?.data?.limit_date)}</p> : ""}
            </div>
            <div className="productSelItem_infoTextBox">
                <p className="productSelItem_store">{data?.data?.store_name}</p>
                <h1 className="productSelItem_name">{data?.data?.menu_name}</h1>
                <p className="productSelItem_price">{comFormat(Math.floor(data?.data?.total_price))}</p>
            </div>
        </div>
    );
}

function ProductSelPopup(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");

    const [chkId,setChkId] =  useState(null);
    
    useEffect(()=>{
        setPopupData(data.data)
    },[data.data]);

    useEffect(()=>{
        if(popupData){
            setTimeout(() => {
                setPopupOpen("block");
                setTimeout(() => {
                    setPopupClass("active");
                }, 100);
            }, 200);
        }else{
            setPopupClass("");
            setTimeout(() => {
                setPopupOpen("none");
            }, 200);
        }
    },[popupData]);

    return (
        <div className={`selPopup ${popupClass ? popupClass : ""} ${popupData?.addClass ? popupData?.addClass : ""}`} style={{display:popupOpen}}>
            <div className="popup_cover" onClick={(e) => setPopupData(null)}></div>
            <div className="selPopupSetion">
                <div className="selPopupTextBox">
                    <h2 className="popupTitle">상품권 선택</h2>
                </div>
                <div className="selPopup_selBox yScroll">
                    {data?.productList && data.productList.map((item,i)=>(
                        <div className="chkSelItemBox" key={i}>
                            <input type="radio" name="chk_itemChk" onChange={(e) => setChkId(item?.id)} className="chk_itemChk" id={`chkSelItem_${item?.id}`} checked={chkId == item?.id ? true : false}/>
                            <label htmlFor={`chkSelItem_${item?.id}`} className="chkSelItem">
                                <ProductSelItem
                                    data={item}
                                />
                            </label>
                        </div>
                    ))}
                </div>
                <div className="selPopupBtnBox">
                    <button type="button" className="pageBtn" onClick={()=>{setPopupData(null);popupData?.func(chkId)}}>확인</button>
                </div>
            </div>
        </div>
    );
}

function ProductSelInput(data){
    const [val,setVal] = useState(data.value ? data.value : "");
    const [selPopup,setSelPopup] =  useState(null);

    function selOpen(){
        setSelPopup({
            addClass:null,
            func:(e) => {
                setVal(e)
                data.func(e)
            }
        })
    }

    useEffect(()=>{
        setVal(data.value ? data.value : "");
    },[data.value]);

    return (
        <div className="inputItemBox">
            {data.inputName && data.inputName !== "" ? <h2 className="inputName">{data.inputName}</h2> : ""}
            {val == "" ? 
            <div className="inputBox type2">
                <input type="text" value={val} className="inputItem customSel" onClick={(e) => selOpen()} onInput={(e) => {data.func(e.target.value)}} onPaste={(e) => {data.func(e.target.value)}} onChange={(e) => {data.func(e.target.value)}} onKeyUp={(e) => {data.func(e.target.value)}} placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={true}/>
            </div>
            :
            <>
                <div className="productSelInfo">
                    {data.selItemData && data.selItemData.id &&
                    <ProductSelItem
                        data={data.selItemData}
                    />}
                </div>
                <button type="button" className="pageBtn wType" onClick={()=>selOpen()}>다시 선택하기</button>
            </>
            }
            <ProductSelPopup
                data={selPopup}
                productList={data?.productList}
            />
        </div>
    );
}

export {ProductSelInput}