//가로스크롤 박스
export default function ListBox(data){
    return (
        <>
            {data.type == "horizontalScroll" ? 
                <div className={`horizontalScroll ${data.addClass ? data.addClass : ""}`}>
                    {data.children}
                </div>
            : ""}
        </>
    );
}