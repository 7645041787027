import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "component/header";
import { OrderInfo } from "component/list";
import useGet from "api/useGet";
import { comFormat } from "js/function";
import { Etc } from "component/etc";
import { BtnBox, BtnBoxV2 } from "component/btns";

const MyOrderDetail = () => {
    const navigate = useNavigate();

    const param = useParams();
    const id = param.id;

    const orderApi = useGet({
        url:`/mypage/order/detail?id=${id}`,
        loginType:"login"
    });
    const orderData = orderApi?.data?.order;

    const etcData = useGet({
        url:`/etc`,
        loginType:"login"
    });

    useEffect(() => {

    }, []);

    return (
        <div className="myOrderPage">
            <Header
                pageBack = {true}
                func={() => navigate(-1)}
                headTitle="구매내역 상세"
                centerTitle={true}
            />
            <div className={`myOrderDetail_section 
            ${orderData?.gift_type == "mySend" && (orderData?.order_status == "사용가능" || orderData?.order_status == "기간만료") ? "addBtns" : ""}`}>
                <div className="orderDetailInfo">
                    <OrderInfo
                        data={orderData}
                        detail={true}
                    />
                </div>
                <div className="deail_etcBox">
                    <Etc
                        data={etcData?.data?.etc_infos}
                        notProduct={true}
                    />
                </div>
                <div className={`detail_totalPrice ${
                    orderData?.gift_type == "mySend" && (orderData?.order_status == "사용가능" || orderData?.order_status == "기간만료") ? "addBtn_div" : ""
                }`}>
                    <p className="detail_totalPrice_name">총 결제금액</p>
                    <p className="detail_totalPrice_text">{`${orderData?.total_price ? comFormat(Math.floor(orderData?.total_price)) : 0}원`}</p>
                </div>
                {(orderData?.gift_type == "mySend" || orderData?.gift_type == "receive") && orderData?.order_status == "사용가능" ? 
                    <BtnBoxV2
                        addClass="col2"
                        leftText="취소/환불 문의"
                        rightText="사용하기"
                        leftAddClass="wType"
                        leftFunc={()=>{navigate(`/cs/cancel/${id}/결제 취소`)}}
                        rightFunc={()=>{navigate(`/gift/detail/${id}/0`)}}
                    />
                    :
                    (orderData?.gift_type == "mySend" || orderData?.gift_type == "receive") && orderData?.order_status == "기간만료" ? 
                    <BtnBox
                        text="취소/환불 문의"
                        btnAddClass="wType"
                        func={()=>{navigate(`/cs/cancel/${id}/상품권 환불`)}}
                    />
                    :
                    ""
                }
            </div>
        </div>
    );
};

export default MyOrderDetail;