import { useEffect, useState } from "react";

//스토어 상세 관심매장 버튼
export default function DetailLike(data){
    const [like,setLike] = useState(data.likeType);
    
    useEffect(()=>{
        setLike(data.likeType);
    },[data.likeType])

    return (
        <button type="button" onClick={() => {data.like(data.id,like == "Y" ? 0 : 1);setLike(like == "Y" ? "N" : "Y")}} className="detailInfoMenuItem">
            <img src={`/assets/images/icon/${like == "Y" ? "detail_like_on.svg" : "detail_like.svg"}`}/>
            관심매장
        </button>
    );
}