//마이페이지 버튼 형태
export default function MypageListItem(data){
    return (
        <button type="button" className={`mypage_linkItem ${data.addClass ? data.addClass : ""}`} onClick={()=>data.func()}>
            <h2 className="mypage_linkName">{data.name}</h2>
            <div className="mypage_linkItemBox">
                {data.chkType ? 
                    <div className="toggleBox">
                        <input type="checkbox" onChange={(e)=>{data.chkFunc(e.target.checked)}} id={data.id} checked={data.checkedType} className="toggleChk"/>
                        <label htmlFor={data.id}>
                            <span></span>
                        </label>
                    </div>
                    :
                    <>
                        <p className={`mypage_linkText ${data.rColor ? "rColor" : ""}`}>{data.text}</p>
                        <img src="/assets/images/basic/move.svg"/>
                    </>
                }
            </div>
        </button>
    );
}
