import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "component/header";
import { CsList } from "component/list";
import useGet from "api/useGet";
import { BtnBox } from "component/btns";

const Cs = () => {
    const navigate = useNavigate();

    const csApi = useGet({
        url:`/mypage/contact`,
        loginType:"login"
    });
    const csData = csApi?.data?.contacts;

    useEffect(() => {

    }, []);

    return (
        <div className="csPage">
            <Header
                pageBack = {true}
                func={() => navigate(-1)}
                headTitle="1:1 문의"
                centerTitle={true}
            />
            <div className="cs_section">
                <CsList
                    data={csData}
                />
            </div>
            <BtnBox
                text="문의 작성하기"
                func={()=>{navigate("/cs/writing")}}
            >
                {csData && csData.length > 0 ? <p className="btnSubText">1:1 문의가 필요하신가요?</p> : ""}
            </BtnBox>
        </div>
    );
};

export default Cs;