import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {FooterManu} from "component/footer";
import Header from "component/header";
import { BottomErrMsg, Popup, SelPopup } from "component/popup";
import useGet from "api/useGet";
import PickList from "component/pick";
import { storeLike } from "js/function";

const PickPage = () => {
    const navigate = useNavigate();

    const [bottomMsgData,setBottomMsgData] =  useState("");

    const [popupData,setPopupData] =  useState(null);

    const pickList = useGet({
        url:`/pick?user_id=${localStorage.getItem("user_id")}`,
        loginType:"login"
    });

    useEffect(() => {
        
    }, []);

    return (
        <div className="pickPage">
            <Header
                headTitle="PICK"
            />
            <div className="pickList_section">
                <PickList
                    data={pickList?.data?.picks}
                    like={(selId,type) => storeLike({
                        selId : selId,
                        type : type,
                        func : () => {
                            setBottomMsgData({
                                text : "관심매장이 추가되었습니다.",
                                chk : bottomMsgData.chk + 1
                            });
                        },
                        loginErr : () => {
                            setPopupData({
                                addClass:null,
                                title:"로그인이 필요합니다.",
                                text:"",
                                btn0:"취소",
                                btn1:"로그인",
                                closeType:false,
                                closeFunc:(e) => {},
                                btnFunc0:(e)=>{},
                                btnFunc1:(e)=>{
                                    let nowUrl = new URL(window.location.href);
                                    sessionStorage.setItem("setUrl",nowUrl.pathname);
                                    navigate("/member/login");
                                }
                            });
                        }
                    })}
                />
            </div>
            <FooterManu menuChk="2"/>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default PickPage;