import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "component/header";
import { BottomErrMsg, Popup } from "component/popup";
import { CalendarList } from "component/list";
import useGet from "api/useGet";
import { Tap } from "component/tap";
import { storeLike } from "js/function";

const MyLikePage = () => {
    const navigate = useNavigate();

    const [giftType,setGiftType] = useState(1);
    const [likeCh,setLikeCh] = useState(true);

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const [popupData,setPopupData] =  useState(null);

    const listApi = useGet({
        url:`/mypage/like?can_gift=${giftType}&likeCh=${likeCh}`,
        loginType:"login"
    });
    const listData = listApi?.data?.stores;

    useEffect(() => {

    }, []);

    return (
        <div className="likePage">
            <Header
                pageBack = {true}
                func={() => navigate(-1)}
                headTitle="나의 관심매장"
                centerTitle={true}
            />
            <div className="likeList_section">
                <div className="topTap" style={{minHeight:"40px"}}>
                    <Tap
                        data={[
                            {
                                id:1,
                                name:"선물 가능 매장"
                            },
                            {
                                id:0,
                                name:"일반 매장"
                            }
                        ]}
                        val={giftType}
                        func={(tapId) => setGiftType(tapId)}
                    />
                </div>
                <div className="calendarList_area">
                    <div className="calendarList">
                        <CalendarList
                            data={listData}
                            like={(selId,type) => storeLike({
                                selId : selId,
                                type : type,
                                func : () => {
                                    setBottomMsgData({
                                        text : "관심매장이 추가되었습니다.",
                                        chk : bottomMsgData.chk + 1
                                    });
                                    setLikeCh(!likeCh);
                                },
                                loginErr : () => {
                                    setPopupData({
                                        addClass:null,
                                        title:"로그인이 필요합니다.",
                                        text:"",
                                        btn0:"취소",
                                        btn1:"로그인",
                                        closeType:false,
                                        closeFunc:(e) => {},
                                        btnFunc0:(e)=>{},
                                        btnFunc1:(e)=>{
                                            let nowUrl = new URL(window.location.href);
                                            sessionStorage.setItem("setUrl",nowUrl.pathname);
                                            navigate("/member/login");
                                        }
                                    });
                                }
                            })}
                            notText="관심매장이 없습니다"
                            likeChk={true}
                        />
                    </div>
                </div>
            </div>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default MyLikePage;