import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { LikeBtn, LikeBtnMin } from "./likeBtn";
import {
  comFormat,
  dDay,
  PhoneSetting,
  setDateTime,
  slideToggle,
} from "js/function";
import { useEffect, useState } from "react";
import { Popup } from "./popup";
import * as fatchSet from "../api/api";

//스토어 목록
function StoreList(data) {
  const navigate = useNavigate();
  return (
    <>
      {data.data?.map((item, i) => (
        <div key={item.id} className="store_listItem">
          <button
            type="button"
            onClick={() => navigate(`${data.storeLink}${item.id}/0`)}
            className="listItem_imgBox"
          >
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              pagination={{
                type: "fraction",
              }}
              loop={true}
              modules={[Pagination]}
            >
              {item.images?.map((imgItem, index) => (
                <SwiperSlide key={imgItem.id}>
                  <div
                    className="listItemImg"
                    style={{ backgroundImage: `url('${imgItem.image_url}')` }}
                  ></div>
                </SwiperSlide>
              ))}
            </Swiper>
          </button>
          <div className="listItem_infoBox">
            <button
              type="button"
              onClick={() => navigate(`${data.storeLink}${item.id}/0`)}
              className="listItem_info"
            >
              <div className="listItem_textBox">
                <h1 className="listItem_title">{item.store_name}</h1>
                <p className="listItem_text">{item.store_introduce}</p>
              </div>
            </button>
            <LikeBtn id={item.id} likeType={item.is_my_like} like={data.like} />
          </div>
        </div>
      ))}
    </>
  );
}
//메뉴 목록
function MenuList(data) {
  const navigate = useNavigate();

  return (
    <>
      {data.data?.map((item, i) => (
        <button
          type="button"
          onClick={() => navigate(`${data.menuLink}${item.id}`)}
          className="menuListItem"
          key={`menuList_${item.id}`}
        >
          <div
            className="menuListItemImg"
            style={{ backgroundImage: `url('${item.menu_image}')` }}
          ></div>
          <div className="menuListItem_info">
            <h1 className="menuListItem_name">{item.menu_name}</h1>
            {item.menu_description ? (
              <p className="menuListItem_text">{item.menu_description}</p>
            ) : (
              ""
            )}
            <div className="menuListItem_priceBox">
              {item.sale_price > 0 || item.platform_sale_price > 0 ? (
                <>
                  <p className="menuListItem_price_saleVal">
                    {Math.floor(
                      ((item.sale_price
                        ? Number(item.sale_price)
                        : 0 + item.platform_sale_price
                        ? Number(item.platform_sale_price)
                        : 0) /
                        Number(item.price)) *
                        100
                    )}
                    %
                  </p>
                  <p className="menuListItem_price_basic">
                    {comFormat(Math.floor(item.price))} 원
                  </p>
                </>
              ) : (
                ""
              )}
              <p className="menuListItem_price">
                {item.sale_price > 0 || item.platform_sale_price > 0
                  ? comFormat(
                      Math.floor(
                        Number(item.price) -
                          (Number(item.sale_price) +
                            Number(item.platform_sale_price))
                      )
                    )
                  : comFormat(Math.floor(item.price))}{" "}
                원
              </p>
            </div>
          </div>
        </button>
      ))}
    </>
  );
}
//메뉴 목록(단일)
function MenuListItem(data) {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      onClick={() => navigate(`${data.menuLink}${data.id}/0`)}
      className="menuListItem"
      key={`menuList_${data.id}`}
    >
      <div
        className="menuListItemImg"
        style={{ backgroundImage: `url('${data.menu_image}')` }}
      ></div>
      <div className="menuListItem_info">
        <h1 className="menuListItem_name">{data.menu_name}</h1>
        <p className="menuListItem_text">{data.menu_text}</p>
      </div>
    </button>
  );
}
//캘린더형 리스트
function CalendarList(data) {
  const navigate = useNavigate();

  return (
    <>
      {data?.data && data?.data?.length > 0 ? (
        <>
          {data?.data &&
            data?.data?.map((item, i) => (
              <div className="calendarListItem" key={`shList_${item?.id}_${i}`}>
                <div className="calendarListItemImgBox">
                  <button
                    className="calendarListItemImg"
                    onClick={() => navigate(`/main/detail/${item?.id}/0`)}
                    style={{
                      backgroundImage: `url('${
                        item?.images
                          ? item?.images[0]?.image_url
                          : item?.image_url
                      }')`,
                    }}
                  ></button>
                  <LikeBtnMin
                    id={item?.id}
                    likeType={data.likeChk ? "Y" : item?.is_my_like}
                    like={data.like}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => navigate(`/main/detail/${item?.id}/0`)}
                  className="calendarListItem_info"
                >
                  <h1 className="calendarListItem_name">{item?.store_name}</h1>
                  <p className="calendarListItem_text">
                    {item?.store_introduce}
                  </p>
                </button>
              </div>
            ))}
        </>
      ) : (
        <div className="notList">
          <img src="/assets/images/icon/not_list.svg" />
          {data.notText ? data.notText : ""}
        </div>
      )}
    </>
  );
}
//기본형 리스트(카페 테마)
function BasicList(data) {
  const navigate = useNavigate();

  return (
    <div className="basicListItem" key={`basicList_${data?.data?.id}`}>
      <div className="basicListItemImgBox">
        <button
          className="basicListItemImg"
          onClick={() => navigate(`/main/detail/${data?.data?.id}/0`)}
          style={{ backgroundImage: `url('${data?.data?.image_url}')` }}
        ></button>
      </div>
      <button
        type="button"
        onClick={() => navigate(`/main/detail/${data?.data?.id}/0`)}
        className="basicListItem_info"
      >
        <h1 className="basicListItem_name">{data?.data?.store_name}</h1>
        <p className="basicListItem_text">{data?.data?.store_introduce}</p>
      </button>
    </div>
  );
}
//카테고리 스토어 목록
function CafeListItem(data) {
  const navigate = useNavigate();

  return (
    <>
      {data?.data && data?.data?.length > 0 ? (
        <>
          {data?.data &&
            data?.data?.map((item, i) => (
              <div className="cafeListItem" key={`cafeList_${item?.id}_${i}`}>
                <button
                  type="button"
                  onClick={() => navigate(`/main/detail/${item?.id}/0`)}
                  className="cafeListItemImgBox"
                >
                  <Swiper spaceBetween={0} slidesPerView={1} loop={true}>
                    {item.images?.map((imgItem, index) => (
                      <SwiperSlide key={imgItem.id}>
                        <div
                          className="cafeListItemImg"
                          style={{
                            backgroundImage: `url('${imgItem.image_url}')`,
                          }}
                        ></div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className="cafeListItem_subImgBox">
                    <div
                      className="cafeListItemImg"
                      style={{
                        backgroundImage: `url('${item?.images[1]?.image_url}')`,
                      }}
                    ></div>
                    <div
                      className="cafeListItemImg"
                      style={{
                        backgroundImage: `url('${item?.images[2]?.image_url}')`,
                      }}
                    ></div>
                  </div>
                </button>
                <div className="listItem_infoBox">
                  <button
                    type="button"
                    onClick={() => navigate(`/main/detail/${item.id}/0`)}
                    className="listItem_info"
                  >
                    <div className="listItem_textBox">
                      <h1 className="listItem_title">{item.store_name}</h1>
                      <p className="listItem_text">{item.store_introduce}</p>
                    </div>
                  </button>
                  <LikeBtn
                    id={item.id}
                    likeType={item.is_my_like}
                    like={data.like}
                  />
                </div>
              </div>
            ))}
        </>
      ) : (
        <div className="notList">
          <img src="/assets/images/icon/not_list.svg" />
          카테고리에 맞는 매장이 없어요
        </div>
      )}
    </>
  );
}
//상품권목록
function GiftListItem(data) {
  const navigate = useNavigate();
  const [popupData, setPopupData] = useState(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://t1.kakaocdn.net/kakao_js_sdk/2.1.0/kakao.min.js";
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  const shareKakao = (itemId) => {
    fatchSet.FatchApi({
      type: "GET",
      url: `/gift/detail?id=${itemId}`,
      loginType: "login",
      success: (apiData) => {
        const giftData = apiData?.data?.order;

        if (window.Kakao) {
          const kakao = window.Kakao;

          if (!kakao.isInitialized()) {
            kakao.init(process.env.REACT_APP_SHARE_KAKAO_LINK_KEY);
          }

          kakao.Share.sendDefault({
            objectType: "feed",
            content: {
              title: `${giftData?.gift_name} 님의 선물을 확인하세요!`,
              description:
                "소중한 마음이 담긴 편지와 선물을 지금 바로 확인해보세요.",
              imageUrl: giftData?.gift_image,
              link: {
                mobileWebUrl: `${process.env.REACT_APP_URL}/gift/${giftData?.coupon_numbers[0]?.coupon_number}`,
                webUrl: `${process.env.REACT_APP_URL}/gift/${giftData?.coupon_numbers[0]?.coupon_number}`,
              },
            },
            buttons: [
              {
                title: "선물 확인하기",
                link: {
                  mobileWebUrl: `${process.env.REACT_APP_URL}/gift/${giftData?.coupon_numbers[0]?.coupon_number}`,
                  webUrl: `${process.env.REACT_APP_URL}/gift/${giftData?.coupon_numbers[0]?.coupon_number}`,
                },
              },
            ],
          });
        }
      },
      err: (apiData) => {
        setPopupData({
          addClass: null,
          title: "선물 보내기 실패",
          text: "일시적인 문제로 인해 선물 보내기에 실패하였습니다.<br/>잠시 후 다시 시도해주세요.",
          btn0: "확인",
          btn1: "",
          closeType: true,
          closeFunc: (e) => {},
          btnFunc0: (e) => {},
          btnFunc1: (e) => {},
        });
      },
    });
  };

  return (
    <>
      {data?.data && data?.data?.length > 0 ? (
        <>
          {data?.data &&
            data?.data?.map((item, i) => (
              <div className="giftListItem" key={item.id}>
                {((item.order_status == "사용가능" && data.type == 0) ||
                  item.order_status == "기간만료/환불" ||
                  item.order_status == "사용완료" ||
                  item.order_status == "관리자 발행취소") && (
                  <button
                    type="button"
                    onClick={() => {
                      navigate(
                        `/gift/detail/${
                          data.tapType !== "publicList"
                            ? item.id
                            : item.giftCard_number
                        }/0`
                      );
                    }}
                    className="giftStatus"
                  >
                    <p className="giftStatusText">
                      <span
                        className={`${
                          item.order_status == "기간만료/환불" ||
                          item.order_status == "관리자 발행취소"
                            ? "rColor"
                            : ""
                        }`}
                      >
                        {item.order_status == "사용가능"
                          ? `D-${dDay(item.limit_date)} 남음`
                          : item.order_status}
                      </span>
                      {item.order_status == "사용완료"
                        ? setDateTime(item.use_date, "yymmddhhtt", "")
                        : item.order_status == "기간만료/환불" ||
                          item.order_status == "관리자 발행취소"
                        ? item.refund_confrim_date &&
                          item.refund_confrim_date !== "-"
                          ? setDateTime(
                              item.refund_confrim_date,
                              "yymmddhhtt",
                              ""
                            )
                          : item.refund_request_date &&
                            item.refund_request_date !== "-"
                          ? setDateTime(
                              item.refund_request_date,
                              "yymmddhhtt",
                              ""
                            )
                          : item.cancel_confrim_date &&
                            item.cancel_confrim_date !== "-"
                          ? setDateTime(
                              item.cancel_confrim_date,
                              "yymmddhhtt",
                              ""
                            )
                          : item.cancel_request_date &&
                            item.cancel_request_date !== "-"
                          ? setDateTime(
                              item.cancel_request_date,
                              "yymmddhhtt",
                              ""
                            )
                          : setDateTime(item.limit_date, "yymmddhhtt", "")
                        : ""}
                    </p>
                    <img src="/assets/images/basic/move_g.svg" />
                  </button>
                )}
                <div className="giftListItem_infoBox">
                  <div
                    className="giftListItem_infoImgBox"
                    style={{ backgroundImage: `url('${item.menu_image}')` }}
                  >
                    {item.order_status == "사용가능" ? (
                      <p className="giftListItem_infoCount">
                        D-{dDay(item.limit_date)}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="giftListItem_infoTextBox">
                    <p
                      className="giftListItem_store"
                      onClick={() => {
                        if (data.tapType !== "publicList")
                          navigate(`/main/detail/${item.store_id}/0`);
                      }}
                    >
                      {item.store_name}
                    </p>
                    <div className="giftListItem_nameBox">
                      <h1 className="giftListItem_name">{item.menu_name}</h1>
                      {item.options ? (
                        <p className="giftListItem_option">{item.options}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="giftListItem_btnBox">
                  {item.order_status == "기간만료/환불" &&
                  (data.type == 1 || item.gift_type == "receive") &&
                  item.is_admin == 0 ? (
                    <button
                      type="button"
                      className="giftListItem_btn"
                      onClick={() => {
                        navigate(`/cs/cancel/${item.id}/상품권 환불`);
                      }}
                    >
                      환불문의
                    </button>
                  ) : (
                    ""
                  )}
                  {item.order_status == "사용가능" &&
                  data.type == 0 &&
                  data.tapType !== "publicList" ? (
                    <button
                      type="button"
                      className="giftListItem_btn"
                      onClick={() => {
                        shareKakao(item.id);
                      }}
                    >
                      선물 보내기
                    </button>
                  ) : (
                    ""
                  )}
                  <button
                    type="button"
                    className="giftListItem_btn"
                    onClick={() => {
                      navigate(
                        `/gift/detail/${
                          data.tapType !== "publicList"
                            ? item.id
                            : item.giftCard_number
                        }/0`
                      );
                    }}
                  >
                    {item.order_status == "사용가능" && data.type == 1
                      ? "사용하기"
                      : "상세정보"}
                  </button>
                  {item.order_status == "사용가능" && item.is_admin == 0 ? (
                    <button
                      type="button"
                      className="giftListItem_btn"
                      onClick={() => {
                        navigate(`/cs/cancel/${item.id}/결제 취소`);
                      }}
                    >
                      취소문의
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
        </>
      ) : (
        <div className="notList">
          <img src="/assets/images/icon/not_list.svg" />
          {data.type == 0
            ? "보낸선물이 없습니다"
            : data.tapType == "publicList"
            ? "사용가능한 선물이 없습니다"
            : data.tapType == "사용가능"
            ? "사용가능한 선물이 없습니다"
            : data.tapType == "사용완료"
            ? "사용완료된 선물이 없습니다"
            : data.tapType == "기간만료"
            ? "기간만료된 선물이 없습니다"
            : ""}
        </div>
      )}
      <Popup data={popupData} />
    </>
  );
}
//구매내역
function OrderInfoItem(info) {
  return (
    <div
      className={`orderList_infoList ${
        info.detail ? "orderList_infoDetail" : ""
      }`}
    >
      <p className="orderList_infoList_name">{info.name}</p>
      <p
        className={`orderList_infoList_val ${info.gVal ? "gColor" : ""} ${
          info.rVal ? "rColor" : ""
        } ${info.boldVal ? "typeBold" : ""}`}
      >
        {info.text}
      </p>
    </div>
  );
}

function OrderInfo(data) {
  return (
    <>
      <div className="orderList_head">
        <h2 className="orderList_date">
          <b>결제일시</b> {setDateTime(data.data?.purchase_at, "", "")}
        </h2>
        {data.detail ? (
          <p
            className={`myOrderDetail_status ${
              data.data?.order_status == "사용가능"
                ? "gColor"
                : data.data?.order_status == "기간만료/환불"
                ? "rColor"
                : ""
            }`}
          >
            {data.data?.order_status}
          </p>
        ) : (
          <img src="/assets/images/basic/move_g.svg" />
        )}
      </div>
      <div className={data.detail ? "orderDetailInfoBox" : ""}>
        <div className="orderList_data">
          <div
            className="orderList_img"
            style={{ backgroundImage: `url('${data.data?.menu_image}')` }}
          ></div>
          <div className="orderList_info">
            <p className="orderList_store">{data.data?.store_name}</p>
            <h2 className="orderList_name">{data.data?.menu_name}</h2>
            <div className="orderList_infoListBox">
              {data.detail ? (
                <p className="orderInfo_options">{data.data?.options}</p>
              ) : (
                <>
                  {data.data?.target_user_info ? (
                    <OrderInfoItem
                      name="수신 대상"
                      text={data.data?.target_user_info}
                    />
                  ) : (
                    ""
                  )}
                  <OrderInfoItem
                    name="총 결제금액"
                    text={`${comFormat(Math.floor(data.data?.total_price))}원`}
                  />
                  <OrderInfoItem name="수량" text={`${data.data?.qty}개`} />
                </>
              )}
            </div>
          </div>
        </div>
        {data.detail ? (
          <>
            <div className="orderDetailInfo_listBox">
              {data.data?.target_user_info ? (
                <OrderInfoItem
                  name="수신 대상"
                  text={data.data?.target_user_info}
                  detail={true}
                />
              ) : (
                ""
              )}
              <OrderInfoItem
                name="상품권 상태"
                text={data.data?.order_status}
                detail={true}
                gVal={data.data?.order_status == "사용가능" ? true : false}
                rVal={data.data?.order_status == "기간만료" ? true : false}
                boldVal={true}
              />
              <OrderInfoItem
                name="유효기간"
                text={setDateTime(data.data?.limit_date, "", "")}
                detail={true}
              />
            </div>
            <div className="orderDetailInfo_listBox">
              <OrderInfoItem
                name="주문번호"
                text={data.data?.order_number}
                detail={true}
              />
              <OrderInfoItem
                name="상품권번호"
                text={data.data?.giftCard_number}
                detail={true}
              />
              <OrderInfoItem
                name="가격"
                text={`${
                  data.data?.original_price
                    ? comFormat(Math.floor(data.data?.original_price))
                    : 0
                }원`}
                detail={true}
              />
              <OrderInfoItem
                name="할인가격"
                text={`${
                  data.data?.total_price
                    ? comFormat(Math.floor(data.data?.total_price))
                    : 0
                }원(${Math.floor(
                  ((data.data?.original_price - data.data?.total_price) /
                    data.data?.original_price) *
                    100
                )}%)`}
                detail={true}
              />
              <OrderInfoItem
                name="수량"
                text={`${data.data?.qty ? data.data?.qty : 0}개`}
                detail={true}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

function OrderList(data) {
  const navigate = useNavigate();

  return (
    <>
      {data?.data && data?.data?.length > 0 ? (
        <>
          {data?.data &&
            data?.data?.map((item, i) => (
              <button
                type="button"
                key={`orderList_${item?.id}_${i}`}
                onClick={() => navigate(`/mypage/orderDetail/${item?.id}`)}
                className="orderListItem"
              >
                <OrderInfo data={item} />
              </button>
            ))}
        </>
      ) : (
        <div className="notList">
          <img src="/assets/images/icon/not_list.svg" />
          구매내역이 없습니다
        </div>
      )}
    </>
  );
}
//아코디언 목록
function AccordionItem(data) {
  const [openChk, setOpenChk] = useState(false);

  return (
    <div className={`accordionBox ${openChk ? "active" : ""}`}>
      <button
        type="button"
        onClick={(e) => {
          slideToggle(e.target.nextElementSibling, "block");
          setOpenChk(!openChk);
        }}
        className="accordionTitle"
        style={{
          backgroundImage: `url('/assets/images/basic/${
            openChk ? "close_list.svg" : "open_list.svg"
          }')`,
        }}
      >
        <p
          onClick={(e) => {
            slideToggle(e.target.parentElement.nextElementSibling, "block");
            setOpenChk(!openChk);
          }}
        >
          {data.faq ? data.data.question : data.data.term_name}
        </p>
      </button>
      <div className="accordionText">
        <p>{data.faq ? data.data.answer : data.data.contents}</p>
      </div>
    </div>
  );
}
//문의 목록
function CsList(data) {
  const navigate = useNavigate();

  return (
    <>
      {data?.data && data?.data?.length > 0 ? (
        <>
          {data?.data &&
            data?.data?.map((item, i) => (
              <button
                type="button"
                key={`csList_${item?.id}_${i}`}
                onClick={() => navigate(`/cs/detail/${item?.id}`)}
                className="csListItem"
              >
                <p className="csList_type">{item?.contact_type}</p>
                <div className="csList_titleBox">
                  <p className="csList_title">{item?.title}</p>
                  <img src="/assets/images/basic/move_g.svg" />
                </div>
                <p
                  className={`csList_status ${
                    item?.status == 1 ? "gColor" : ""
                  }`}
                >
                  {item?.status == 1 ? "답변완료" : "답변대기"}
                </p>
              </button>
            ))}
        </>
      ) : (
        <div className="notList">
          <img src="/assets/images/icon/not_list.svg" />
          문의내역이 없습니다
        </div>
      )}
    </>
  );
}

export {
  StoreList,
  MenuList,
  MenuListItem,
  CalendarList,
  BasicList,
  CafeListItem,
  GiftListItem,
  OrderList,
  OrderInfo,
  OrderInfoItem,
  AccordionItem,
  CsList,
};
