import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {BtnBox} from "../../component/btns";
import OnboardingItem from "component/onboarding";
import { Swiper, SwiperSlide } from "swiper/react";

const Onboarding = () => {
    const navigate = useNavigate();
    const swiperRef = useRef(null);

    const [pageChk,setPageChk] =  useState(0);

    const PageNext = () => {
        let nowPage = pageChk;
        setPageChk(nowPage + 1);
        swiperRef.current.swiper.slideTo(nowPage + 1);
    }

    const PageStart = () => {
        localStorage.setItem("onboarding",1);
        if(localStorage.getItem("token")){
            navigate("/main");
        }else{
            navigate("/member/login");
        }
    }

    useEffect(() => {
        
    }, []);

    return (
        <div className="onboardingPage">
            <Swiper
                spaceBetween={20}
                slidesPerView={1}
                onSlideChange={(swiper) => setPageChk(swiper.activeIndex)}
                ref={swiperRef}
            >
                <SwiperSlide>
                    <OnboardingItem
                        title="요즘 뜨는 핫한 카페는 어디?"
                        text="최근 오픈한 카페부터 세상 힙한 유명카페까지 앨리펀에서 만나보세요!"
                        image="/assets/images/img/onboarding_0.png"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <OnboardingItem
                        title="최애카페를 모바일상품권으로 선물해보세요!"
                        text="앨리펀에서는 핫플레이스의 모바일 상품권을 선물할 수 있어요. 지금까지 없던 특별한 모바일상품권으로 마음을 전달하세요."
                        image="/assets/images/img/onboarding_1.png"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <OnboardingItem
                        title="3번 터치로 기프티콘 사용 끝!"
                        text="매장에 비치된 QR 코드 스캔으로 쉽게 결제할 수 있어요."
                        image="/assets/images/img/onboarding_2.png"
                    />
                </SwiperSlide>
            </Swiper>
            <BtnBox
                text={pageChk == 2 ? "시작하기" : "다음"}
                func={pageChk == 2 ? PageStart : PageNext}
                addClass="greenBtn"
            />
        </div>
    );
};

export default Onboarding;