import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "component/header";
import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Popup } from "component/popup";
import MypageListItem from "component/mypage_menuItem";

const SettingPage = (props) => {
    const navigate = useNavigate();
    
    const [popupData,setPopupData] =  useState(null);

    const alarmApi = useGet({
        url:`/mypage/alarm`,
        loginType:"login"
    });
    const alarmData = alarmApi?.data;

    const [pushAll,setPushAll] = useState(false);
    const [marketing,setMarketing] = useState(0);
    const [push,setPush] = useState(0);
    
    useEffect(()=>{
        setMarketing(alarmData?.is_marketing ? alarmData.is_marketing : 0);
        setPush(alarmData?.is_push ? alarmData.is_push : 0);

        setPushAll(alarmData?.is_marketing == 1 || alarmData?.is_push == 1 ? true : false);
    },[alarmData]);

    useEffect(()=>{
        if(marketing == 0 && push == 0){
            setPushAll(false);
        }
    },[marketing,push]);

    function alarmAllChk(e){
        if(e){
            setMarketing(1);
            setPush(1);

            alarmFunc("is_push",1);
            alarmFunc("is_marketing",1);
        }else{
            setMarketing(0);
            setPush(0);

            alarmFunc("is_push",0);
            alarmFunc("is_marketing",0);
        }
    }

    function alarmFunc(type,val){
        let formData = new FormData();
        formData.append("alarm_type",type);
        formData.append("value",val);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/mypage/alarm/modify",
            loginType:"login",
            success: (data) => {
                
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.alert,
                        text:data.data ? data.data : "",
                        btn0:"확인",
                        btn1:"",
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btnFunc1:(e)=>{}
                    });
                }
            }
        })
    }

    function logout(){
        localStorage.removeItem("sub_location_text");
        localStorage.removeItem("sub_location_id");
        localStorage.removeItem("main_location_id");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("user_id");

        props.logOut();

        navigate("/main");
    }
    
    return (
        <div className="myPage bottomBtnPage" style={{paddingBottom:"128px"}}>
            <Header
                pageBack = {true}
                func={() => navigate(-1)}
                headTitle="설정"
                centerTitle={true}
            />
            <div className="mypageSubSection">
                <div className="mypage_links">
                    <MypageListItem
                        name="알림 설정"
                        chkType={true}
                        id={"allPush"}
                        checkedType={pushAll}
                        func={()=>{}}
                        chkFunc={(e)=>{setPushAll(e);alarmAllChk(e)}}
                    />
                    {pushAll ? <div className="mypage_link_subItemBox">
                        <MypageListItem
                            name="서비스 알림"
                            chkType={true}
                            id={"push"}
                            checkedType={push == 1 ? true : false}
                            func={()=>{}}
                            chkFunc={(e)=>{setPush(e ? 1 : 0);alarmFunc("is_push",e ? 1 : 0)}}
                        />
                        <MypageListItem
                            name="마케팅 정보 설정"
                            chkType={true}
                            id={"marketing"}
                            checkedType={marketing == 1 ? true : false}
                            func={()=>{}}
                            chkFunc={(e)=>{setMarketing(e ? 1 : 0);alarmFunc("is_marketing",e ? 1 : 0)}}
                        />
                    </div> : ""}
                    <MypageListItem
                        name="로그아웃"
                        text=""
                        func={()=>{
                            setPopupData({
                                addClass:null,
                                title:"로그아웃 하시겠습니까?",
                                text:"",
                                btn0:"취소",
                                btn1:"로그아웃",
                                closeType:false,
                                closeFunc:(e) => {},
                                btnFunc0:(e)=>{},
                                btnFunc1:(e)=>{logout()}
                            });
                        }}
                    />
                </div>
            </div>
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default SettingPage;