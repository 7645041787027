import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "component/header";
import useGet from "api/useGet";
import { BtnBox } from "component/btns";
import { ProductItem, TextItem } from "component/orderItem";
import { Etc } from "component/etc";
import { comFormat, setDateTime } from "js/function";

const OrderCom = () => {
  const navigate = useNavigate();

  const param = useParams();
  const id = param.id;
  const menuId = param.menuId;
  const storeId = param.storeId;
  const orderType = param.type;

  const etcData = useGet({
    url: `/etc`,
    loginType: "login",
  });

  const menuApi = useGet({
    url: `/store/menu/detail?store_id=${storeId}&menu_id=${menuId}`,
    loginType: "login",
  });
  const menuData = menuApi.data?.menu;

  const storeApi = useGet({
    url: `/store/detail?id=${storeId}${
      localStorage.getItem("token")
        ? "&user_id=" + localStorage.getItem("user_id")
        : ""
    }`,
    loginType: "login",
  });
  const storeData = storeApi?.data?.store;

  const orderApi = useGet({
    url: `/mypage/order/detail?id=${id}`,
    loginType: "login",
  });
  const orderData = orderApi?.data?.order;

  const giftApi = useGet({
    url: `/gift/detail?id=${id}`,
    loginType: "login",
  });
  const giftData = giftApi?.data?.order;

  window.appBack = () => {
    navigate("/main");
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://t1.kakaocdn.net/kakao_js_sdk/2.1.0/kakao.min.js";
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  const shareKakao = () => {
    if (window.Kakao) {
      const kakao = window.Kakao;

      if (!kakao.isInitialized()) {
        kakao.init(process.env.REACT_APP_SHARE_KAKAO_LINK_KEY);
      }

      kakao.Share.sendDefault({
        objectType: "feed",
        content: {
          title: `${giftData?.gift_name} 님의 선물을 확인하세요!`,
          description:
            "소중한 마음이 담긴 편지와 선물을 지금 바로 확인해보세요.",
          imageUrl: giftData?.gift_image,
          link: {
            mobileWebUrl: `${process.env.REACT_APP_URL}/gift/${giftData?.coupon_numbers[0]?.coupon_number}`,
            webUrl: `${process.env.REACT_APP_URL}/gift/${giftData?.coupon_numbers[0]?.coupon_number}`,
          },
        },
        buttons: [
          {
            title: "선물 확인하기",
            link: {
              mobileWebUrl: `${process.env.REACT_APP_URL}/gift/${giftData?.coupon_numbers[0]?.coupon_number}`,
              webUrl: `${process.env.REACT_APP_URL}/gift/${giftData?.coupon_numbers[0]?.coupon_number}`,
            },
          },
        ],
      });
    }
  };

  return (
    <div className="orderPage bottomBtnPage">
      <Header
        pageClose={true}
        func={() => navigate("/main")}
        headTitle={"결제 완료"}
        centerTitle={true}
      />
      <div className="orderSection">
        <ProductItem
          storeId={storeData?.id}
          storeName={storeData?.store_name}
          options={orderData?.options}
          data={menuData}
          price={false}
        />
        <div className="orderInfoText_area">
          <TextItem
            infoName="가격"
            infoData={
              menuData?.sale_price > 0 || menuData?.platform_sale_price > 0 ? (
                <>
                  <span>{comFormat(Math.floor(Number(menuData?.price)))}</span>
                  {menuData?.price &&
                    comFormat(
                      Math.floor(
                        Number(menuData?.price) -
                          (menuData?.sale_price
                            ? Number(menuData?.sale_price)
                            : 0 + menuData?.platform_sale_price
                            ? Number(menuData?.platform_sale_price)
                            : 0)
                      )
                    )}
                </>
              ) : (
                menuData?.price &&
                comFormat(
                  Math.floor(
                    Number(menuData?.price) -
                      (menuData?.sale_price
                        ? Number(menuData?.sale_price)
                        : 0 + menuData?.platform_sale_price
                        ? Number(menuData?.platform_sale_price)
                        : 0)
                  )
                )
              )
            }
            infoUnit="원"
          />
          <TextItem infoName="수량" infoData={orderData?.qty} infoUnit="개" />
          <TextItem
            infoName="총 결제금액"
            infoData={comFormat(
              Math.floor(
                Number(orderData?.total_price) * Number(orderData?.qty)
              )
            )}
            infoUnit="원"
          />
          <TextItem
            infoName="결제 방식"
            infoData={
              orderData?.payment_method == "card"
                ? "신용/체크카드"
                : orderData?.payment_method == "naver"
                ? "네이버페이"
                : orderData?.payment_method == "kakao"
                ? "카카오페이"
                : "토스페이"
            }
            infoUnit=""
          />
          <TextItem
            infoName="주문일시"
            infoData={setDateTime(
              orderData?.purchase_at,
              "yymmddhhttss",
              "ymd"
            )}
            infoUnit=""
          />
          <TextItem
            infoName="유효기간"
            infoData={setDateTime(orderData?.limit_date, "yymmddhhttss", "ymd")}
            infoUnit=""
          />
        </div>
      </div>
      <div className="orderSection">
        <div className="order_etc">
          <Etc data={etcData?.data?.etc_infos} />
        </div>
      </div>
      <BtnBox
        text={`${orderType == 0 ? "상품권 사용" : "선물 대상선택"}`}
        btnAddClass={`${orderType == 0 ? "greenBtn" : ""}`}
        func={() => {
          if (orderType == 0) {
            navigate("/gift");
          } else {
            shareKakao();
          }
        }}
      />
    </div>
  );
};

export default OrderCom;
