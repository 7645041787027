import { Popup } from "component/popup";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const OrderFailed = () => {
    const navigate = useNavigate();
    const param = useParams();
    const failedType = param.failedType;

    const [popupData,setPopupData] =  useState(null);

    useEffect(() => {
        setPopupData({
            addClass:null,
            title:failedType,
            text:"",
            btn0:"확인",
            btn1:"",
            closeType:false,
            closeFunc:(e) => {},
            btnFunc0:(e)=>{
                navigate("/main");
            },
            btnFunc1:(e)=>{}
        });
    }, []);

    return (
        <Popup
            data={popupData}
        />
    );
};

export default OrderFailed;