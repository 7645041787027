import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "component/header";
import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { comFormat } from "js/function";
import { BtnBox } from "component/btns";
import { FileItemMultiple, InputItemBox, TestAreaItem } from "component/formItems";
import { Popup, SelPopup } from "component/popup";
import { ProductSelInput } from "component/productSel";

const CsWriting = () => {
    const navigate = useNavigate();

    const [btnChk,setBtnChk] = useState(true);

    const [selPopup,setSelPopup] =  useState(null);

    const [csType,setCsType] = useState("");
    const [csTitle,setCsTitle] = useState("");
    const [csText,setCsText] = useState("");

    const [giftId,setGiftId] = useState(null);
    const [bank,setBank] = useState("");
    const [depositor,setDepositor] = useState("");
    const [account,setAccount] = useState("");

    const [popupData,setPopupData] =  useState(null);

    let csFile = [];

    const productApi = useGet({
        url: giftId ? `/gift/detail?id=${giftId}` : "",
        loginType:"login"
    });
    const productData = giftId ? productApi?.data?.order : null;

    const cancelApi = useGet({
        url: `/gift?&is_contact=1&status=사용가능`,
        loginType:"login"
    });
    const cancelData = cancelApi?.data?.orders;

    const refundApi = useGet({
        url: `/gift?is_receive=1&is_contact=1&status=기간만료`,
        loginType:"login"
    });
    const refundData = refundApi?.data?.orders;

    const [writingChk,setWritingChk] =  useState(true);
    const writingStart = () => {
        if(writingChk){
            setWritingChk(false);
        
            let formData = new FormData();
            formData.append("contact_type", csType);
            formData.append("title", csTitle);
            formData.append("contents", csText);

            csFile.forEach(function(item,i){
                formData.append("image[]", item);
            });

            if(csType == "결제 취소" || csType == "상품권 환불"){
                formData.append("order_id", giftId);
                formData.append("refund_bank_name", bank);
                formData.append("refund_user_name", depositor);
                formData.append("refund_bank_account", account);
            }

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: "/mypage/contact/register",
                loginType:"login",
                success: (data) => {
                    setPopupData({
                        addClass:null,
                        title:"문의가 접수되었습니다.",
                        text:"접수된 문의는 순차적으로 답변드리고 있으며,<br/>취소/환불의 경우 3-5 영업일이 소요될 수 있습니다.",
                        btn0:"확인",
                        btn1:"",
                        closeType:false,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{navigate(-1)},
                        btnFunc1:(e)=>{}
                    });
                    setTimeout(() => {
                        setWritingChk(true);
                    }, 500);
                },
                err: (data) => {
                    setTimeout(() => {
                        setWritingChk(true);
                    }, 500);
                    if(data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert,
                            text:data.data ? data.data : "",
                            btn0:"확인",
                            btn1:"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btnFunc1:(e)=>{}
                        });
                    }
                }
            });
        }
    }

    useEffect(() => {
        if(csType == "결제 취소" || csType == "상품권 환불"){
            if(csType !== "" && csTitle !== "" && csText !== "" && giftId && bank !== ""  && depositor !== ""  && account !== ""){
                setBtnChk(false);
            }else{
                setBtnChk(true);
            }
        }else if(csType !== "" && csTitle !== "" && csText !== ""){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [csType,csTitle,csText,giftId,bank,depositor,account]);

    return (
        <div className="csPage">
            <Header
                pageBack = {true}
                func={() => navigate(-1)}
                headTitle="1:1 문의 작성"
                centerTitle={true}
            />
            <div className={`csWriting_section ${(csType == "결제 취소" || csType == "상품권 환불") ? "addBtns" : ""}`}>
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName="문의유형 선택"
                    placeholder="선택하세요"
                    value={csType}
                    readOnly={true}
                    func={(e) => {}}
                    clickEv={(e) => setSelPopup({
                        title:"문의유형 선택",
                        addClass:null,
                        closeType:true,
                        items:["일반 문의","버그 신고","기타","결제 취소","상품권 환불"],
                        val:["일반 문의","버그 신고","기타","결제 취소","상품권 환불"],
                        sel:csType,
                        closeFunc:(e) => {},
                        func:(e) => {
                            setCsType(e.text);
                            setGiftId(null);
                        }
                    })}
                />
                {(csType == "결제 취소" || csType == "상품권 환불") &&
                    <ProductSelInput
                        inputName="상품권 선택"
                        placeholder="선택하세요"
                        value={giftId}
                        selItemData={productData}
                        productList={csType == "결제 취소" ? cancelData : refundData}
                        func={(e) => {setGiftId(e)}}
                    />
                }
                <InputItemBox
                    type="text"
                    addClass="type2"
                    inputName="제목"
                    placeholder="제목을 입력하세요"
                    max={20}
                    value={csTitle}
                    func={(e) => {setCsTitle(e)}}
                />
                <TestAreaItem
                    addClass="type2"
                    inputName="내용"
                    placeholder="내용을 입력하세요"
                    max={500}
                    maxChk={true}
                    value={csText}
                    func={(e)=>setCsText(e)}
                />
                <FileItemMultiple
                    inputName="이미지 첨부"
                    id="csImages"
                    max={10}
                    maxSise={null}
                    func={(e)=>{
                        if(e){
                            csFile = [...e];
                        }
                    }}
                />
                {(csType == "결제 취소" || csType == "상품권 환불") && 
                <>
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName="은행 선택"
                    placeholder="선택하세요"
                    value={bank}
                    readOnly={true}
                    func={(e) => {}}
                    clickEv={(e) => setSelPopup({
                        title:"은행 선택",
                        addClass:null,
                        closeType:true,
                        items:["산업은행","기업은행","국민은행","수협","농협","지역농축협","우리은행","SC제일은행","한국씨티은행","대구은행","부산은행","광주은행","제주은행","전북은행","경남은행","새마을금고","신협","저축은행","HSBC은행","도이치은행","JP모간체이스은행","BOA은행","BNP파리바은행","중국공상은행","산림조합","중국건설은행","우체국","하나은행","신한은행","케이뱅크","카카오뱅크","유안타증권","KB증권","KTB투자증권","미래에셋대우","삼성증권","한국투자증권","NH투자증권","교보증권","하이투자증권","현대차증권","키움증권","이베스트투자증권","SK증권","대신증권","한화투자증권","하나금융투자","신한금융투자","DB금융투자","유진투자증권","메리츠종합금융증권","카카오페이증권","부국증권","신영증권","케이프투자증권","펀드온라인코리아"],
                        val:["산업은행","기업은행","국민은행","수협","농협","지역농축협","우리은행","SC제일은행","한국씨티은행","대구은행","부산은행","광주은행","제주은행","전북은행","경남은행","새마을금고","신협","저축은행","HSBC은행","도이치은행","JP모간체이스은행","BOA은행","BNP파리바은행","중국공상은행","산림조합","중국건설은행","우체국","하나은행","신한은행","케이뱅크","카카오뱅크","유안타증권","KB증권","KTB투자증권","미래에셋대우","삼성증권","한국투자증권","NH투자증권","교보증권","하이투자증권","현대차증권","키움증권","이베스트투자증권","SK증권","대신증권","한화투자증권","하나금융투자","신한금융투자","DB금융투자","유진투자증권","메리츠종합금융증권","카카오페이증권","부국증권","신영증권","케이프투자증권","펀드온라인코리아"],
                        sel:bank,
                        closeFunc:(e) => {},
                        func:(e) => {
                            setBank(e.text);
                        }
                    })}
                />
                <InputItemBox
                    type="text"
                    addClass="type2"
                    inputName="예금주"
                    placeholder="예금주를 입력하세요"
                    max={null}
                    value={depositor}
                    func={(e) => {setDepositor(e)}}
                />
                <InputItemBox
                    type="number"
                    addClass="type2"
                    inputName="환불 받을 계좌번호"
                    placeholder="‘-’빼고 입력하세요"
                    max={null}
                    value={account}
                    func={(e) => {setAccount(e)}}
                />
                </>}
            </div>
            {(csType == "결제 취소" || csType == "상품권 환불") ? <div className="detail_totalPrice addBtn_div">
                <p className="detail_totalPrice_name">총 환불금액</p>
                <p className="detail_totalPrice_text">{`${
                    productData?.total_price ? 
                    (productData?.gift_type == "receive" || productData?.order_status == "기간만료/환불") ? 
                    comFormat(Math.floor(Number(productData?.total_price) * 0.9)) : comFormat(Math.floor(productData?.total_price)) : 0}원`}</p>
            </div> : ""}
            <BtnBox
                text="작성완료"
                disabled={btnChk}
                func={()=>{writingStart()}}
            />

            <SelPopup
                data={selPopup}
            />
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default CsWriting;