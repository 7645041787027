import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "component/header";
import useGet from "api/useGet";
import MypageListItem from "component/mypage_menuItem";
import { PhoneSetting } from "js/function";
import { Footer, FooterManu } from "component/footer";

const MyPage = () => {
    const navigate = useNavigate();
    const footerRef = useRef(null);
    const [pageSize,setPageSize] = useState(0);

    const myApi = useGet({
        url:`/mypage/info`,
        loginType:"login"
    });
    const myData = myApi?.data;

    useEffect(()=>{
        setPageSize(`calc(100vh - 54px - 66px - ${footerRef?.current?.clientHeight ? footerRef?.current?.clientHeight : 0}px - 40px)`)
    },[footerRef]);
    
    return (
        <div className="myPage bottomBtnPage">
            <Header
                setting={true}
            />
            <div className="mypageSection" style={{minHeight:pageSize}}>
                <div className="mypageMain_info_area">
                    <div className="mypageMain_infoBox">
                        <h1 className="mypageMain_name">{myData?.nickname}</h1>
                        <p className="mypageMain_phone">{PhoneSetting(String(myData?.phone))}</p>
                    </div>
                    <button type="button" className="editLink" onClick={()=>navigate("/mypage/edit")}>회원정보 수정<img src="/assets/images/basic/move_g.svg"/></button>
                </div>
                <div className="mypageMain_menuArea">
                    <MypageListItem
                        name="선물함"
                        text={`${myData?.gift_count}개`}
                        func={()=>navigate("/gift")}
                    />
                    <div className="mypageMain_menuBox">
                        <button type="button" onClick={()=>{navigate("/mypage/like")}} className="mypageMain_menuItem">
                            <img src="/assets/images/icon/mypage_menu_0.svg"/>
                            나의 관심매장
                        </button>
                        <button type="button" onClick={()=>{navigate("/mypage/order")}} className="mypageMain_menuItem">
                            <img src="/assets/images/icon/mypage_menu_1.svg"/>
                            구매내역
                        </button>
                        <button type="button" onClick={()=>{navigate("/cs")}} className="mypageMain_menuItem">
                            <img src="/assets/images/icon/mypage_menu_2.svg"/>
                            고객센터
                        </button>
                    </div>
                </div>
            </div>
            <FooterManu menuChk="4"/>
            <Footer footerRef={footerRef}/>
        </div>
    );
};

export default MyPage;