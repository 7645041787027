import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { giftModalState } from "recoil/atoms/modal";
import styled from "styled-components";

function GiftFixedButtons({
  id,
  status,
  type,
  cardNumber,
  giftImage,
  giftMsg,
  giftName,
}) {
  const navigate = useNavigate();
  const setGiftModal = useSetRecoilState(giftModalState);

  const handleUse = () => {
    return navigate(`/gift/qr/${id}`);
  };

  const handleViewGiftCard = () => {
    setGiftModal({
      giftImage,
      giftMsg,
      giftName,
    });
  };

  return (
    <Wrapper className="pageBtn_box">
      <p className={`pageBtn textType`}>
        상품권 번호<span>{cardNumber}</span>
      </p>
      <div className="buttons-wrapper">
        {(type === "receive" || type === "send") && (
          <button
            type="button"
            className={`pageBtn greenBtn`}
            onClick={handleViewGiftCard}
          >
            선물카드 보기
          </button>
        )}

        {status === "사용가능" && (
          <button
            type="button"
            className={`pageBtn greenBtn`}
            dangerouslySetInnerHTML={{
              __html: `<img src="/assets/images/basic/qr_icon.svg" class="qrIcon"/>상품권 사용`,
            }}
            onClick={handleUse}
          />
        )}

        {/* {status !== "사용가능" && type === } */}

        {/* <button
          type="button"
          className={`pageBtn greenBtn`}
          dangerouslySetInnerHTML={{ __html: text }}
          onClick={handleUse}
        /> */}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .buttons-wrapper {
    margin-top: 10px;
    display: flex;
    gap: 10px;

    & > button {
      margin: 0;
    }
  }
`;

export default GiftFixedButtons;
