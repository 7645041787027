//tap item
function Tap(data){
    return (
        <div className={`tapBox ${data.addClass ? data.addClass : ""}`}>
            {data.data?.map((item, i) => (
                <button type="button" key={item.id} onClick={() => data.func(item.id)} className={`tapItem ${item.id == data.val ? "active" : ""}`}>{item.name}</button>
            ))}
        </div>
    );
}

//TypeTap item
function TypeTap(data){
    return (
        <div className={`order_type_item ${data.addClass ? data.addClass : ""}`}>
            <input type="radio" name={data.name} value={data.val} checked={data.checked} onChange={() => data.func(data.val)} className="order_type" id={data.id}/>
            <label htmlFor={data.id}>
                {data.text}
            </label>
        </div>
    );
}

export {Tap, TypeTap}