import { useEffect, useState } from "react";
//우측 슬라이드 팝업
function RightPopup(data){
    return (
        <div className={`rightPopup ${data.addClass ? data.addClass : ""} ${data.bType ? "bPopup" : ""}`}>
            <div className="rightPopup_header_section">
                {data.headerTitle ? <h1 className="rightPopup_header_title">{data.headerTitle}</h1> : ""}
                <button type="button" className="btn_back rightPopupClose" onClick={(e) => data.closePopup(e)}><img src={`/assets/images/basic/${data.bType ? "close_w.svg" : "close.svg"}`} alt="back_icon"/></button>
            </div>
            <div className="rightPopup_contents" onScroll={()=>{if(data.scrollEv){data.scrollEv()}}}>
                {data.title ? <div className="rightPopup_title_area">
                    <h1 className="rightPopup_title" dangerouslySetInnerHTML={{__html:data.title}}></h1>
                </div> : ""}
                {data.text ? <div className="terms_text yScroll" dangerouslySetInnerHTML={{__html:data.text}}></div> : ""}
                {data.children && data.children}
            </div>
        </div>
    );
}
//로딩
function LoadingBox(data){
    return (
        <div className={`loadingBox ${data.addClass ? data.addClass : ""}`}>
            <div id="loading" className="loading"></div>
        </div>
    );
}
//하단 메세지 문구
let timeOut = "";
function BottomErrMsg(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");

    useEffect(()=>{
        if(data.text && data.text !== ""){
            clearTimeout(timeOut);

            setPopupData(data.text);
            
            setPopupOpen("block");
            setTimeout(() => {
                setPopupClass("active");
            }, 100);

            timeOut = setTimeout(() => {
                setPopupClass("");
                setTimeout(() => {
                    setPopupOpen("none");
                }, 200);
            }, 2000);
        }
    },[data.chk]);

    return (
        <p className={`bottomErrMsg ${popupClass ? popupClass : ""} ${data.addClass ? data.addClass : ""}`} style={{display:popupOpen}} dangerouslySetInnerHTML={{__html:popupData}}></p>
    );
}
//기본팝업
function Popup(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");
    
    useEffect(()=>{
        setPopupData(data.data)
    },[data.data]);

    useEffect(()=>{
        if(popupData){
            setTimeout(() => {
                setPopupOpen("block");
                setTimeout(() => {
                    setPopupClass("active");
                }, 100);
            }, 200);
        }else{
            setPopupClass("");
            setTimeout(() => {
                setPopupOpen("none");
            }, 200);
        }
    },[popupData]);

    return (
        <div className={`popup ${popupClass ? popupClass : ""} ${popupData?.addClass ? popupData?.addClass : ""}`} style={{display:popupOpen}}>
            <div className="popup_cover" onClick={(e) => {popupData?.closeType && setPopupData(null);popupData?.closeType && popupData?.closeFunc(e)}}></div>
            <div className="popupSetion">
                <div className="popupTextBox">
                    <h2 className="popupTitle" dangerouslySetInnerHTML={{__html:popupData?.title}}></h2>
                    <p className="popupText" dangerouslySetInnerHTML={{__html:popupData?.text}}></p>
                </div>
                <div className={`popup_btnBox ${popupData?.btn1 ? "col2" : ""}`}>
                    <button type="button" className="btn_popup btn_popupLeft" onClick={(e) => {setPopupData(null);popupData?.btnFunc0(e)}}>{popupData?.btn0}</button>
                    {popupData?.btn1 ? <button type="button" className="btn_popup btn_popupRight" onClick={(e) => {setPopupData(null);popupData?.btnFunc1(e)}}>{popupData?.btn1}</button> : ""}
                </div>
            </div>
        </div>
    );
}
//선택팝업
function SelPopup(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");
    
    useEffect(()=>{
        setPopupData(data.data)
    },[data.data]);

    useEffect(()=>{
        if(popupData){
            setTimeout(() => {
                setPopupOpen("block");
                setTimeout(() => {
                    setPopupClass("active");
                }, 100);
            }, 200);
        }else{
            setPopupClass("");
            setTimeout(() => {
                setPopupOpen("none");
            }, 200);
        }
    },[popupData]);

    return (
        <div className={`selPopup ${popupClass ? popupClass : ""} ${popupData?.addClass ? popupData?.addClass : ""}`} style={{display:popupOpen}}>
            <div className="popup_cover" onClick={(e) => {popupData?.closeType && setPopupData(null);popupData?.closeType && popupData?.closeFunc(e)}}></div>
            <div className="selPopupSetion">
                {popupData?.title ? 
                <div className="selPopupTextBox">
                    <h2 className="popupTitle" dangerouslySetInnerHTML={{__html:popupData?.title}}></h2>
                </div> : ""
                }
                {data.children}
                <div className="selPopup_selBox yScroll">
                    {popupData?.dataKey ? 
                        popupData?.data?.length > 0 ?
                        popupData?.data.map((item, i) => (
                                <button type="button" key={i} className={`selPopup_sel ${item[popupData?.dataKey] == popupData?.sel ? "active" : ""}`} onClick={(e) => {setPopupData(null);popupData?.func({val:item.id, text:item[popupData?.dataKey]})}}>{item[popupData?.dataKey]}</button>
                            ))
                            : ""
                        : 
                        popupData?.items?.map((item, i) => (
                            <button type="button" key={i} className={`selPopup_sel ${item == popupData?.sel ? "active" : ""}`} onClick={(e) => {setPopupData(null);popupData?.func({val:popupData?.val[i], text:item})}}>{item}</button>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export {Popup, SelPopup, LoadingBox, RightPopup, BottomErrMsg}