import { PhoneSetting, comFormat } from "js/function";
import { useNavigate } from "react-router-dom";
import CountInput from "./count";
import { BtnBox } from "./btns";
import { useState } from "react";
import { useEffect } from "react";
//상품정보
function ProductItem(data){
    const navigate = useNavigate();
    
    return (
        <div className="orderProductItem">
            <button type="button" onClick={()=>{navigate(`/main/detail/${data?.storeId}/0`)}} className="orderProductItem_store"><img src="/assets/images/basic/location.svg"/><span>{data?.storeName}</span></button>
            <div className="orderProductItem_box">
                <div className="orderProductItem_img" style={{backgroundImage:`url('${data.data?.menu_image}')`}}></div>
                <div className="orderProductItem_info">
                    <h1 className="orderProductItem_name">{data?.data?.menu_name}</h1>
                    {data?.options ? <p className="orderProductItem_option">{data?.options}</p> : ""}
                    {data?.price ? <div className="orderProductItem_priceBox">
                        <div className="orderProductItem_priceItem">
                            {data.data?.sale_price > 0 || data.data?.platform_sale_price > 0? <p className="orderProductItem_basicPrice">{data.data?.price && comFormat(Math.floor(data.data?.price))} 원</p> : ""}
                            <div className="orderProductItem_priceArea">
                                {data.data?.sale_price > 0 || data.data?.platform_sale_price > 0 ? <p className="orderProductItem_sale_val">{Math.floor((data.data?.sale_price ? Number(data.data?.sale_price) : 0 + data.data?.platform_sale_price ? Number(data.data?.platform_sale_price) : 0) / Number(data.data?.price) * 100)}%</p> : ""}
                                <h2 className="orderProductItem_prict">{data.data?.price && comFormat(Math.floor(Number(data.data?.price) - (Number(data.data?.sale_price) + Number(data.data?.platform_sale_price))))} 원</h2>
                            </div>
                        </div>
                        <CountInput
                            val={data.countVal}
                            qty={data.data?.qty}
                            func={data.countFunc}
                        />
                    </div> : ""}
                </div>
            </div>
        </div>
    );
}
//주문정보 아이템
function TextItem(data){
    return (
        <div className="order_infoItem">
            <p className="order_infoName">{data.infoName}</p>
            <p className="order_infoText">{data.infoData} {data.infoUnit}</p>
        </div>
    );
}
//연락처 아이템
function PhoneShItem(data){
    return (
        <div className="phoneShItem">
            <input type="radio" name="phoneShItem" value={data.val} checked={data.checked} onChange={() => data.func(data.val)} className="phoneShItem" id={data.id}/>
            <label htmlFor={data.id}>
                <p className="phoneShItemName">{data.name}</p>
                <p className="phoneShItemNumber">{PhoneSetting(data.phone)}</p>
            </label>
        </div>
    );
}
//연락처 팝업
function PhoneShPopup(data){
    const [btnChkItem,setBtnChkItem] = useState(true);
    const [phoneItem,setPhoneItem] = useState("");
    const [nameItem,setNameItem] = useState("");

    useEffect(() => {
        setPhoneItem("")
        setNameItem("")
    }, [data.openChk]);

    useEffect(() => {
        if(phoneItem !== "" && phoneItem){
            setBtnChkItem(false);
        }else{
            setBtnChkItem(true);
        }
    }, [phoneItem]);

    return (
        <div className="phoneShItem_section">
            {data.data && data.data.length > 0 ? 
                data.data.map((item,i)=>(
                    <PhoneShItem
                        key={`${i}_${item.phone}`}
                        id={`phoneShItem_${i}_${item.phone}`}
                        val={item.phone}
                        func={()=>{setPhoneItem(item.phone);setNameItem(item.name)}}
                        name={item.name}
                        phone={item.phone}
                    />
                ))
            :
            <div className="notList">
                <img src="/assets/images/icon/not_list.svg"/>
                저장된 연락처가 없거나,<br/>연락처를 불러오지 못했습니다.
            </div>}
            <BtnBox
                text={"선택"}
                disabled={btnChkItem}
                func={()=>{data.selFunc(nameItem,phoneItem)}}
            />
        </div>
    );
}

export {TextItem, ProductItem, PhoneShItem, PhoneShPopup}