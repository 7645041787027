import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "component/header";
import DetailBox from "component/detail";
import useGet from "api/useGet";
import { BtnBoxV2 } from "component/btns";
import {Etc} from "component/etc";
import { OptionBox } from "component/menuOption";
import { sharing } from "js/function";

const MenuDetail = () => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;
    const storeId = param.storeId;

    const [count, setCount] = useState(1);

    const fixedTitleRef = useRef(null);
    const [fixedTitle, setFixedTitle] = useState(false);

    const deatilData = useGet({
        url:`/store/menu/detail?store_id=${storeId}&menu_id=${id}`,
        loginType:"login"
    });
    const menuData = deatilData.data?.menu;

    const [requireArr, setRequireArr] = useState([]);
    const [selectArr, setSelectArr] = useState([]);

    const [requireSelArr, setRequireSelArr] = useState([]);
    const [selectSelArr, setSelectSelArr] = useState([]);

    const [btnChk, setBtnChk] = useState(false);

    const etcData = useGet({
        url:`/etc`,
        loginType:"login"
    });

    function handleScroll(){
        if(window.scrollY + 54 > fixedTitleRef?.current?.offsetTop){
            setFixedTitle(true);
        }else{
            setFixedTitle(false);
        }
    };

    useEffect(() => {
        let requireArrKey = [],
            selectArrKey = [];
        menuData?.require_options.forEach((item,i) => {
            if(i == 0){
                requireArrKey.push({id:item.group_id, name:item.group_name});
            }else if(requireArrKey.findIndex(val => val.name == item.group_name) == -1){
                requireArrKey.push({id:item.group_id, name:item.group_name});
            }
            
            if(menuData.require_options.length - 1 == i){
                setRequireArr(requireArrKey);
            }
        });

        menuData?.select_options.forEach((item,i) => {
            if(i == 0){
                selectArrKey.push({id:item.group_id, name:item.group_name});
            }else if(selectArrKey.findIndex(val => val.name == item.group_name) == -1){
                selectArrKey.push({id:item.group_id, name:item.group_name});
            }
            
            if(menuData.select_options.length - 1 == i){
                setSelectArr(selectArrKey);
            }
        });

        if(menuData?.require_options?.length == 0){
            setBtnChk(false);
        }else if(requireSelArr.length == 0){
            setBtnChk(true);
        }
    }, [menuData]);

    useEffect(() => {
        if(requireArr.length == requireSelArr.length){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [requireSelArr]);

    useEffect(() => {
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="detailPage bottomBtnPage">
            <Header
                pageBack = {true}
                func={() => {navigate(-1)}}
                headTitle={
                    fixedTitle ? 
                    deatilData.data?.menu?.menu_name ? deatilData.data?.menu?.menu_name : "" 
                    : ""
                }
                centerTitle={true}
                sharing = {true}
                sharingFunc={() => {sharing(window.location.href)}}
            />
            <DetailBox 
                data={deatilData.data?.menu}
                fixedTitle={fixedTitleRef}
                countFunc={(e) => {setCount(e)}}
            />
            
            <div className="productInfo_section">
                {requireArr.length > 0 &&
                    requireArr.map((item,i)=>(
                        <OptionBox
                            id={item.id}
                            title={item.name}
                            essential={true}
                            data={menuData?.require_options?.filter((optionArr)=>{return optionArr.group_id == item.id})}
                            key={item.id}
                            func={(e,selId,gId)=>{
                                if (e.target.checked) {
                                    setRequireSelArr(requireSelArr.filter((el) => el.gId !== gId));
                                    setRequireSelArr(prev => [...prev, {gId:gId,selId:selId}]);
                                } else {
                                    setRequireSelArr(requireSelArr.filter((el) => el.selId !== selId));
                                }
                            }}
                        />
                    ))
                }
                {selectArr.length > 0 &&
                    selectArr.map((item,i)=>(
                        <OptionBox
                            id={item.id}
                            title={item.name}
                            essential={false}
                            data={menuData?.select_options?.filter((optionArr)=>{return optionArr.group_id == item.id})}
                            key={item.id}
                            func={(e,selId)=>{
                                if (e.target.checked) {
                                    setSelectSelArr(prev => [...prev, selId]);
                                } else {
                                    setSelectSelArr(selectSelArr.filter((el) => el !== selId));
                                }
                            }}
                        />
                    ))
                }
            </div>
            
            <div className="productInfo_section">
                <Etc
                    data={etcData?.data?.etc_infos}
                />
            </div>
            <BtnBoxV2
                addClass="col2"
                leftAddClass="greenBtn"
                leftText={`<img src="/assets/images/icon/btn_gift.svg" style="margin:1px 3px 0 0"/>선물하기`}
                rightText="나에게 선물하기"
                disabled={btnChk}
                leftFunc={()=>{
                    if(requireSelArr.length > 0){
                        let requireSelArrIdSet = [];
                        requireSelArr.forEach(function(item,i){
                            requireSelArrIdSet.push(item.selId);
                            if(requireSelArr.length - 1 == i){
                                navigate(`/order/0/${storeId}/${id}/${count}`,{state: {requireArr: requireSelArrIdSet, selectArr: selectSelArr}})
                            }
                        });
                    }else{
                        navigate(`/order/0/${storeId}/${id}/${count}`,{state: {requireArr: requireSelArr, selectArr: selectSelArr}})
                    }
                }}
                rightFunc={()=>{
                    if(requireSelArr.length > 0){
                        let requireSelArrIdSet = [];
                        requireSelArr.forEach(function(item,i){
                            requireSelArrIdSet.push(item.selId);
                            if(requireSelArr.length - 1 == i){
                                navigate(`/order/1/${storeId}/${id}/${count}`,{state: {requireArr: requireSelArrIdSet, selectArr: selectSelArr}})
                            }
                        });
                    }else{
                        navigate(`/order/1/${storeId}/${id}/${count}`,{state: {requireArr: requireSelArr, selectArr: selectSelArr}})
                    }
                }}
            />
        </div>
    );
};

export default MenuDetail;