import { Popup } from "component/popup";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Private = () => {
    const navigate = useNavigate();

    const [popupData,setPopupData] =  useState(null);

    useEffect(() => {
        if(localStorage.getItem("token")){
            navigate(0);
        }else{
            setPopupData({
                addClass:null,
                title:"로그인이 필요한 페이지 입니다.",
                text:"",
                btn0:"취소",
                btn1:"로그인",
                closeType:false,
                closeFunc:(e) => {},
                btnFunc0:(e)=>{
                    navigate(-1);
                },
                btnFunc1:(e)=>{
                    let nowUrl = new URL(window.location.href);
                    sessionStorage.setItem("setUrl",nowUrl.pathname);
                    navigate("/member/login");
                }
            });
        }
    }, []);

    return (
        <Popup
            data={popupData}
        />
    );
};

export default Private;