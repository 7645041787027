import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as fatchSet from "../../api/api";
import { Popup } from "component/popup";
import Html5QrcodePlugin from "component/Html5QrcodePlugin";

const GiftQrPage = () => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;
    
    const [popupData,setPopupData] =  useState(null);

    let scanChk = 0;

    function qrSend(code){
        let formData = new FormData();
        formData.append("order_id", id);
        formData.append("qr_code", code);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/gift/use",
            loginType:"login",
            success: (data) => {
                setPopupData({
                    addClass:null,
                    title:"상품권 사용 완료!",
                    text:"상품권 사용이 완료되었습니다.<br/>매장 직원분에게 사용 완료된 상품권을 보여주세요.",
                    btn0:"확인",
                    btn1:"",
                    closeType:false,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{
                        navigate(-1);
                    },
                    btnFunc1:(e)=>{}
                });
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.alert,
                        text:data.data ? data.data : "",
                        btn0:"확인",
                        btn1:"",
                        closeType:true,
                        closeFunc:(e) => {scanChk = 0},
                        btnFunc0:(e)=>{scanChk = 0},
                        btnFunc1:(e)=>{}
                    });
                }
            }
        })
    }

    const onNewScanResult = (decodedText, decodedResult) => {
        if(scanChk == 0){
            scanChk = 1;
            qrSend(decodedText);
        }
    };

    useEffect(() => {
        var uaSet = window.navigator.userAgent;
        if (uaSet.toLocaleLowerCase().indexOf("kakaotalk") > -1 && !(/iPad|iPhone|iPod/.test(uaSet))) {
            window.location.href = "kakaotalk://inappbrowser/close";
            window.location.href='intent://'+window.location.href.replace(/https?:\/\//i,'')+'#Intent;scheme=http;end';
        }

        if(uaSet.match('golmogtikon/iOS') != null){
            window.webkit.messageHandlers.cameraHandler.postMessage({});
        }
    }, []);

    return (
        <div className="giftListPage bottomBtnPage">
            <div className="giftQr">
                <div className="qrCover">
                    <p className="t"></p> 
                    <p className="l"></p>
                    <p className="c"> 
                        <span className="bgIcons lt" style={{minWidth:"42px",width:"42px",height:"42px",backgroundImage:"url(/assets/images/icon/scan_0.svg)"}}></span>
                        <span className="bgIcons rt" style={{minWidth:"42px",width:"42px",height:"42px",backgroundImage:"url(/assets/images/icon/scan_1.svg)"}}></span>
                        <span className="bgIcons lb" style={{minWidth:"42px",width:"42px",height:"42px",backgroundImage:"url(/assets/images/icon/scan_2.svg)"}}></span>
                        <span className="bgIcons rb" style={{minWidth:"42px",width:"42px",height:"42px",backgroundImage:"url(/assets/images/icon/scan_3.svg)"}}></span>
                    </p> 
                    <p className="r"></p> 
                    <p className="b"></p>  
                </div>
                <div className="qrBox">
                    <Html5QrcodePlugin
                        qrCodeSuccessCallback={onNewScanResult}
                        errPopup={()=>{
                            setPopupData({
                                addClass:null,
                                title:"지원되는 카메라가 없습니다.",
                                text:"현재 지원되는 카메라가 없거나, 일시적인 문제로 카메라를 불러올 수 없습니다.",
                                btn0:"확인",
                                btn1:"",
                                closeType:false,
                                closeFunc:(e) => {},
                                btnFunc0:(e)=>{navigate(-1)},
                                btnFunc1:(e)=>{}
                            })
                        }}
                    />
                </div>
            </div>
            <div className="qrBtnBox">
                <p className="qrBtnText">매장 내 앨리펀 QR 코드를 스캔하세요</p>
                <button type="button" onClick={()=>{navigate(-1)}} className="pageBtn wType">닫기</button>
            </div>
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default GiftQrPage;