import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "component/header";
import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { comFormat } from "js/function";
import { BtnBox } from "component/btns";
import { ChkBox, InputItemBox, TestAreaItem } from "component/formItems";
import { Popup, RightPopup, SelPopup } from "component/popup";
import { TextItem } from "component/orderItem";
import termsData from "../../data/terms.json";

const CancelPage = () => {
    const navigate = useNavigate();
    const param = useParams();
    const giftId = param.giftId;
    const type = param.type;

    const [btnChk,setBtnChk] = useState(true);

    const [selPopup,setSelPopup] =  useState(null);

    const [bank,setBank] = useState("");
    const [depositor,setDepositor] = useState("");
    const [account,setAccount] = useState("");

    const [chkData,setChkData] = useState(false);
    
    const [popupData,setPopupData] =  useState(null);

    const [totalPrict,setTotalPrice] =  useState(1);

    const [terms,setTerms] =  useState("");
    const chkDataAll = termsData?.data;

    const productApi = useGet({
        url: giftId ? `/gift/detail?id=${giftId}` : "",
        loginType:"login"
    });
    const productData = giftId ? productApi?.data?.order : null;


    const [writingChk,setWritingChk] =  useState(true);
    const writing = () => {
        if(writingChk){
            setWritingChk(false);    
            let formData = new FormData();
            formData.append("contact_type", type);
            formData.append("order_id", giftId);
            formData.append("refund_bank_name", bank);
            formData.append("refund_user_name", depositor);
            formData.append("refund_bank_account", account);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: "/mypage/contact/refund",
                loginType:"login",
                success: (data) => {
                    setPopupData({
                        addClass:null,
                        title:"취소/환불 접수 완료",
                        text:"상품권 취소/환불 접수가 완료되었습니다.<br/>취소/환불의 경우 3~5 영업일이 소요될 수 있습니다.",
                        btn0:"확인",
                        btn1:"",
                        closeType:false,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{navigate(-1)},
                        btnFunc1:(e)=>{}
                    });
                    setWritingChk(true);
                },
                err: (data) => {
                    if(data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert,
                            text:data.data ? data.data : "",
                            btn0:"확인",
                            btn1:"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btnFunc1:(e)=>{}
                        });
                    }
                    setWritingChk(true);
                }
            });
        }
    }

    const writingStart = () => {
        let text = productData?.order_status == "사용가능" && (productData?.gift_type == "mySend" || productData?.gift_type == "send") ? 
        `구매한 상품권을 취소/환불할 경우 상품권 구매금액의 100%가 환불됩니다.`
        : `선물받은 상품권을 취소/환불할 경우<br/>상품권 구매금액의 90%가 환불됩니다.`;
        let is_admin = productData?.is_admin;

        if(is_admin == 1){
            setPopupData({
                addClass:null,
                title:"취소/환불이 불가능한 상품권 입니다.",
                text:"",
                btn0:"확인",
                btn1:"",
                closeType:true,
                closeFunc:(e) => {},
                btnFunc0:(e)=>{},
                btnFunc1:(e)=>{}
            });
        }else{
            setPopupData({
                addClass:null,
                title:"정말로 선물을 취소/환불하시겠습니까?",
                text:text,
                btn0:"취소",
                btn1:"확인",
                closeType:true,
                closeFunc:(e) => {},
                btnFunc0:(e)=>{},
                btnFunc1:(e)=>{writing()}
            });
        }
    }

    const rightOpen = (id) => {    
        setTerms({
            title:chkDataAll[Number(id)].text,
            text:chkDataAll[Number(id)].termsText
        })
    }

    useEffect(() => {
        if(productData?.gift_type == "receive" || productData?.order_status == "기간만료/환불"){
            setTotalPrice(0.9)
        }else{
            setTotalPrice(1)
        }
    }, [productData]);

    useEffect(() => {
        if(giftId && bank !== ""  && depositor !== ""  && account !== "" && chkData){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [giftId,bank,depositor,account,chkData]);

    return (
        <div className="csPage">
            <Header
                pageBack = {true}
                func={() => navigate(-1)}
                headTitle="취소/환불 접수"
                centerTitle={true}
                info={productData?.order_status == "사용가능" && (productData?.gift_type == "mySend" || productData?.gift_type == "send") ? 
                `구매한 상품권을 취소/환불할 경우 상품권 구매금액의 100%가 환불됩니다.`
                : `<i>선물받은 상품권을 취소/환불할 경우</i><i>상품권 구매금액의 90%가 환불됩니다.</i>`}
            />
            <div className="csWriting_section" style={{paddingBottom:"128px"}}>
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName="은행 선택"
                    placeholder="선택하세요"
                    value={bank}
                    readOnly={true}
                    func={(e) => {}}
                    clickEv={(e) => setSelPopup({
                        title:"은행 선택",
                        addClass:null,
                        closeType:true,
                        items:["산업은행","기업은행","국민은행","수협","농협","지역농축협","우리은행","SC제일은행","한국씨티은행","대구은행","부산은행","광주은행","제주은행","전북은행","경남은행","새마을금고","신협","저축은행","HSBC은행","도이치은행","JP모간체이스은행","BOA은행","BNP파리바은행","중국공상은행","산림조합","중국건설은행","우체국","하나은행","신한은행","케이뱅크","카카오뱅크","유안타증권","KB증권","KTB투자증권","미래에셋대우","삼성증권","한국투자증권","NH투자증권","교보증권","하이투자증권","현대차증권","키움증권","이베스트투자증권","SK증권","대신증권","한화투자증권","하나금융투자","신한금융투자","DB금융투자","유진투자증권","메리츠종합금융증권","카카오페이증권","부국증권","신영증권","케이프투자증권","펀드온라인코리아"],
                        val:["산업은행","기업은행","국민은행","수협","농협","지역농축협","우리은행","SC제일은행","한국씨티은행","대구은행","부산은행","광주은행","제주은행","전북은행","경남은행","새마을금고","신협","저축은행","HSBC은행","도이치은행","JP모간체이스은행","BOA은행","BNP파리바은행","중국공상은행","산림조합","중국건설은행","우체국","하나은행","신한은행","케이뱅크","카카오뱅크","유안타증권","KB증권","KTB투자증권","미래에셋대우","삼성증권","한국투자증권","NH투자증권","교보증권","하이투자증권","현대차증권","키움증권","이베스트투자증권","SK증권","대신증권","한화투자증권","하나금융투자","신한금융투자","DB금융투자","유진투자증권","메리츠종합금융증권","카카오페이증권","부국증권","신영증권","케이프투자증권","펀드온라인코리아"],
                        sel:bank,
                        closeFunc:(e) => {},
                        func:(e) => {
                            setBank(e.text);
                        }
                    })}
                />
                <InputItemBox
                    type="text"
                    addClass="type2"
                    inputName="예금주"
                    placeholder="예금주를 입력하세요"
                    max={null}
                    value={depositor}
                    func={(e) => {setDepositor(e)}}
                />
                <InputItemBox
                    type="number"
                    addClass="type2"
                    inputName="환불 받을 계좌번호"
                    placeholder="‘-’빼고 입력하세요"
                    max={null}
                    value={account}
                    func={(e) => {setAccount(e)}}
                />
                <div className="cancelPriceItem">
                    <TextItem
                        infoName="총 환불금액"
                        infoData={comFormat(Math.floor(Number(productData ? productData?.total_price : 0) * totalPrict))}
                        infoUnit="원"
                    />
                </div>
            </div>
            
            <BtnBox
                text="취소/환불 접수"
                disabled={btnChk}
                func={()=>{writingStart()}}
            >
                <div className="btnChk_area">
                    <ChkBox
                        addClass=""
                        func={(e) => setChkData(e.target.checked)}
                        label={`환불을 위한 <b>개인정보 수집</b>에 동의합니다.`}
                        chkFunc={(e) => rightOpen(1)}
                    />
                </div>
            </BtnBox>

            <SelPopup
                data={selPopup}
            />
            <Popup
                data={popupData}
            />
            <RightPopup
                title={terms?.title}
                addClass={terms == "" ? "" : "active"}
                text={terms?.text}
                closePopup={() => setTerms("")}
            />
        </div>
    );
};

export default CancelPage;