import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "component/header";
import { OrderList } from "component/list";
import useGet from "api/useGet";

const MyOrder = () => {
    const navigate = useNavigate();

    const orderApi = useGet({
        url:`/mypage/order`,
        loginType:"login"
    });
    const orderData = orderApi?.data?.orders;

    useEffect(() => {

    }, []);

    return (
        <div className="myOrderPage">
            <Header
                pageBack = {true}
                func={() => navigate(-1)}
                headTitle="구매내역"
                centerTitle={true}
                info="구매내역은 최대 6개월까지 보관됩니다."
            />
            <div className="myOrderList_section">
                <OrderList
                    data={orderData}
                />
            </div>
        </div>
    );
};

export default MyOrder;