import React from "react";
import { Navigate } from "react-router-dom";

import Login from "../pages/member/Login";
import Terms from "../pages/member/Terms";
import Onboarding from "../pages/onboarding/Onboarding";
import Main from "pages/main/Main";
import MainDetail from "pages/main/Detail";
import PickPage from "pages/pick/pick";
import ShPage from "pages/sh/sh";
import Cafe from "pages/cafe/Main";
import CafeList from "pages/cafe/List";
import MenuDetail from "pages/main/MenuDetail";
import GiftListPage from "pages/gift/List";
import GiftDetailPage from "pages/gift/Detail";
import GiftQrPage from "pages/gift/Qr";
import Order from "pages/order/Order";
import OrderCom from "pages/order/OrderCom";
import MyPage from "pages/mypage/Mypage";
import EditPage from "pages/mypage/Edit";
import WithdrawalPage from "pages/mypage/Withdrawal";
import MyLikePage from "pages/mypage/MyLike";
import MyOrder from "pages/mypage/MyOrder";
import MyOrderDetail from "pages/mypage/MyOrderDetail";
import Faq from "pages/cs/Faq";
import Cs from "pages/cs/CsList";
import CsDetail from "pages/cs/CsDetail";
import CsWriting from "pages/cs/CsWriting";
import CancelPage from "pages/cs/Cancel";
import OrderFailed from "pages/order/OrderFailed";
import PublicGiftListPage from "pages/gift/PublicList";
import OrderErr from "pages/order/OrderErr";
import OrderPay from "pages/order/OrderPay";
//로그인,비로그인 무관 페이지
export const publicRoutes = [
    {
        exact: true,
        path: "/",
        component: !localStorage.getItem("onboarding") ? <Navigate to="/onboarding" /> : <Navigate to="/main" />
    },
    // {
    //     exact: true,
    //     path: "/main2",
    //     component: <Main/>,
    // },
    {
        exact: true,
        path: "/main",
        component: <Cafe/>,
    },
    {
        exact: true,
        path: "/main/detail/:id/:tap",
        component: <MainDetail/>,
    },
    {
        exact: true,
        path: "/main/menu/:storeId/:id",
        component: <MenuDetail/>,
    },
    {
        exact: true,
        path: "/pick",
        component: <PickPage/>,
    },
    {
        exact: true,
        path: "/sh",
        component: <ShPage/>,
    },
    {
        exact: true,
        path: "/cafe",
        component: <Cafe/>,
    },
    {
        exact: true,
        path: "/cafe/list/:category",
        component: <CafeList/>,
    },
    {
        exact: true,
        path: "/gift/detail/:id/:popup",
        component: <GiftDetailPage/>,
    },
    {
        exact: true,
        path: "/gift/qr/:id",
        component: <GiftQrPage/>,
    },
    {
        exact: true,
        path: "/cs/cancel/:giftId/:type",
        component: <CancelPage/>,
    },
    {
        exact: true,
        path: "/gift/:id",
        component: <PublicGiftListPage/>,
    },
];
//비로그인 한정 페이지
export const publicRoutesOnly = [
    {
        exact: true,
        path: "/member/login",
        component: <Login/>,
    },
    {
        exact: true,
        path: "/member/terms/:phone",
        component: <Terms/>,
    },
    {
        exact: true,
        path: "/onboarding",
        component: <Onboarding/>,
    }
];
//로그인 한정 페이지
export const privateRoutes = [
    {
        exact: true,
        path: "/order/:type/:storeId/:id/:count",
        component: <Order/>,
    },
    {
        exact: true,
        path: "/order/orderCom/:menuId/:storeId/:id/:type",
        component: <OrderCom/>,
    },
    {
        exact: true,
        path: "/order/orderFailed/:storeId/:menuId/:failedType",
        component: <OrderFailed/>,
    },
    {
        exact: true,
        path: "/gift",
        component: <GiftListPage/>,
    },
    {
        exact: true,
        path: "/mypage",
        component: <MyPage/>,
    },
    {
        exact: true,
        path: "/mypage/edit",
        component: <EditPage/>,
    },
    {
        exact: true,
        path: "/mypage/like",
        component: <MyLikePage/>,
    },
    {
        exact: true,
        path: "/mypage/order",
        component: <MyOrder/>,
    },
    {
        exact: true,
        path: "/mypage/orderDetail/:id",
        component: <MyOrderDetail/>,
    },
    {
        exact: true,
        path: "/cs",
        component: <Faq/>,
    },
    {
        exact: true,
        path: "/cs/cs",
        component: <Cs/>,
    },
    {
        exact: true,
        path: "/cs/detail/:id",
        component: <CsDetail/>,
    },
    {
        exact: true,
        path: "/cs/writing",
        component: <CsWriting/>,
    },
    {
        exact: true,
        path: "/order/err/:type/:storeId/:menuId",
        component: <OrderErr/>,
    },
    {
        exact: true,
        path: "/order/pay/:type/:menuId/:storeId/:option_id/:qty/:payment_method/:amount/:card_image/:message/:giftPhoneNumber",
        component: <OrderPay/>,
    },
];