import { Popup } from "component/popup";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const OrderErr = () => {
    const navigate = useNavigate();
    const param = useParams();
    const type = param.type;
    const storeId = param.storeId;
    const menuId = param.menuId;

    const [popupData,setPopupData] =  useState(null);

    useEffect(() => {
        setPopupData({
            addClass:null,
            title:`${type == "card" ? "유효하지 않은 카드입니다." : type == "err" ? "결제에 문제가 발생하였습니다." : "결제가 취소되었습니다."}<br/>초기 화면으로 돌아갑니다.`,
            text:"",
            btn0:"확인",
            closeType:false,
            closeFunc:(e) => {},
            btnFunc0:(e)=>{
                navigate(`/main`);
            },
            btnFunc1:(e)=>{}
        });
    }, []);

    return (
        <Popup
            data={popupData}
        />
    );
};

export default OrderErr;