import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "component/header";
import { BottomErrMsg, Popup } from "component/popup";
import { CalendarList } from "component/list";
import useGet from "api/useGet";
import { Tap } from "component/tap";
import { bottomChk, storeLike } from "js/function";

const ShPage = () => {
    const navigate = useNavigate();

    let settingChk = 0;

    const [page,setPage] = useState(1);
    const [giftType,setGiftType] = useState(1);
    const [shKey,setShKey] = useState("");

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const [shData,setShData] = useState([]);

    const [popupData,setPopupData] =  useState(null);

    const shListData = useGet({
        url:`/search?user_id=${localStorage.getItem("user_id")}&search=${shKey}&can_gift=${giftType}&page=${page}`,
        loginType:"login"
    });

    function tapCh(id){
        setShData([]);
        setPage(1);
        setGiftType(id);
    }

    function shSetting(key){
        if(shKey !== key){
            setShData([]);
            setPage(1);
            setShKey(key);
        }
    }

    function handleScroll(){
        if(bottomChk() && shListData.data?.stores?.current_page < shListData.data?.stores?.last_page && settingChk == 0){
            setPage(page + 1);
            settingChk = 1;
            setTimeout(() => {
                settingChk = 0;
            }, 500);
        }
    };

    useEffect(() => {
        if(shKey !== ""){
            if(shListData.data?.stores?.data && shListData.data?.stores?.data.length > 0){
                if(page == 1){
                    setShData([...shListData.data?.stores?.data]);
                }else{    
                    setShData(prev => [...prev, ...shListData.data?.stores?.data]);
                }
            }else{
                setShData([]);
            }
        }
    }, [shListData]);

    useEffect(() => {
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="shPage">
            <Header
                pageBack = {true}
                func={() => {navigate(-1)}}
                shBox = {true}
                shFunc = {(key) => shSetting(key)}
            />
            <div className="sh_section">
                <div className="topTap" style={{minHeight:"40px"}}>
                    <Tap
                        data={[
                            {
                                id:1,
                                name:"선물 가능 매장"
                            },
                            {
                                id:0,
                                name:"일반 매장"
                            }
                        ]}
                        val={giftType}
                        func={(tapId) => tapCh(tapId)}
                    />
                </div>
                <div className="calendarList_area">
                    {shKey !== "" && shListData?.data?.stores?.total !== 0 ? <p className="shTotal">총 <b>{shListData?.data?.stores?.total ? shListData?.data?.stores?.total : 0}</b> 건</p> : ""}
                    <div className="calendarList">
                        <CalendarList
                            data={shData}
                            like={(selId,type) => storeLike({
                                selId : selId,
                                type : type,
                                func : () => {
                                    setBottomMsgData({
                                        text : "관심매장이 추가되었습니다.",
                                        chk : bottomMsgData.chk + 1
                                    });
                                },
                                loginErr : () => {
                                    setPopupData({
                                        addClass:null,
                                        title:"로그인이 필요합니다.",
                                        text:"",
                                        btn0:"취소",
                                        btn1:"로그인",
                                        closeType:false,
                                        closeFunc:(e) => {},
                                        btnFunc0:(e)=>{},
                                        btnFunc1:(e)=>{
                                            let nowUrl = new URL(window.location.href);
                                            sessionStorage.setItem("setUrl",nowUrl.pathname);
                                            navigate("/member/login");
                                        }
                                    });
                                }
                            })}
                            notText="검색 결과가 없어요"
                        />
                    </div>
                </div>
            </div>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default ShPage;